import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Content } from "antd/es/layout/layout"
import Title from "antd/es/typography/Title"

import { LoaderSpinner } from "Base/components"
import { clientModuleRoutes } from "Base/constants/routes"
import { useNavFormDisable } from "Base/hooks/useNavFormDisable"
import * as calcIndClassActions from "Base/store/indClass/actions"
import * as calcLeadActions from "Base/store/lead/actions"
import * as calcLesseeActions from "Base/store/lessee/actions"
import { TCalcLessee } from "Base/types/provider/api/lessee"
import { phoneNumber } from "Base/utils/validation"
import { StageLayout } from "ClientModule/layouts/StageLayout"
import { RootState } from "Starter/store/configureStore"
import { DatePicker, Form, Input, Select } from "antd"
import { format, parse } from "date-fns"
import dayjs from "dayjs"
import { StringParam, useQueryParam } from "use-query-params"

const { Item } = Form

export const Lessee = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const requiredValue = Form.useWatch("name", form)

  const [leadId] = useQueryParam("leadId", StringParam)
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcLessee: { getCalcLessee, getCalcLesseeById, postCalcLessee, patchCalcLessee },
      calcIndClass: { getCalcIndClass },
    },
  } = useSelector((state: RootState) => state)

  const [searchNameValue, setSearchNameValue] = useState("")
  const [searchInnValue, setSearchInnValue] = useState("")

  useEffect(() => {
    if (getCalcLeadById.data?.result?.lessee?.id) {
      dispatch(
        calcLesseeActions.getCalcLesseeByIdAction.call({
          id: getCalcLeadById.data.result.lessee.id,
        }),
      )
    }
    if (getCalcLeadById.data) {
      dispatch(calcLesseeActions.getCalcLesseeAction.call({}))
      dispatch(calcIndClassActions.getCalcIndClassAction.call({}))
    }
  }, [getCalcLeadById.data])

  useEffect(() => {
    if (!getCalcLesseeById.data) return

    form.setFieldValue("name", getCalcLesseeById.data.result.name)
    getCalcLesseeById.data.result.indClass?.id &&
      form.setFieldValue("indClass", getCalcLesseeById.data.result.indClass.id)
    form.setFieldValue("inn", getCalcLesseeById.data.result.inn)
    form.setFieldValue("directorName", getCalcLesseeById.data.result.directorName)
    form.setFieldValue("contactName", getCalcLesseeById.data.result.contactName)
    form.setFieldValue("contactJobTitle", getCalcLesseeById.data.result.contactJobTitle)
    form.setFieldValue("contactPhone", getCalcLesseeById.data.result.contactPhone)
    getCalcLesseeById.data.result.directorBirthdate &&
      form.setFieldValue(
        "directorBirthdate",
        dayjs(getCalcLesseeById.data.result.directorBirthdate, "YYYY-MM-DD"),
      )
  }, [getCalcLesseeById.data])

  const lesseesOptions = useMemo(() => {
    if (!getCalcLessee.data?.result) return []
    return getCalcLessee.data?.result.map((lessee) => {
      return { value: lessee.id, label: lessee.name }
    })
  }, [getCalcLessee.data])

  const innOptions = useMemo(() => {
    if (!getCalcLessee.data?.result) return []
    return getCalcLessee.data?.result
      .filter((lessee) => lessee?.inn)
      .map((lessee) => {
        return { value: lessee.id, label: lessee.inn }
      })
  }, [getCalcLessee.data])

  const indClassOptions = useMemo(() => {
    if (!getCalcIndClass.data?.result) return []
    return getCalcIndClass.data?.result.map((indClass) => {
      return { value: indClass.id, label: indClass.name }
    })
  }, [getCalcIndClass.data])

  const onChangeNameOrInn = (value: number) => {
    if (!leadId || !getCalcLeadById.data) return
    dispatch(
      calcLeadActions.patchCalcLeadAction.call({
        id: leadId,
        lessee: value,
        name: getCalcLeadById.data.result.name,
      }),
    )
    dispatch(calcLesseeActions.getCalcLesseeByIdAction.call({ id: value }))
  }

  const onChangeIndClass = (value: number) => {
    if (!leadId || !getCalcLesseeById.data) return
    dispatch(
      calcLesseeActions.patchCalcLesseeAction.call({
        id: getCalcLesseeById.data.result.id,
        indClass: value,
      }),
    )
  }

  const isDisableForm = useMemo(() => {
    return getCalcLeadById.isLoading || postCalcLessee.isLoading
  }, [getCalcLeadById, postCalcLessee])

  const onBlurName = () => {
    if (!searchNameValue) return
    if (
      getCalcLessee.data?.result.filter((lessee) => lessee.name === searchNameValue).length === 0
    ) {
      dispatch(
        calcLesseeActions.postCalcLesseeAction.call({
          name: searchNameValue,
        }),
      )
    }
  }

  const onBlurInn = () => {
    if (
      searchInnValue &&
      getCalcLessee.data?.result.filter((lessee) => lessee.inn === searchInnValue).length === 0 &&
      getCalcLesseeById.data?.result.id
    ) {
      dispatch(
        calcLesseeActions.patchCalcLesseeAction.call({
          id: getCalcLesseeById.data.result.id,
          inn: searchInnValue,
        }),
      )
      setSearchInnValue("")
    }
  }

  const onBlur = ({ name, value }: { name: keyof TCalcLessee; value: string | number }) => {
    if (!leadId || !getCalcLesseeById.data || !value) return
    if (name === "directorBirthdate") {
      const parsedDate = parse(value.toString(), "dd-MM-yyyy", new Date())
      value = format(new Date(parsedDate), "yyyy-MM-dd")
    }
    if (getCalcLesseeById.data.result[name] === value) return
    dispatch(
      calcLesseeActions.patchCalcLesseeAction.call({
        id: getCalcLesseeById.data.result.id,
        [name]: value,
      }),
    )
  }

  const isButtonsDisabled = !requiredValue

  useNavFormDisable(isButtonsDisabled)

  const isDisableFormFields = useMemo(() => {
    return !getCalcLesseeById.data || patchCalcLessee.isLoading
  }, [getCalcLesseeById.data, patchCalcLessee])

  return (
    <StageLayout
      stageTitle='Шаг 1/4'
      nextLink={
        clientModuleRoutes["leasing-stages"]["subject-of-leasing"].root + "?leadId=" + leadId
      }
      isButtonsDisabled={isButtonsDisabled}
    >
      <Title style={{ marginBottom: "20px" }} level={3}>
        Данные о лизингополучателе
      </Title>
      <Content>
        {getCalcLeadById.isLoading && <LoaderSpinner />}
        <Form
          disabled={isDisableForm}
          name='mixes'
          form={form}
          layout={"vertical"}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
          }}
        >
          <div style={{ display: "grid", gridTemplateRows: "repeat(5, 1fr)" }}>
            <Item required name='name' label='Наименование лизингополучателя'>
              <Select
                className='select-custom'
                size='large'
                onChange={onChangeNameOrInn}
                onSearch={(value) => setSearchNameValue(value)}
                onBlur={onBlurName}
                showSearch
                style={{ width: "100%" }}
                placeholder='Введите наименование'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={lesseesOptions}
              />
            </Item>
            <Item name='indClass' label='Классификация отрасли'>
              <Select
                className='select-custom'
                size='large'
                disabled={isDisableFormFields}
                onChange={onChangeIndClass}
                showSearch
                style={{ width: "100%" }}
                placeholder='Выберите классификацию'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={indClassOptions}
              />
            </Item>
            <Item name='directorName' label='ФИО руководителя'>
              <Input
                size='large'
                onBlur={(event) => {
                  onBlur({ name: "directorName", value: event.target.value })
                }}
                disabled={isDisableFormFields}
                placeholder='Введите ФИО'
              />
            </Item>
            <Item name='contactName' label='Контактное лицо'>
              <Input
                size='large'
                disabled={isDisableFormFields}
                onBlur={(event) => {
                  onBlur({ name: "contactName", value: event.target.value })
                }}
                placeholder='Контактное лицо'
              />
            </Item>
            <Item rules={[phoneNumber]} name='contactPhone' label='Телефон контактного лица'>
              <Input
                size='large'
                onBlur={(event) => {
                  onBlur({ name: "contactPhone", value: event.target.value })
                }}
                disabled={isDisableFormFields}
                placeholder='+79999999999'
              />
            </Item>
          </div>
          <div style={{ display: "grid", gridTemplateRows: "repeat(5, 1fr)" }}>
            <Item name='inn' label='ИНН'>
              <Select
                className='select-custom'
                size='large'
                onChange={onChangeNameOrInn}
                onSearch={(value) => setSearchInnValue(value)}
                onBlur={onBlurInn}
                showSearch
                style={{ width: "100%" }}
                placeholder='502101100500'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={innOptions}
              />
            </Item>
            <div />
            <Item name='directorBirthdate' label='Дата рождения руководителя'>
              <DatePicker
                size='large'
                disabled={isDisableFormFields}
                // onChange={onChangeDate}
                style={{ width: "100%" }}
                onBlur={(event: BaseSyntheticEvent) => {
                  onBlur({ name: "directorBirthdate", value: event.target.value })
                }}
                format='DD-MM-YYYY'
              />
            </Item>
            <Item name='contactJobTitle' label='Должность контактного лица'>
              <Input
                size='large'
                onBlur={(event) => {
                  onBlur({ name: "contactJobTitle", value: event.target.value })
                }}
                disabled={isDisableFormFields}
                placeholder='Укажите должность'
              />
            </Item>
            <div />
          </div>
        </Form>
      </Content>
    </StageLayout>
  )
}
