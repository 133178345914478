export const toCalculateValueAssets = ({
  valueAssets,
  initialPayment,
  initialPaymentPercent,
}: {
  valueAssets: number
  initialPayment?: number
  initialPaymentPercent?: number
}): {
  initialPayment: number
  initialPaymentPercent: number
} => {
  let _initialPayment = 0
  let _initialPaymentPercent = 0
  if (valueAssets > 1) {
    const onePercent = valueAssets / 100
    if (initialPayment) {
      _initialPayment = initialPayment
      _initialPaymentPercent = initialPayment / onePercent
    }
    if (initialPaymentPercent) {
      _initialPayment = initialPaymentPercent * onePercent
      _initialPaymentPercent = initialPaymentPercent
    }

    if (_initialPayment > valueAssets) {
      _initialPayment = valueAssets
      _initialPaymentPercent = 100
    }
  }

  return {
    initialPayment: Math.round(_initialPayment * 100) / 100,
    initialPaymentPercent: Math.round(_initialPaymentPercent * 100) / 100,
  }
}
