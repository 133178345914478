import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcCreditorParams,
  TDeleteCalcCreditorReq,
  TDeleteCalcCreditorResp,
  TGetCalcCreditorByIdParams,
  TGetCalcCreditorByIdReq,
  TGetCalcCreditorByIdResp,
  TGetCalcCreditorParams,
  TGetCalcCreditorReq,
  TGetCalcCreditorResp,
  TPostCalcCreditorParams,
  TPostCalcCreditorReq,
  TPostCalcCreditorResp,
  TPutCalcCreditorParams,
  TPutCalcCreditorReq,
  TPutCalcCreditorResp,
} from "Base/types/provider/api/creditor"

export default class CreditorProvider extends ApiProviderDefault {
  static async deleteCalcCreditor(parameters: {
    params: TDeleteCalcCreditorParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/creditor/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcCreditorReq, TDeleteCalcCreditorResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcCreditorById(parameters: {
    params: TGetCalcCreditorByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/creditor/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcCreditorByIdReq, TGetCalcCreditorByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcCreditor(parameters: { params: TGetCalcCreditorParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/creditor`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcCreditorReq, TGetCalcCreditorResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcCreditor(parameters: { params: TPostCalcCreditorParams; headers: Headers }) {
    const { params, headers } = parameters
    const path = "/calc/creditor"
    const query = {}
    const body = params
    return this.request<TPostCalcCreditorReq, TPostCalcCreditorResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putCalcCreditor(parameters: { params: TPutCalcCreditorParams; headers: Headers }) {
    const {
      params: { id, name, contactName, contactPhone, contactJobTitle },
      headers,
    } = parameters
    const path = `/calc/creditor/${id}`
    const body = { name, contactName, contactPhone, contactJobTitle }
    const query = undefined
    return this.request<TPutCalcCreditorReq, TPutCalcCreditorResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
