import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as calcAssetActions from "Base/store/asset/actions"
import * as calcAssetCurrencyActions from "Base/store/assetCurrency/actions"
import * as calcCreditorActions from "Base/store/creditor/actions"
import * as calcLeadActions from "Base/store/lead/actions"
import * as calcLesseeActions from "Base/store/lessee/actions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import * as calcPaymentScheduleActions from "Base/store/paymentSchedule/actions"
import * as calcProviderActions from "Base/store/provider/actions"
import { RootState } from "Starter/store/configureStore"
import { StringParam, useQueryParam } from "use-query-params"

type LeadProviderProps = {
  children?: React.ReactNode
}
export const LeadProvider = ({ children }: LeadProviderProps) => {
  const dispatch = useDispatch()
  const [leadId, setLeadId] = useQueryParam("leadId", StringParam)

  const {
    base: {
      calcLead: { getCalcLeadById },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    return () => {
      dispatch(calcLeadActions.getCalcLeadByIdAction.initial())
      dispatch(calcProviderActions.getCalcProviderAction.initial())
      dispatch(calcPaymentOtherActions.getCalcPaymentOtherAction.initial())
      dispatch(calcPaymentScheduleActions.getCalcPaymentScheduleAction.initial())
      dispatch(calcAssetCurrencyActions.getCalcAssetCurrencyAction.initial())
      dispatch(calcLeadActions.postCalcLeadAction.initial())
      dispatch(calcLesseeActions.getCalcLesseeByIdAction.initial())
      dispatch(calcAssetActions.getCalcAssetAction.initial())
    }
  }, [])

  useEffect(() => {
    if (!leadId) return
    if (
      getCalcLeadById?.isLoading ||
      getCalcLeadById?.error ||
      getCalcLeadById?.data?.result?.id === Number(leadId)
    )
      return
    dispatch(calcLeadActions.getCalcLeadByIdAction.call({ id: Number(leadId) }))
  }, [leadId, getCalcLeadById])

  useEffect(() => {
    if (!getCalcLeadById?.data?.result?.id) return
    const _leadId = getCalcLeadById.data.result.id
    dispatch(calcProviderActions.getCalcProviderAction.call({}))
    dispatch(calcPaymentOtherActions.getCalcPaymentOtherAction.call({ leadId: _leadId }))
    dispatch(calcPaymentScheduleActions.getCalcPaymentScheduleAction.call({ leadId: _leadId }))
    dispatch(calcAssetCurrencyActions.getCalcAssetCurrencyAction.call({}))
    dispatch(calcCreditorActions.getCalcCreditorAction.call({}))
    dispatch(calcAssetActions.getCalcAssetAction.call({ leadId: _leadId }))
  }, [getCalcLeadById?.data?.result?.id])

  return <>{children}</>
}
