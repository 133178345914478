import { apiRequest } from "Base/provider/sagas"
import * as calcAgentActions from "Base/store/agent/actions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import {
  TDeleteCalcPaymentOtherResp,
  TGetCalcPaymentOtherResp,
  TPostCalcPaymentOtherResp,
  TPatchCalcPaymentOtherResp,
  TGetCalcPaymentOtherByIdResp,
  TCalcPaymentOther,
  TPutCalcPaymentOtherResp,
  TPutCalcPaymentOtherParams,
} from "Base/types/provider/api/paymentOther"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select, all, delay } from "redux-saga/effects"

import { selectLeadId } from "../lead/selectors"
import { getCalcPaymentScheduleAction } from "../paymentSchedule/actions"
import {
  TDeleteCalcPaymentOtherReqAction,
  TPostCalcPaymentOtherReqAction,
  TPatchCalcPaymentOtherReqAction,
  TGetCalcPaymentOtherByIdReqAction,
  TGetCalcPaymentOtherReqAction,
  TPutCalcPaymentOtherReqAction,
  TPutCalcPaymentOtherByAgentIdReqAction,
  TDeleteCalcPaymentOtherByPropertiesReqAction,
} from "./types"

export function* getCalcPaymentOtherSaga({ payload }: TGetCalcPaymentOtherReqAction) {
  try {
    const resp: TGetCalcPaymentOtherResp = yield call(apiRequest, "getCalcPaymentOther", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentOtherActions.getCalcPaymentOtherAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.getCalcPaymentOtherAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcPaymentOtherByIdSaga({ payload }: TGetCalcPaymentOtherByIdReqAction) {
  try {
    const resp: TGetCalcPaymentOtherByIdResp = yield call(apiRequest, "getCalcPaymentOtherById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentOtherActions.getCalcPaymentOtherByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.getCalcPaymentOtherByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcPaymentOtherSaga({ payload }: TPostCalcPaymentOtherReqAction) {
  try {
    const resp: TPostCalcPaymentOtherResp = yield call(apiRequest, "postCalcPaymentOther", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentOtherActions.postCalcPaymentOtherAction.success(resp.data))
    const { getCalcPaymentOther } = yield select(({ base }: RootState) => base.calcPaymentOther)

    if (payload?.agent) {
      yield put(
        calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({ leadId: payload.leadId }),
      )
    } else {
      yield put(
        calcPaymentOtherActions.getCalcPaymentOtherAction.success({
          ...getCalcPaymentOther.data,
          result: [...getCalcPaymentOther.data.result, resp.data.result],
        }),
      )
    }

    yield delay(500)
    yield put(
      getCalcPaymentScheduleAction.call({
        leadId: payload.leadId,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.postCalcPaymentOtherAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* patchCalcPaymentOtherSaga({ payload }: TPatchCalcPaymentOtherReqAction) {
  try {
    const resp: TPatchCalcPaymentOtherResp = yield call(apiRequest, "patchCalcPaymentOther", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentOtherActions.patchCalcPaymentOtherAction.success(resp.data))
    const { getCalcPaymentOther } = yield select(({ base }: RootState) => base.calcAsset)
    yield put(
      calcPaymentOtherActions.getCalcPaymentOtherAction.success({
        ...getCalcPaymentOther.data,
        result: getCalcPaymentOther.data?.result.map((asset: TCalcPaymentOther) => {
          if (asset.id !== resp.data.result.id) return asset
          return resp.data.result
        }),
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.patchCalcPaymentOtherAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putCalcPaymentOtherSaga({ payload }: TPutCalcPaymentOtherReqAction) {
  try {
    const resp: TPutCalcPaymentOtherResp = yield call(apiRequest, "putCalcPaymentOther", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentOtherActions.putCalcPaymentOtherAction.success(resp.data))
    const { getCalcPaymentOther } = yield select(({ base }: RootState) => base.calcPaymentOther)
    yield put(
      calcPaymentOtherActions.getCalcPaymentOtherAction.success({
        ...getCalcPaymentOther.data,
        result: getCalcPaymentOther.data?.result.map((paymentOther: TCalcPaymentOther) => {
          if (paymentOther.id !== resp.data.result.id) return paymentOther
          return resp.data.result
        }),
      }),
    )

    const leadId: number = yield select(selectLeadId)
    if (leadId) {
      yield delay(500)
      yield put(
        getCalcPaymentScheduleAction.call({
          leadId,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.putCalcPaymentOtherAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putCalcPaymentOtherByAgentIdSaga({
  payload,
}: TPutCalcPaymentOtherByAgentIdReqAction) {
  try {
    const {
      calcPaymentOther: { getCalcPaymentOther },
      calcLead: { getCalcLeadById },
    } = yield select(({ base }: RootState) => base)
    const paymentOtherList: Array<TPutCalcPaymentOtherParams> = getCalcPaymentOther?.data?.result
      .filter((paymentOther: TCalcPaymentOther) => paymentOther.agentId === payload.agentIdPrev)
      .map((paymentOther: TCalcPaymentOther) => ({
        paymentId: paymentOther.id,
        agent: payload.agentIdNew,
        asset: paymentOther.assetId,
        date: paymentOther.date,
        amount: paymentOther.amount,
        reason: paymentOther.reason,
      }))
    yield all(
      paymentOtherList.map((paymentOther: TPutCalcPaymentOtherParams) =>
        call(apiRequest, "putCalcPaymentOther", {
          params: paymentOther,
        }),
      ),
    )
    yield put(
      calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({
        leadId: getCalcLeadById.data.result.id,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.putCalcPaymentOtherByAgentIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcPaymentOtherSaga({ payload }: TDeleteCalcPaymentOtherReqAction) {
  try {
    const resp: TDeleteCalcPaymentOtherResp = yield call(apiRequest, "deleteCalcPaymentOther", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentOtherActions.deleteCalcPaymentOtherAction.success(resp.data))
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)

    yield put(
      calcPaymentOtherActions.getCalcPaymentOtherAction.call({
        leadId: getCalcLeadById.data.result.id,
      }),
    )

    const leadId: number = yield select(selectLeadId)
    if (leadId) {
      yield delay(500)
      yield put(
        getCalcPaymentScheduleAction.call({
          leadId,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.deleteCalcPaymentOtherAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcPaymentOtherByPropertiesSaga({
  payload,
}: TDeleteCalcPaymentOtherByPropertiesReqAction) {
  try {
    const {
      calcPaymentOther: { getCalcPaymentOther },
      calcLead: { getCalcLeadById },
    } = yield select(({ base }: RootState) => base)
    const paymentOtherList: Array<TCalcPaymentOther> = getCalcPaymentOther?.data?.result.filter(
      (paymentOther: TCalcPaymentOther) =>
        paymentOther.agentId === payload.agentId || paymentOther.agentId === payload.assetId,
    )

    yield all(
      paymentOtherList.map((paymentOther: TCalcPaymentOther) =>
        call(apiRequest, "deleteCalcPaymentOther", {
          params: {
            id: paymentOther.id,
          },
        }),
      ),
    )
    yield put(
      calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({
        leadId: getCalcLeadById.data.result.id,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentOtherActions.deleteCalcPaymentOtherAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcPaymentOtherWatcher() {
  yield takeLatest(
    calcPaymentOtherActions.getCalcPaymentOtherAction.call.type,
    getCalcPaymentOtherSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.getCalcPaymentOtherByIdAction.call.type,
    getCalcPaymentOtherByIdSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.postCalcPaymentOtherAction.call.type,
    postCalcPaymentOtherSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.putCalcPaymentOtherAction.call.type,
    putCalcPaymentOtherSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.putCalcPaymentOtherByAgentIdAction.call.type,
    putCalcPaymentOtherByAgentIdSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.patchCalcPaymentOtherAction.call.type,
    patchCalcPaymentOtherSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.deleteCalcPaymentOtherAction.call.type,
    deleteCalcPaymentOtherSaga,
  )
  yield takeLatest(
    calcPaymentOtherActions.deleteCalcPaymentOtherByPropertiesAction.call.type,
    deleteCalcPaymentOtherByPropertiesSaga,
  )
}
