import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Content } from "antd/es/layout/layout"
import { ColumnsType } from "antd/es/table"

import { PaymentDetailsTable } from "Base/components"
import { Breadcrumbs } from "Base/components/Breadcrumbs"
import * as calcPaymentScheduleActions from "Base/store/paymentSchedule/actions"
import { THistoryChangesDataType } from "Base/store/paymentSchedule/types"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { InputNameLeadForm } from "ClientModule/containers"
import { RootState } from "Starter/store/configureStore"
import { Table, Space, Typography, Form, Select, Spin, Alert } from "antd"

const { Title } = Typography

export const HistoryChanges: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedPayment, setSelectedPayment] = useState<THistoryChangesDataType | null>(null)

  const {
    calcPaymentSchedule,
    calcLead: { getCalcLeadById },
  } = useSelector((state: RootState) => state.base)

  const scheduleId = useMemo(() => {
    return calcPaymentSchedule.getCalcPaymentSchedule?.data?.result[0]?.id
  }, [calcPaymentSchedule.getCalcPaymentSchedule?.data])

  useEffect(() => {
    if (scheduleId) {
      dispatch(
        calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.call({
          id: scheduleId,
        }),
      )
    }
  }, [dispatch, scheduleId])

  const dataSource = useMemo(() => {
    const data = calcPaymentSchedule.getPaymentsHistory?.data
    const contractNumber = getCalcLeadById?.data?.result.name || "-"

    if (data && data.length) {
      return data
        .slice()
        .sort((a, b) => {
          const dateA = new Date(a.modifiedAt).getTime()
          const dateB = new Date(b.modifiedAt).getTime()
          return dateB - dateA
        })
        .map((payment, index) => {
          const formattedDate = new Date(payment.modifiedAt).toLocaleString("ru-RU", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })

          return {
            key: data.length - index,
            date: formattedDate,
            contractNumber,
            leasingAmount: payment.financialResult,
            buyoutAmount: payment.redemptionAmount,
            profitability: payment.paymentYield,
            profitabilityPercent: payment.yieldPercent,
            annualIncreasePercent: payment.risePerYear,
            residualValue: payment.residualValue,
            payments: payment.payments,
          }
        })
    }
    return []
  }, [calcPaymentSchedule.getPaymentsHistory, getCalcLeadById?.data?.result.name])

  const columns: ColumnsType<THistoryChangesDataType> = [
    { title: "№", dataIndex: "key", key: "key", width: 50 },
    { title: "Дата", dataIndex: "date", key: "date", width: 120 },
    { title: "Номер договора", dataIndex: "contractNumber", key: "contractNumber", width: 150 },
    {
      title: "Сумма лизинга",
      dataIndex: "leasingAmount",
      key: "leasingAmount",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Сумма выкупа",
      dataIndex: "buyoutAmount",
      key: "buyoutAmount",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Доходность",
      dataIndex: "profitability",
      key: "profitability",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Доходность, %",
      dataIndex: "profitabilityPercent",
      key: "profitabilityPercent",
    },
    {
      title: "Удорожание в год, %",
      dataIndex: "annualIncreasePercent",
      key: "annualIncreasePercent",
    },
    {
      title: "Остаточная стоимость",
      dataIndex: "residualValue",
      key: "residualValue",
      render: (v) => convertNumberToLocalString(v),
    },
  ]

  const tableContent = useMemo(() => {
    if (calcPaymentSchedule.getPaymentsHistory?.isLoading) {
      return <Spin size='large' style={{ display: "block", margin: "20px auto" }} />
    }

    if (calcPaymentSchedule.getPaymentsHistory?.error) {
      return (
        <Alert
          message={calcPaymentSchedule.getPaymentsHistory?.error}
          type='error'
          showIcon
          style={{ margin: "20px 0" }}
        />
      )
    }

    return null
  }, [calcPaymentSchedule.getPaymentsHistory])

  const handleBack = () => {
    setSelectedPayment(null)
  }

  return (
    <Content style={{ padding: "30px" }}>
      <InputNameLeadForm style={{ padding: "0 0 0 0" }} />
      <Breadcrumbs selectedPayment={selectedPayment} handleBack={handleBack} />
      {selectedPayment ? (
        <PaymentDetailsTable
          data={selectedPayment || []}
          contractNumber={selectedPayment.contractNumber}
        />
      ) : (
        <>
          <Space style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
            <Title level={3}>История сделок</Title>
            <Form.Item name='status' style={{ margin: 0, marginLeft: "20px" }}>
              <Select
                className='select-custom'
                style={{ width: "160px" }}
                placeholder='Выбор динамики'
                options={[
                  { value: "type1", label: "1 тип" },
                  { value: "type2", label: "2 тип" },
                  { value: "type3", label: "3 тип" },
                ]}
              />
            </Form.Item>
          </Space>
          <Table
            columns={columns}
            dataSource={dataSource}
            style={{ marginTop: "20px" }}
            onRow={(record) => ({
              onClick: () => {
                setSelectedPayment(record)
              },
              style: { cursor: "pointer" },
            })}
            locale={{
              emptyText: tableContent || "Нет данных",
            }}
            scroll={{ x: "max-content" }}
          />
        </>
      )}
    </Content>
  )
}
