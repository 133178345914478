import { createReducer } from "@reduxjs/toolkit"
import * as authPersistActions from "Base/store/authPersist/actions"

import { TSetPasswordReqAction } from "./types"

export type AuthPersistStateType = {
  auth?: {
    password: string
  }
}

const initialState: AuthPersistStateType = {
  auth: undefined,
}

export const authPersistReducer = createReducer(initialState, {
  [authPersistActions.setPasswordAction.call.type]: (state, action: TSetPasswordReqAction) => ({
    ...state,
    auth: {
      ...state.auth,
      ...action.payload,
    },
  }),
})
