import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { clientModuleRoutes } from "Base/constants/routes"
import { modulesRoutes } from "Base/constants/routes/modulesRoutes"
import { getClientRouters } from "ClientModule/routes"

export const BaseRouter = () => {
  return (
    <Routes>
      {getClientRouters()}
      <Route
        path={modulesRoutes.root}
        element={
          <Navigate
            to={clientModuleRoutes["list-of-lease-payment-schedules"].root}
            replace={false}
          />
        }
      />
    </Routes>
  )
}
