import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcProviderFailurePayload,
  TDeleteCalcProviderReqPayload,
  TDeleteCalcProviderSuccessPayload,
  TGetCalcProviderFailurePayload,
  TGetCalcProviderReqPayload,
  TGetCalcProviderSuccessPayload,
  TPostCalcProviderFailurePayload,
  TPostCalcProviderReqPayload,
  TPostCalcProviderSuccessPayload,
  TPatchCalcProviderFailurePayload,
  TPatchCalcProviderReqPayload,
  TPatchCalcProviderSuccessPayload,
  TGetCalcProviderByIdReqPayload,
  TGetCalcProviderByIdSuccessPayload,
  TGetCalcProviderByIdFailurePayload,
  TPutCalcProviderSuccessPayload,
  TPutCalcProviderFailurePayload,
  TPutCalcProviderReqPayload,
} from "./types"

const prefix = "calc-provider"

export const getCalcProviderAction = baseCSF<
  TGetCalcProviderReqPayload,
  TGetCalcProviderSuccessPayload,
  TGetCalcProviderFailurePayload,
  undefined
>(prefix, "get")
export const getCalcProviderByIdAction = baseCSF<
  TGetCalcProviderByIdReqPayload,
  TGetCalcProviderByIdSuccessPayload,
  TGetCalcProviderByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcProviderAction = baseCSF<
  TPostCalcProviderReqPayload,
  TPostCalcProviderSuccessPayload,
  TPostCalcProviderFailurePayload,
  undefined
>(prefix, "post")

export const patchCalcProviderAction = baseCSF<
  TPatchCalcProviderReqPayload,
  TPatchCalcProviderSuccessPayload,
  TPatchCalcProviderFailurePayload,
  undefined
>(prefix, "patch")

export const putCalcProviderAction = baseCSF<
  TPutCalcProviderReqPayload,
  TPutCalcProviderSuccessPayload,
  TPutCalcProviderFailurePayload,
  undefined
>(prefix, "put")

export const deleteCalcProviderAction = baseCSF<
  TDeleteCalcProviderReqPayload,
  TDeleteCalcProviderSuccessPayload,
  TDeleteCalcProviderFailurePayload,
  undefined
>(prefix, "delete")
