import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcProviderActions from "Base/store/provider/actions"
import {
  TDeleteCalcProviderData,
  TGetCalcProviderData,
  TPostCalcProviderData,
  TPatchCalcProviderData,
  TGetCalcProviderByIdData,
  TPutCalcProviderData,
} from "Base/types/provider/api/provider"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcProviderFailureAction,
  TDeleteCalcProviderSuccessAction,
  TGetCalcProviderFailureAction,
  TGetCalcProviderSuccessAction,
  TGetCalcProviderByIdFailureAction,
  TPostCalcProviderFailureAction,
  TPostCalcProviderSuccessAction,
  TPatchCalcProviderFailureAction,
  TPatchCalcProviderSuccessAction,
  TPutCalcProviderFailureAction,
  TPutCalcProviderSuccessAction,
} from "./types"

export type CalcProviderStateType = {
  getCalcProvider: {
    data?: TGetCalcProviderData
  } & RequestInfoChildType
  getCalcProviderById: {
    data?: TGetCalcProviderByIdData
  } & RequestInfoChildType
  postCalcProvider: {
    data?: TPostCalcProviderData
  } & RequestInfoChildType
  patchCalcProvider: {
    data?: TPatchCalcProviderData
  } & RequestInfoChildType
  putCalcProvider: {
    data?: TPutCalcProviderData
  } & RequestInfoChildType
  deleteCalcProvider: {
    data?: TDeleteCalcProviderData
  } & RequestInfoChildType
}

const initialState: CalcProviderStateType = {
  getCalcProvider: {
    ...RequestInfoChildState,
  },
  getCalcProviderById: {
    ...RequestInfoChildState,
  },
  postCalcProvider: {
    ...RequestInfoChildState,
  },
  patchCalcProvider: {
    ...RequestInfoChildState,
  },
  putCalcProvider: {
    ...RequestInfoChildState,
  },
  deleteCalcProvider: {
    ...RequestInfoChildState,
  },
}

export const calcProviderReducer = createReducer(initialState, {
  [calcProviderActions.getCalcProviderAction.call.type]: (state) => ({
    ...state,
    getCalcProvider: {
      ...state.getCalcProvider,
      isLoading: true,
    },
  }),
  [calcProviderActions.getCalcProviderAction.success.type]: (
    state,
    action: TGetCalcProviderSuccessAction,
  ) => ({
    ...state,
    getCalcProvider: {
      ...state.getCalcProvider,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcProviderActions.getCalcProviderAction.failure.type]: (
    state,
    action: TGetCalcProviderFailureAction,
  ) => ({
    ...state,
    getCalcProvider: {
      ...state.getCalcProvider,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcProviderActions.getCalcProviderByIdAction.call.type]: (state) => ({
    ...state,
    getCalcProviderById: {
      ...state.getCalcProviderById,
      isLoading: true,
    },
  }),
  [calcProviderActions.getCalcProviderByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcProviderById: {
      ...state.getCalcProviderById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcProviderActions.getCalcProviderByIdAction.failure.type]: (
    state,
    action: TGetCalcProviderByIdFailureAction,
  ) => ({
    ...state,
    getCalcProviderById: {
      ...state.getCalcProviderById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcProviderActions.getCalcProviderByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcProviderById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcProviderActions.postCalcProviderAction.call.type]: (state) => ({
    ...state,
    postCalcProvider: {
      ...state.postCalcProvider,
      isLoading: true,
    },
  }),
  [calcProviderActions.postCalcProviderAction.success.type]: (
    state,
    action: TPostCalcProviderSuccessAction,
  ) => ({
    ...state,
    postCalcProvider: {
      ...state.postCalcProvider,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcProviderActions.postCalcProviderAction.failure.type]: (
    state,
    action: TPostCalcProviderFailureAction,
  ) => ({
    ...state,
    postCalcProvider: {
      ...state.postCalcProvider,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcProviderActions.patchCalcProviderAction.call.type]: (state) => ({
    ...state,
    patchCalcProvider: {
      ...state.patchCalcProvider,
      isLoading: true,
    },
  }),
  [calcProviderActions.patchCalcProviderAction.success.type]: (
    state,
    action: TPatchCalcProviderSuccessAction,
  ) => ({
    ...state,
    patchCalcProvider: {
      ...state.patchCalcProvider,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcProviderActions.patchCalcProviderAction.failure.type]: (
    state,
    action: TPatchCalcProviderFailureAction,
  ) => ({
    ...state,
    patchCalcProvider: {
      ...state.patchCalcProvider,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcProviderActions.putCalcProviderAction.call.type]: (state) => ({
    ...state,
    putCalcProvider: {
      ...state.putCalcProvider,
      isLoading: true,
    },
  }),
  [calcProviderActions.putCalcProviderAction.success.type]: (
    state,
    action: TPutCalcProviderSuccessAction,
  ) => ({
    ...state,
    putCalcProvider: {
      ...state.putCalcProvider,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcProviderActions.putCalcProviderAction.failure.type]: (
    state,
    action: TPutCalcProviderFailureAction,
  ) => ({
    ...state,
    putCalcProvider: {
      ...state.putCalcProvider,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcProviderActions.deleteCalcProviderAction.call.type]: (state) => ({
    ...state,
    deleteCalcProvider: {
      ...state.deleteCalcProvider,
      isLoading: true,
    },
  }),
  [calcProviderActions.deleteCalcProviderAction.success.type]: (
    state,
    action: TDeleteCalcProviderSuccessAction,
  ) => ({
    ...state,
    deleteCalcProvider: {
      ...state.deleteCalcProvider,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcProviderActions.deleteCalcProviderAction.failure.type]: (
    state,
    action: TDeleteCalcProviderFailureAction,
  ) => ({
    ...state,
    deleteCalcProvider: {
      ...state.deleteCalcProvider,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
