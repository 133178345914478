import React from "react"

import cx from "classnames"

import styles from "./LoaderSpinner.module.scss"

interface LoaderSpinnerProps {
  className?: string
  theme?: "primary" | "white"
}

export const LoaderSpinner = ({ className, theme = "primary" }: LoaderSpinnerProps) => (
  <div className={cx(styles.loader_spinner, className)}>
    <svg className={styles.spinner} viewBox='0 0 50 50'>
      <circle
        className={cx(styles.spinner__path, styles[`spinner__path--${theme}`])}
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='5'
      />
    </svg>
  </div>
)

export default null
