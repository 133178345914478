import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import { PlusCircleOutlined, MinusOutlined } from "@ant-design/icons"
import { clientModuleRoutes } from "Base/constants/routes"
import { useNavFormDisable } from "Base/hooks/useNavFormDisable"
import * as calcAmortizationGroupActions from "Base/store/amortizationGroup/actions"
import * as calcAssetActions from "Base/store/asset/actions"
import * as calcAssetTypeActions from "Base/store/assetType/actions"
import {
  AdditionalExpensesForm,
  ExploitationForm,
  ParamsForm,
  SubjectOfLeasingForm,
  TaxForm,
  TrancheOfPaymentToTheSupplierForm,
  ProviderForm,
} from "ClientModule/containers/subjectsOfLeasingForms"
import { StageLayout } from "ClientModule/layouts"
import { RootState } from "Starter/store/configureStore"
import { Button, Collapse, Space } from "antd"
import "dayjs/locale/ru"

const { Panel } = Collapse

export const SubjectsOfLeasing = () => {
  const dispatch = useDispatch()
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcAsset: { getCalcAsset, postCalcAsset },
    },
  } = useSelector((state: RootState) => state)

  const leadId = useMemo(() => getCalcLeadById?.data?.result.id, [getCalcLeadById?.data?.result.id])

  useEffect(() => {
    if (!leadId) return
    // dispatch(calcAssetActions.getCalcAssetAction.call({ leadId }))
    dispatch(calcAssetTypeActions.getCalcAssetTypeAction.call({}))
    dispatch(calcAmortizationGroupActions.getCalcAmortizationGroupAction.call({}))
    // dispatch(calcProviderActions.getCalcProviderAction.call({}))
  }, [leadId])

  const createAsset = () => {
    if (!leadId) return
    dispatch(calcAssetActions.postCalcAssetAction.call({ leadId }))
  }
  const deleteAsset = ({ id }: { id: string }) => {
    dispatch(calcAssetActions.deleteCalcAssetAction.call({ id }))
  }

  const [uncomplitedFormList, setUncomplitedFormList] = useState<
    {
      exploitation: boolean
      subjectOfLeasing: boolean
      provider: boolean
    }[]
  >([])

  const setIsRequiredFieldsCompleted = (index: number, form: string, isDisabled: boolean) => {
    setUncomplitedFormList((prev: any) => {
      const newState = [...prev]
      newState[index] = newState[index] || {}
      newState[index][form] = isDisabled
      return newState
    })
  }

  const isButtonsDisabled = useMemo(() => {
    if (!uncomplitedFormList) return false
    return uncomplitedFormList.some((form) => {
      if (!form) return false
      return Object.values(form)?.some((value) => value === true)
    })
  }, [uncomplitedFormList])

  useNavFormDisable(isButtonsDisabled)

  return (
    <StageLayout
      stageTitle='Шаг 2/4'
      nextLink={clientModuleRoutes["leasing-stages"].deal.root + "?leadId=" + leadId}
      backLink={clientModuleRoutes["leasing-stages"].lessee.root + "?leadId=" + leadId}
      isButtonsDisabled={isButtonsDisabled}
    >
      <Collapse accordion>
        {getCalcAsset?.data?.result.map((asset, index) => {
          return (
            <Panel
              className='panel-icon'
              key={index}
              header={
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Title style={{ margin: 0 }} level={4}>
                    Предмет лизинга {index + 1}
                  </Title>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteAsset({ id: `${asset.id}` })
                    }}
                    style={{ marginLeft: "21px" }}
                    icon={<MinusOutlined />}
                  />
                </Space>
              }
            >
              <>
                <SubjectOfLeasingForm
                  index={index}
                  asset={asset}
                  setIsRequiredFieldsCompleted={setIsRequiredFieldsCompleted}
                />
                <ParamsForm asset={asset} />
                <TaxForm asset={asset} />
                <ProviderForm
                  asset={asset}
                  setIsRequiredFieldsCompleted={setIsRequiredFieldsCompleted}
                  index={index}
                />
                <TrancheOfPaymentToTheSupplierForm asset={asset} />
                <ExploitationForm
                  setIsRequiredFieldsCompleted={setIsRequiredFieldsCompleted}
                  index={index}
                  asset={asset}
                />
                <AdditionalExpensesForm asset={asset} />
              </>
            </Panel>
          )
        })}
      </Collapse>
      <Button
        loading={postCalcAsset.isLoading || getCalcAsset.isLoading}
        disabled={postCalcAsset.isLoading || getCalcAsset.isLoading}
        type={"primary"}
        style={{ width: "100%", marginBottom: "30px", marginTop: "30px" }}
        icon={<PlusCircleOutlined />}
        onClick={createAsset}
      >
        Новый предмет лизинга
      </Button>
    </StageLayout>
  )
}
