import { baseCSF } from "Base/utils/store"

import {
  TGetCalcAssetCurrencyByIdFailurePayload,
  TGetCalcAssetCurrencyByIdReqPayload,
  TGetCalcAssetCurrencyByIdSuccessPayload,
  TGetCalcAssetCurrencyFailurePayload,
  TGetCalcAssetCurrencyReqPayload,
  TGetCalcAssetCurrencySuccessPayload,
} from "./types"

const prefix = "calc-asset-currency"

export const getCalcAssetCurrencyAction = baseCSF<
  TGetCalcAssetCurrencyReqPayload,
  TGetCalcAssetCurrencySuccessPayload,
  TGetCalcAssetCurrencyFailurePayload,
  undefined
>(prefix, "get")
export const getCalcAssetCurrencyByIdAction = baseCSF<
  TGetCalcAssetCurrencyByIdReqPayload,
  TGetCalcAssetCurrencyByIdSuccessPayload,
  TGetCalcAssetCurrencyByIdFailurePayload,
  undefined
>(prefix, "get-by-id")
