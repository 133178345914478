import React from "react"
import { NavLink } from "react-router-dom"

import Title from "antd/es/typography/Title"

import { Space } from "antd"
import hash from "object-hash"

export type TNavBarTab = {
  to: string
  title: string
  value: string
}
export const NavBarTabs = ({ navLinks }: { navLinks: TNavBarTab[] }) => {
  return (
    <Space
      style={{
        height: "50px",
      }}
      className='nav-links'
    >
      <>
        {navLinks.map((navLink) => {
          return (
            <div
              key={hash(navLink.to)}
              style={{
                marginRight: "20px",
              }}
            >
              <NavLink to={navLink.to}>
                {(event) => {
                  return (
                    <Title
                      level={5}
                      style={{
                        margin: 0,
                        color: event.isActive ? "" : "#757575",
                        borderBottom: event.isActive ? "3px solid black" : "",
                      }}
                    >
                      {navLink.title}
                    </Title>
                  )
                }}
              </NavLink>
            </div>
          )
        })}
      </>
    </Space>
  )
}
