import { all, fork } from "redux-saga/effects"

import { calcAgentWatcher } from "./agent/sagas"
import { calcAmortizationGroupWatcher } from "./amortizationGroup/sagas"
import { calcAssetWatcher } from "./asset/sagas"
import { calcAssetCurrencyWatcher } from "./assetCurrency/sagas"
import { calcAssetTypeWatcher } from "./assetType/sagas"
import { calcAssetTypeParamWatcher } from "./assetTypeParam/sagas"
import { calcAssetTypeTaxWatcher } from "./assetTypeTax/sagas"
import { calcCreditorWatcher } from "./creditor/sagas"
import { calcIndClassWatcher } from "./indClass/sagas"
import { calcLeadWatcher } from "./lead/sagas"
import { calcLesseeWatcher } from "./lessee/sagas"
import { calcPaymentOtherWatcher } from "./paymentOther/sagas"
import { calcPaymentScheduleWatcher } from "./paymentSchedule/sagas"
import { calcProviderWatcher } from "./provider/sagas"

export function* baseRootSaga() {
  try {
    yield all([fork(calcLesseeWatcher)])
    yield all([fork(calcLeadWatcher)])
    yield all([fork(calcIndClassWatcher)])
    yield all([fork(calcAssetWatcher)])
    yield all([fork(calcAssetTypeWatcher)])
    yield all([fork(calcAmortizationGroupWatcher)])
    yield all([fork(calcAssetTypeParamWatcher)])
    yield all([fork(calcAssetTypeTaxWatcher)])
    yield all([fork(calcProviderWatcher)])
    yield all([fork(calcPaymentOtherWatcher)])
    yield all([fork(calcAgentWatcher)])
    yield all([fork(calcAssetCurrencyWatcher)])
    yield all([fork(calcCreditorWatcher)])
    yield all([fork(calcPaymentScheduleWatcher)])
  } finally {
    // always runs
  }
}
