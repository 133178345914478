import { apiRequest } from "Base/provider/sagas"
import * as calcLeadActions from "Base/store/lead/actions"
import {
  TDeleteCalcLeadResp,
  TGetCalcLeadResp,
  TPostCalcLeadResp,
  TPatchCalcLeadResp,
  TGetCalcLeadByIdResp,
  TPostCalcLeadStatusResp,
  TCopyCalcLeadResp,
} from "Base/types/provider/api/lead"
import { takeLatest, call, put, delay, select } from "redux-saga/effects"

import { getCalcPaymentScheduleAction } from "../paymentSchedule/actions"
import { selectLeadId } from "./selectors"
import {
  TDeleteCalcLeadReqAction,
  TPostCalcLeadReqAction,
  TPatchCalcLeadReqAction,
  TGetCalcLeadByIdReqAction,
  TPostCalcLeadStatusReqAction,
  TCopyCalcLeadReqAction,
} from "./types"

export function* getCalcLeadSaga() {
  try {
    const resp: TGetCalcLeadResp = yield call(apiRequest, "getCalcLead")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.getCalcLeadAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLeadActions.getCalcLeadAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcLeadByIdSaga({ payload }: TGetCalcLeadByIdReqAction) {
  try {
    const resp: TGetCalcLeadByIdResp = yield call(apiRequest, "getCalcLeadById", {
      params: payload,
    })
    console.log("getCalcLeadByIdSaga, resp: ", resp)
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.getCalcLeadByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLeadActions.getCalcLeadByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcLeadSaga({ payload }: TPostCalcLeadReqAction) {
  try {
    const resp: TPostCalcLeadResp = yield call(apiRequest, "postCalcLead", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.postCalcLeadAction.success(resp.data))
    yield put(calcLeadActions.getCalcLeadByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLeadActions.postCalcLeadAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcLeadStatusSaga({ payload }: TPostCalcLeadStatusReqAction) {
  try {
    const resp: TPostCalcLeadStatusResp = yield call(apiRequest, "postCalcLeadStatus", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.postCalcLeadStatusAction.success(resp.data))
    yield put(calcLeadActions.getCalcLeadByIdAction.call({ id: payload.id }))
  } catch (e: unknown) {
    yield put(
      calcLeadActions.postCalcLeadStatusAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* patchCalcLeadSaga({ payload }: TPatchCalcLeadReqAction) {
  try {
    const resp: TPatchCalcLeadResp = yield call(apiRequest, "patchCalcLead", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.patchCalcLeadAction.success(resp.data))
    yield put(calcLeadActions.getCalcLeadByIdAction.success(resp.data))
    const leadId: number = yield select(selectLeadId)

    if (leadId) {
      yield delay(500)
      yield put(
        getCalcPaymentScheduleAction.call({
          leadId,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcLeadActions.patchCalcLeadAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcLeadSaga({ payload }: TDeleteCalcLeadReqAction) {
  try {
    const resp: TDeleteCalcLeadResp = yield call(apiRequest, "deleteCalcLead", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.deleteCalcLeadAction.success(resp.data))
    yield put(calcLeadActions.getCalcLeadAction.call({}))
  } catch (e: unknown) {
    yield put(
      calcLeadActions.deleteCalcLeadAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* copyCalcLeadSaga({ payload }: TCopyCalcLeadReqAction) {
  try {
    const resp: TCopyCalcLeadResp = yield call(apiRequest, "copyCalcLead", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLeadActions.getCalcLeadAction.call({}))
  } catch (e: unknown) {
    yield put(
      calcLeadActions.copyCalcLeadAction.failure({
        error: e?.toString() || "",
      }),
    )
  }
}

export function* calcLeadWatcher() {
  yield takeLatest(calcLeadActions.getCalcLeadAction.call.type, getCalcLeadSaga)
  yield takeLatest(calcLeadActions.getCalcLeadByIdAction.call.type, getCalcLeadByIdSaga)
  yield takeLatest(calcLeadActions.postCalcLeadAction.call.type, postCalcLeadSaga)
  yield takeLatest(calcLeadActions.postCalcLeadStatusAction.call.type, postCalcLeadStatusSaga)
  yield takeLatest(calcLeadActions.patchCalcLeadAction.call.type, patchCalcLeadSaga)
  yield takeLatest(calcLeadActions.deleteCalcLeadAction.call.type, deleteCalcLeadSaga)
  yield takeLatest(calcLeadActions.copyCalcLeadAction.call.type, copyCalcLeadSaga)
}
