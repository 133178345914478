import React from "react"
import { Route, Navigate } from "react-router-dom"

import { clientModuleRoutes } from "Base/constants/routes/clientModuleRoutes"
import { LeasingLayout, LeasingStagesLayout } from "ClientModule/layouts"
import {
  Agency,
  Deal,
  SubjectsOfLeasing,
  ListOfLeasePaymentSchedules,
  Lessee,
  LeasePaymentSchedule,
} from "ClientModule/pages"
import { HistoryChanges } from "ClientModule/pages/History"
import { LeadProvider } from "ClientModule/providers"

export const getClientRouters = () => {
  return (
    <>
      <Route
        path={clientModuleRoutes["list-of-lease-payment-schedules"].root}
        element={
          <LeasingLayout>
            <ListOfLeasePaymentSchedules />
          </LeasingLayout>
        }
      />
      <Route
        path={clientModuleRoutes["leasing-stages"].lessee.root}
        element={
          <LeasingStagesLayout>
            <Lessee />
          </LeasingStagesLayout>
        }
      />
      <Route
        path={clientModuleRoutes["leasing-stages"]["subject-of-leasing"].root}
        element={
          <LeasingStagesLayout>
            <SubjectsOfLeasing />
          </LeasingStagesLayout>
        }
      />
      <Route
        path={clientModuleRoutes["leasing-stages"].deal.root}
        element={
          <LeasingStagesLayout>
            <Deal />
          </LeasingStagesLayout>
        }
      />
      <Route
        path={clientModuleRoutes["leasing-stages"].agency.root}
        element={
          <LeasingStagesLayout>
            <Agency />
          </LeasingStagesLayout>
        }
      />
      <Route
        path={clientModuleRoutes["lease-payment-history"].root}
        element={
          <LeasingLayout>
            <LeadProvider>
              <HistoryChanges />
            </LeadProvider>
          </LeasingLayout>
        }
      />
      <Route
        path={clientModuleRoutes["lease-payment-schedule"].root}
        element={
          <LeasingLayout>
            <LeadProvider>
              <LeasePaymentSchedule />
            </LeadProvider>
          </LeasingLayout>
        }
      />
      <Route
        path={`*`}
        element={
          <Navigate
            to={clientModuleRoutes["list-of-lease-payment-schedules"].root}
            replace={false}
          />
        }
      />
    </>
  )
}
