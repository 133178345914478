import React, { useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

import { LoaderSpinner } from "Base/components"
import { clientModuleRoutes } from "Base/constants/routes"
import { useNavFormDisable } from "Base/hooks/useNavFormDisable"
import * as calcLeadActions from "Base/store/lead/actions"
import { TPatchCalcLeadParams } from "Base/types/provider/api/lead"
import { DealDataForm, FinancingForm, OtherExpensesForm } from "ClientModule/containers/deal"
import { StageLayout } from "ClientModule/layouts/StageLayout"
import { RootState } from "Starter/store/configureStore"
import { Button, Form, Layout } from "antd"
import { format } from "date-fns"
import { StringParam, useQueryParam } from "use-query-params"

const { Content } = Layout

export const Deal: React.FC = () => {
  const [leadId] = useQueryParam("leadId", StringParam)
  const dispatch = useDispatch()

  const [dealForm] = Form.useForm()
  const [financingForm] = Form.useForm()
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [amount, setAmount] = useState(0)

  const dealFormValues = Form.useWatch([], dealForm)
  const financingFormValues = Form.useWatch([], financingForm)

  const {
    base: {
      calcLead: { getCalcLeadById },
    },
  } = useSelector((state: RootState) => state)

  const requiredDealFormValues = useMemo(
    () => ({
      initialPayment: dealFormValues?.initialPayment,
      interestRate: dealFormValues?.interestRate,
      term: dealFormValues?.term,
      issueDate: dealFormValues?.issueDate,
      firstPaymentDate: dealFormValues?.firstPaymentDate,
      monthlyPaymentDay: dealFormValues?.monthlyPaymentDay,
      balanceHolder: dealFormValues?.balanceHolder,
      paymentType: dealFormValues?.paymentType,
    }),
    [dealFormValues],
  )

  const requiredFinancingFormValues = useMemo(
    () => ({
      term2: financingFormValues?.term,
      interestRate2: financingFormValues?.interestRate,
    }),
    [financingFormValues],
  )

  const isNavButtonsDisabled = useMemo(
    () =>
      Object.values({ ...requiredDealFormValues, ...requiredFinancingFormValues }).some(
        (value) => !value || value === 0,
      ),
    [requiredDealFormValues, requiredFinancingFormValues],
  )

  useNavFormDisable(isNavButtonsDisabled)

  const handleFormChange = () => {
    setIsFormChanged(true)
  }

  const handleSave = () => {
    if (!getCalcLeadById?.data?.result.id) return
    const dealValues = dealForm.getFieldsValue()
    const financingValues = financingForm.getFieldsValue()

    const requestData: TPatchCalcLeadParams = {
      id: getCalcLeadById.data.result.id,
      name: getCalcLeadById.data.result.name,
      leaseData: {
        initialPayment: dealValues.initialPayment,
        interestRate: dealValues.interestRate,
        term: Number(dealValues.term?.toFixed(0)),
        issueDate: format(new Date(dealValues.issueDate), "yyyy-MM-dd"),
        firstPaymentDate: format(new Date(dealValues.firstPaymentDate), "yyyy-MM-dd"),
        monthlyPaymentDay: Number(dealValues.monthlyPaymentDay?.toFixed(0)),
        balanceHolder: dealValues.balanceHolder,
        paymentType: dealValues.paymentType,
      },
      loanData: {
        creditor: financingValues.creditor,
        amount: amount || getCalcLeadById.data.result.loanData?.amount || 0,
        interestRate: financingValues.interestRate,
        term: Number(financingValues.term?.toFixed(0)),
      },
    }

    dispatch(calcLeadActions.patchCalcLeadAction.call(requestData))
    setIsFormChanged(false)
  }

  return (
    <StageLayout
      data-deal-disabled={isNavButtonsDisabled}
      isButtonsDisabled={isNavButtonsDisabled}
      stageTitle='Шаг 3/4'
      nextLink={clientModuleRoutes["leasing-stages"].agency.root + "?leadId=" + leadId}
      backLink={
        clientModuleRoutes["leasing-stages"]["subject-of-leasing"].root + "?leadId=" + leadId
      }
    >
      <Content style={{ padding: "20px 0", position: "relative" }}>
        {getCalcLeadById.isLoading && <LoaderSpinner />}
        <DealDataForm form={dealForm} setAmount={setAmount} onChange={handleFormChange} />
        <OtherExpensesForm />
        <FinancingForm form={financingForm} amount={amount} onChange={handleFormChange} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            top: "20px",
            right: 0,
          }}
        >
          <Button type='primary' onClick={handleSave} disabled={!isFormChanged}>
            Сохранить
          </Button>
        </div>
      </Content>
    </StageLayout>
  )
}
