import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAssetTypeTaxActions from "Base/store/assetTypeTax/actions"
import {
  TDeleteCalcAssetTypeTaxData,
  TGetCalcAssetTypeTaxByIdData,
  TGetCalcAssetTypeTaxData, // TPatchCalcAssetTypeTaxData,
  TPostCalcAssetTypeTaxData,
} from "Base/types/provider/api/assetTypeTax"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcAssetTypeTaxFailureAction,
  TDeleteCalcAssetTypeTaxSuccessAction,
  TGetCalcAssetTypeTaxByIdFailureAction,
  TGetCalcAssetTypeTaxFailureAction,
  TGetCalcAssetTypeTaxSuccessAction, // TPatchCalcAssetTypeTaxFailureAction,
  // TPatchCalcAssetTypeTaxSuccessAction,
  TPostCalcAssetTypeTaxFailureAction,
  TPostCalcAssetTypeTaxSuccessAction,
} from "./types"

export type CalcAssetTypeTaxStateType = {
  getCalcAssetTypeTax: {
    data?: { [key: string]: TGetCalcAssetTypeTaxData }
  } & RequestInfoChildType
  getCalcAssetTypeTaxById: {
    data?: TGetCalcAssetTypeTaxByIdData
  } & RequestInfoChildType
  postCalcAssetTypeTax: {
    data?: TPostCalcAssetTypeTaxData
  } & RequestInfoChildType
  // patchCalcAssetTypeTax: {
  //   data?: TPatchCalcAssetTypeTaxData
  // } & RequestInfoChildType
  deleteCalcAssetTypeTax: {
    data?: TDeleteCalcAssetTypeTaxData
  } & RequestInfoChildType
}

const initialState: CalcAssetTypeTaxStateType = {
  getCalcAssetTypeTax: {
    ...RequestInfoChildState,
  },
  getCalcAssetTypeTaxById: {
    ...RequestInfoChildState,
  },
  postCalcAssetTypeTax: {
    ...RequestInfoChildState,
  },
  // patchCalcAssetTypeTax: {
  //   ...RequestInfoChildState,
  // },
  deleteCalcAssetTypeTax: {
    ...RequestInfoChildState,
  },
}

export const calcAssetTypeTaxReducer = createReducer(initialState, {
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.call.type]: (state) => ({
    ...state,
    getCalcAssetTypeTax: {
      ...state.getCalcAssetTypeTax,
      isLoading: true,
    },
  }),
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.success.type]: (
    state,
    action: TGetCalcAssetTypeTaxSuccessAction,
  ) => ({
    ...state,
    getCalcAssetTypeTax: {
      ...state.getCalcAssetTypeTax,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.failure.type]: (
    state,
    action: TGetCalcAssetTypeTaxFailureAction,
  ) => ({
    ...state,
    getCalcAssetTypeTax: {
      ...state.getCalcAssetTypeTax,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAssetTypeTaxById: {
      ...state.getCalcAssetTypeTaxById,
      isLoading: true,
    },
  }),
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcAssetTypeTaxById: {
      ...state.getCalcAssetTypeTaxById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.failure.type]: (
    state,
    action: TGetCalcAssetTypeTaxByIdFailureAction,
  ) => ({
    ...state,
    getCalcAssetTypeTaxById: {
      ...state.getCalcAssetTypeTaxById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.initial.type]: (state) => {
    return {
      ...state,
      getCalcAssetTypeTaxById: {
        data: undefined,
        isLoading: false,
        error: undefined,
      },
    }
  },
  //============================================================
  //============================================================
  [calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.call.type]: (state) => ({
    ...state,
    postCalcAssetTypeTax: {
      ...state.postCalcAssetTypeTax,
      isLoading: true,
    },
  }),
  [calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.success.type]: (
    state,
    action: TPostCalcAssetTypeTaxSuccessAction,
  ) => ({
    ...state,
    postCalcAssetTypeTax: {
      ...state.postCalcAssetTypeTax,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.failure.type]: (
    state,
    action: TPostCalcAssetTypeTaxFailureAction,
  ) => ({
    ...state,
    postCalcAssetTypeTax: {
      ...state.postCalcAssetTypeTax,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  // [calcAssetTypeTaxActions.patchCalcAssetTypeTaxAction.call.type]: (state) => ({
  //   ...state,
  //   patchCalcAssetTypeTax: {
  //     ...state.patchCalcAssetTypeTax,
  //     isLoading: true,
  //   },
  // }),
  // [calcAssetTypeTaxActions.patchCalcAssetTypeTaxAction.success.type]: (
  //   state,
  //   action: TPatchCalcAssetTypeTaxSuccessAction,
  // ) => ({
  //   ...state,
  //   patchCalcAssetTypeTax: {
  //     ...state.patchCalcAssetTypeTax,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [calcAssetTypeTaxActions.patchCalcAssetTypeTaxAction.failure.type]: (
  //   state,
  //   action: TPatchCalcAssetTypeTaxFailureAction,
  // ) => ({
  //   ...state,
  //   patchCalcAssetTypeTax: {
  //     ...state.patchCalcAssetTypeTax,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  //============================================================
  //============================================================
  [calcAssetTypeTaxActions.deleteCalcAssetTypeTaxAction.call.type]: (state) => ({
    ...state,
    deleteCalcAssetTypeTax: {
      ...state.deleteCalcAssetTypeTax,
      isLoading: true,
    },
  }),
  [calcAssetTypeTaxActions.deleteCalcAssetTypeTaxAction.success.type]: (
    state,
    action: TDeleteCalcAssetTypeTaxSuccessAction,
  ) => ({
    ...state,
    deleteCalcAssetTypeTax: {
      ...state.deleteCalcAssetTypeTax,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeTaxActions.deleteCalcAssetTypeTaxAction.failure.type]: (
    state,
    action: TDeleteCalcAssetTypeTaxFailureAction,
  ) => ({
    ...state,
    deleteCalcAssetTypeTax: {
      ...state.deleteCalcAssetTypeTax,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
