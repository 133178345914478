import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import * as calcAssetActions from "Base/store/asset/actions"
import * as calcAssetTypeTaxActions from "Base/store/assetTypeTax/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { RootState } from "Starter/store/configureStore"
import { Form } from "antd"
import { format } from "date-fns"
import dayjs from "dayjs"
import "dayjs/locale/ru"

import { TaxItem } from "./components/TaxItem"

const typeTax = "typeTax"
const commentTax = "commentTax"
const dateTax = "dateTax"
const amountTax = "amountTax"

export const TaxForm = ({ asset }: { asset: TCalcAsset }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {
    base: {
      calcAssetTypeTax: { getCalcAssetTypeTax },
      calcAsset: { patchCalcAsset },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (!asset?.type?.id || getCalcAssetTypeTax.data?.[asset?.type?.id]) return
    dispatch(calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.call({ assetType: asset.type.id }))
  }, [asset?.type?.id, getCalcAssetTypeTax.data])

  useEffect(() => {
    asset?.taxes.forEach((tax, index) => {
      form.setFieldValue(typeTax + index, tax.type.id)
      form.setFieldValue(amountTax + index, tax.amount)
      form.setFieldValue(commentTax + index, tax.comment)
      tax?.date && form.setFieldValue(dateTax + index, dayjs(tax.date, "YYYY-MM-DD"))
    })
  }, [asset])

  const isDisableForm = useMemo(() => {
    return getCalcAssetTypeTax.isLoading || patchCalcAsset.isLoading
  }, [getCalcAssetTypeTax, patchCalcAsset])

  const assetTypeTaxOptions = useMemo(() => {
    if (!asset?.type?.id || !getCalcAssetTypeTax.data?.[asset.type.id]?.result) return []
    return getCalcAssetTypeTax.data?.[asset.type.id]?.result.map((assetTypeTax) => {
      return { value: assetTypeTax.id, label: assetTypeTax.name }
    })
  }, [getCalcAssetTypeTax.data, asset?.type])

  const createTax = () => {
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        taxes: [
          ...asset.taxes.map((tax) => ({
            type: tax.type.id,
            date: tax.date,
            amount: tax.amount,
            comment: tax.amount,
          })),
          {
            type: form.getFieldValue(typeTax),
            date: form.getFieldValue(dateTax),
            amount: form.getFieldValue(amountTax),
            comment: form.getFieldValue(commentTax),
          },
        ],
      }),
    )
    form.setFieldValue(typeTax, undefined)
    form.setFieldValue(dateTax, undefined)
    form.setFieldValue(amountTax, undefined)
    form.setFieldValue(commentTax, undefined)
  }

  const deleteTax = (index: number) => {
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        taxes: asset.taxes
          .filter((tax, _index) => index !== _index)
          .map((tax) => ({
            type: tax.type.id,
            date: tax.date,
            amount: tax?.amount,
          })),
      }),
    )
  }

  const onChangeTax = async (index: number) => {
    let isChanged = false
    const fieldNames = [typeTax + index, dateTax + index, amountTax + index, commentTax + index]
    return form.validateFields(fieldNames).then(() => {
      const taxes = asset.taxes.map((tax, _index) => {
        if (
          index !== _index ||
          (form.getFieldValue(typeTax + index) == tax.type.id &&
            form.getFieldValue(dateTax + index) === tax.date &&
            form.getFieldValue(amountTax + index) === tax.amount &&
            form.getFieldValue(commentTax + index) === tax.comment)
        ) {
          return {
            type: tax.type.id,
            date: tax.date,
            amount: tax.amount,
            comment: tax.comment,
          }
        }
        if (!isChanged) isChanged = true

        return {
          type: form.getFieldValue(typeTax + index),
          date: format(new Date(form.getFieldValue(dateTax + index)), "yyyy-MM-dd"),
          amount: form.getFieldValue(amountTax + index),
          comment: form.getFieldValue(commentTax + index),
        }
      })
      if (!isChanged) return
      dispatch(
        calcAssetActions.patchCalcAssetAction.call({
          id: asset.id,
          taxes,
        }),
      )
    })
  }

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Налог
      </Title>
      <Form
        onFinish={createTax}
        disabled={isDisableForm}
        name='tax_form'
        form={form}
        layout={"vertical"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {asset?.taxes.map((tax, index) => (
            <TaxItem
              index={index}
              key={index}
              assetTypeTaxOptions={assetTypeTaxOptions}
              onUpdate={() => onChangeTax(index)}
              onDeleteClick={() => deleteTax(index)}
            />
          ))}
        </div>

        <TaxItem isCreateMode assetTypeTaxOptions={assetTypeTaxOptions} />
      </Form>
    </>
  )
}
