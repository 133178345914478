import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcAssetParams,
  TDeleteCalcAssetReq,
  TDeleteCalcAssetResp,
  TGetCalcAssetByIdParams,
  TGetCalcAssetByIdReq,
  TGetCalcAssetByIdResp,
  TGetCalcAssetParams,
  TGetCalcAssetReq,
  TGetCalcAssetResp,
  TPatchCalcAssetParams,
  TPatchCalcAssetReq,
  TPatchCalcAssetResp,
  TPostCalcAssetParams,
  TPostCalcAssetReq,
  TPostCalcAssetResp,
  TPutCalcAssetParams,
  TPutCalcAssetReq,
  TPutCalcAssetResp,
} from "Base/types/provider/api/asset"

export default class AssetProvider extends ApiProviderDefault {
  static async deleteCalcAsset(parameters: { params: TDeleteCalcAssetParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcAssetReq, TDeleteCalcAssetResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetById(parameters: { params: TGetCalcAssetByIdParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetByIdReq, TGetCalcAssetByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAsset(parameters: { params: TGetCalcAssetParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/asset`
    const body = undefined
    const query = parameters.params
    return this.request<TGetCalcAssetReq, TGetCalcAssetResp>(path, "GET", query, body, headers)
  }

  static async postCalcAsset(parameters: { params: TPostCalcAssetParams; headers: Headers }) {
    const {
      params: { leadId },
      headers,
    } = parameters
    const path = "/calc/asset"
    const query = { leadId }
    const body = undefined
    return this.request<TPostCalcAssetReq, TPostCalcAssetResp>(path, "POST", query, body, headers)
  }

  static async patchCalcAsset(parameters: { params: TPatchCalcAssetParams; headers: Headers }) {
    const {
      params: {
        id,
        params,
        type,
        taxes,
        value,
        provider,
        adr,
        name,
        commission,
        tranche,
        amortizationGroup,
        commissionDate,
        rate,
        currency,
        okof,
      },
      headers,
    } = parameters
    const path = `/calc/asset/${id}`
    const body = {
      params,
      type,
      taxes,
      value,
      provider,
      adr,
      name,
      commission,
      tranche,
      amortizationGroup,
      commissionDate,
      rate,
      currency,
      okof,
    }
    const query = undefined
    return this.request<TPatchCalcAssetReq, TPatchCalcAssetResp>(
      path,
      "PATCH",
      query,
      body,
      headers,
    )
  }

  static async putCalcAsset(parameters: { params: TPutCalcAssetParams; headers: Headers }) {
    const {
      params: {
        id,
        name,
        params,
        amortizationGroup,
        commission,
        tranche,
        provider,
        adr,
        type,
        taxes,
        value,
        commissionDate,
        currency,
        rate,
        okof,
      },
      headers,
    } = parameters
    const path = `/calc/asset/${id}`
    const body = {
      name,
      params,
      amortizationGroup,
      commission,
      tranche,
      provider,
      adr,
      type,
      taxes,
      value,
      commissionDate,
      currency,
      rate,
      okof,
    }
    const query = undefined
    return this.request<TPutCalcAssetReq, TPutCalcAssetResp>(path, "PUT", query, body, headers)
  }
}
