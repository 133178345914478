import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import { FormInstance } from "antd/lib"

import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { RootState } from "Starter/store/configureStore"
import { Form, InputNumber, Select, Typography } from "antd"

const { Item } = Form
const { Title } = Typography

export const FinancingForm: React.FC<{
  amount?: number
  form: FormInstance<any>
  onChange: () => void
}> = ({ amount, form, onChange }) => {
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcCreditor: { getCalcCreditor },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    const loanData = getCalcLeadById?.data?.result?.loanData
    if (loanData) {
      form.setFieldsValue({
        creditor: loanData.creditor?.id,
        term: loanData.term,
        interestRate: loanData.interestRate,
        amount: loanData.amount,
      })
    }
  }, [getCalcLeadById?.data?.result?.loanData, form])

  useEffect(() => {
    if (amount) form.setFieldsValue({ amount })
  }, [amount, form])

  const isDisableForm = useMemo(() => {
    return getCalcLeadById.isLoading
  }, [getCalcLeadById])

  const creditorOptions = useMemo(() => {
    if (!getCalcCreditor.data?.result) return []
    return getCalcCreditor.data?.result.map((creditor) => ({
      value: creditor.id,
      label: creditor.name,
    }))
  }, [getCalcCreditor.data])

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Финансирование
      </Title>
      <Form
        disabled={isDisableForm}
        name='financingForm'
        form={form}
        layout={"vertical"}
        onValuesChange={onChange}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "24px",
          }}
        >
          <Item name='creditor' label='Наименование банка, предоставляющего кредит'>
            <Select
              className='select-custom'
              size='large'
              showSearch
              style={{ width: "100%" }}
              placeholder='Выберите банк'
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={creditorOptions}
            />
          </Item>
          <Item required name='amount' label='Сумма кредитования'>
            <InputNumber
              formatter={convertNumberToLocalString}
              parser={convertLocalStringToNumber}
              style={{ width: "100%", background: "white", color: "black", cursor: "inherit" }}
              size='large'
              disabled
              precision={2}
            />
          </Item>
          <Item required name='term' label='Срок кредитования (мес.)'>
            <InputNumber
              min={1}
              max={99}
              formatter={convertNumberToLocalString}
              parser={convertLocalStringToNumber}
              style={{ width: "100%" }}
              size='large'
              placeholder='Введите срок'
            />
          </Item>
          <Item required name='interestRate' label='Процентная ставка кредитования'>
            <InputNumber
              formatter={convertNumberToLocalString}
              parser={convertLocalStringToNumber}
              style={{ width: "100%" }}
              size='large'
              placeholder='Введите процент'
            />
          </Item>
        </div>
      </Form>
    </>
  )
}
