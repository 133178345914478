import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import * as calcAssetActions from "Base/store/asset/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { RootState } from "Starter/store/configureStore"
import { Form } from "antd"
import { format } from "date-fns"
import dayjs from "dayjs"
import "dayjs/locale/ru"

import { TrancheItem } from "./components/TrancheItem"

const dateTranche = "dateTranche"
const amountTranche = "amountTranche"
const currencyTranche = "currencyTranche"
const rateTranche = "rateTranche"
const rateDateTranche = "rateDateTranche"

export const TrancheOfPaymentToTheSupplierForm = ({ asset }: { asset: TCalcAsset }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {
    base: {
      calcAsset: { patchCalcAsset, getCalcAsset },
      calcAssetCurrency: { getCalcAssetCurrency },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    asset?.tranche.forEach((tranche, index) => {
      form.setFieldValue(amountTranche + index, tranche?.amount)
      form.setFieldValue(currencyTranche + index, tranche?.currency)
      tranche?.date && form.setFieldValue(dateTranche + index, dayjs(tranche.date, "YYYY-MM-DD"))
      tranche?.rate?.date &&
        form.setFieldValue(rateDateTranche + index, dayjs(tranche.rate.date, "YYYY-MM-DD"))
      form.setFieldValue(rateTranche + index, tranche?.rate?.rate)
    })
  }, [asset])

  const isDisableForm = useMemo(() => {
    return getCalcAsset.isLoading || patchCalcAsset.isLoading
  }, [patchCalcAsset, getCalcAsset])

  const assetCurrencyOptions = useMemo(() => {
    if (!getCalcAssetCurrency.data?.result) return []
    return getCalcAssetCurrency.data?.result.map((currency) => {
      return { value: currency.id, label: currency.name + " (" + currency.isoCode + ")" }
    })
  }, [getCalcAssetCurrency.data])

  const validateCurrentFormGroup = async (index: number | "" = "") => {
    const fieldNames = [
      dateTranche + index,
      amountTranche + index,
      currencyTranche + index,
      rateTranche + index,
      rateDateTranche + index,
    ]
    return form.validateFields(fieldNames)
  }

  const createTranche = () => {
    validateCurrentFormGroup()
      .then(() => {
        dispatch(
          calcAssetActions.patchCalcAssetAction.call({
            id: asset.id,
            tranche: [
              ...asset.tranche.map((tranche) => ({
                date: tranche.date,
                amount: tranche.amount,
                currency: tranche.currency,
                rate: tranche.rate,
              })),
              {
                date: form.getFieldValue(dateTranche),
                amount: form.getFieldValue(amountTranche),
                currency: form.getFieldValue(currencyTranche),
                rate: {
                  rate: form.getFieldValue(rateTranche),
                  date: form.getFieldValue(rateDateTranche),
                },
              },
            ],
          }),
        )
        form.setFieldValue(dateTranche, undefined)
        form.setFieldValue(amountTranche, undefined)
        form.setFieldValue(currencyTranche, undefined)
        form.setFieldValue(rateTranche, undefined)
        form.setFieldValue(rateDateTranche, undefined)
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  const deleteTranche = (index: number) => {
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        tranche: asset.tranche
          .filter((tranche, _index) => index !== _index)
          .map((tranche) => ({
            date: tranche?.date,
            amount: tranche?.amount,
            currency: tranche?.currency,
            rate: tranche?.rate,
          })),
      }),
    )
  }

  const onChangeTranche = async (index: number | "") => {
    const fieldNames = [
      dateTranche + index,
      amountTranche + index,
      currencyTranche + index,
      rateTranche + index,
      rateDateTranche + index,
    ]
    return form.validateFields(fieldNames).then(() => {
      let isChanged = false
      const tranche = asset.tranche.map((tranche, _index) => {
        if (
          index !== _index ||
          (format(new Date(form.getFieldValue(dateTranche + index)), "yyyy-MM-dd") ===
            tranche.date &&
            form.getFieldValue(amountTranche + _index) === tranche.amount &&
            form.getFieldValue(rateTranche + _index) === tranche.rate.rate &&
            format(new Date(form.getFieldValue(rateDateTranche + index)), "yyyy-MM-dd") ===
              tranche.rate.date &&
            form.getFieldValue(currencyTranche + _index) === tranche.currency)
        ) {
          return {
            date: tranche?.date && format(new Date(tranche?.date), "yyyy-MM-dd"),
            amount: tranche?.amount,
            currency: tranche?.currency,
            rate: {
              rate: tranche?.rate?.rate,
              date: tranche?.rate?.date && format(new Date(tranche?.rate?.date), "yyyy-MM-dd"),
            },
          }
        }
        if (!isChanged) isChanged = true

        return {
          date:
            form.getFieldValue(dateTranche + index) &&
            format(new Date(form.getFieldValue(dateTranche + index)), "yyyy-MM-dd"),
          amount: form.getFieldValue(amountTranche + index),
          currency: form.getFieldValue(currencyTranche + index),
          rate: {
            rate: form.getFieldValue(rateTranche + index),
            date:
              form.getFieldValue(rateDateTranche + index) &&
              format(new Date(form.getFieldValue(rateDateTranche + index)), "yyyy-MM-dd"),
          },
        }
      })
      if (!isChanged) return
      dispatch(
        calcAssetActions.patchCalcAssetAction.call({
          id: asset.id,
          tranche,
        }),
      )
    })
  }
  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Транш оплаты поставщику
      </Title>
      <Form
        disabled={isDisableForm}
        name='tranche'
        form={form}
        layout={"vertical"}
        onFinish={createTranche}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {asset?.tranche.map((_, index) => (
            <TrancheItem
              key={index}
              index={index}
              onDeleteClick={() => deleteTranche(index)}
              onUpdate={() => onChangeTranche(index)}
              assetCurrencyOptions={assetCurrencyOptions}
              validate={validateCurrentFormGroup}
            />
          ))}
        </div>

        <TrancheItem
          validate={validateCurrentFormGroup}
          isCreateMode
          assetCurrencyOptions={assetCurrencyOptions}
          onAddClick={createTranche}
        />
      </Form>
    </>
  )
}
