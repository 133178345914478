import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcPaymentOtherFailurePayload,
  TDeleteCalcPaymentOtherReqPayload,
  TDeleteCalcPaymentOtherSuccessPayload,
  TGetCalcPaymentOtherFailurePayload,
  TGetCalcPaymentOtherReqPayload,
  TGetCalcPaymentOtherSuccessPayload,
  TPostCalcPaymentOtherFailurePayload,
  TPostCalcPaymentOtherReqPayload,
  TPostCalcPaymentOtherSuccessPayload,
  TPatchCalcPaymentOtherFailurePayload,
  TPatchCalcPaymentOtherReqPayload,
  TPatchCalcPaymentOtherSuccessPayload,
  TGetCalcPaymentOtherByIdReqPayload,
  TGetCalcPaymentOtherByIdSuccessPayload,
  TGetCalcPaymentOtherByIdFailurePayload,
  TPutCalcPaymentOtherReqPayload,
  TPutCalcPaymentOtherSuccessPayload,
  TPutCalcPaymentOtherFailurePayload,
  TPutCalcPaymentOtherByAgentIdFailurePayload,
  TPutCalcPaymentOtherByAgentIdReqPayload,
  TDeleteCalcPaymentOtherByPropertiesReqPayload,
  TDeleteCalcPaymentOtherByPropertiesSuccessPayload,
  TDeleteCalcPaymentOtherByPropertiesFailurePayload,
} from "./types"

const prefix = "calc-payment-other"

export const getCalcPaymentOtherAction = baseCSF<
  TGetCalcPaymentOtherReqPayload,
  TGetCalcPaymentOtherSuccessPayload,
  TGetCalcPaymentOtherFailurePayload,
  undefined
>(prefix, "get")
export const getCalcPaymentOtherByIdAction = baseCSF<
  TGetCalcPaymentOtherByIdReqPayload,
  TGetCalcPaymentOtherByIdSuccessPayload,
  TGetCalcPaymentOtherByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcPaymentOtherAction = baseCSF<
  TPostCalcPaymentOtherReqPayload,
  TPostCalcPaymentOtherSuccessPayload,
  TPostCalcPaymentOtherFailurePayload,
  undefined
>(prefix, "post")

export const putCalcPaymentOtherAction = baseCSF<
  TPutCalcPaymentOtherReqPayload,
  TPutCalcPaymentOtherSuccessPayload,
  TPutCalcPaymentOtherFailurePayload,
  undefined
>(prefix, "put")

export const putCalcPaymentOtherByAgentIdAction = baseCSF<
  TPutCalcPaymentOtherByAgentIdReqPayload,
  undefined,
  TPutCalcPaymentOtherByAgentIdFailurePayload,
  undefined
>(prefix, "put-by-agent-id")

export const patchCalcPaymentOtherAction = baseCSF<
  TPatchCalcPaymentOtherReqPayload,
  TPatchCalcPaymentOtherSuccessPayload,
  TPatchCalcPaymentOtherFailurePayload,
  undefined
>(prefix, "patch")

export const deleteCalcPaymentOtherAction = baseCSF<
  TDeleteCalcPaymentOtherReqPayload,
  TDeleteCalcPaymentOtherSuccessPayload,
  TDeleteCalcPaymentOtherFailurePayload,
  undefined
>(prefix, "delete")

export const deleteCalcPaymentOtherByPropertiesAction = baseCSF<
  TDeleteCalcPaymentOtherByPropertiesReqPayload,
  TDeleteCalcPaymentOtherByPropertiesSuccessPayload,
  TDeleteCalcPaymentOtherByPropertiesFailurePayload,
  undefined
>(prefix, "delete-by-properties")
