import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import { ListFormActions } from "Base/components/ListFormActions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import { TCalcAgent } from "Base/types/provider/api/agent"
import { TCalcPaymentOther } from "Base/types/provider/api/paymentOther"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { nonZeroNumber, required } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { DatePicker, Form, InputNumber } from "antd"
import { format } from "date-fns"
import dayjs from "dayjs"
import "dayjs/locale/ru"

const { Item } = Form

const dateName = "date"
const amountName = "amount"

const AgencyPaymentItem = ({
  paymentOther,
  onDeleteClick,
  onAddClick,
  onUpdate,
  isCreateMode = false,
}: {
  paymentOther?: TCalcPaymentOther
  onDeleteClick?: (index: number) => void
  onAddClick?: () => void
  onUpdate?: () => Promise<void>
  isCreateMode?: boolean
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const isReadonly = !isEdit && !isCreateMode

  const onLocalEditClick = () => {
    if (isEdit) {
      onUpdate &&
        onUpdate()
          .then(() => {
            setIsEdit(false)
          })
          .catch(() => setIsEdit(true))
    } else {
      setIsEdit(true)
    }
  }
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr auto",
        columnGap: "24px",
      }}
    >
      <Item
        rules={[required]}
        required
        name={dateName + (paymentOther?.id || "")}
        label='Дата'
        style={{ margin: 0 }}
      >
        <DatePicker
          disabled={isReadonly}
          size='large'
          style={{ width: "100%" }}
          format='DD-MM-YYYY'
        />
      </Item>

      <Item
        required
        label='Сумма'
        rules={[required, nonZeroNumber]}
        name={amountName + (paymentOther?.id || "")}
      >
        <InputNumber
          disabled={isReadonly}
          formatter={convertNumberToLocalString}
          parser={convertLocalStringToNumber}
          size='large'
          style={{ width: "100%" }}
        />
      </Item>
      <ListFormActions
        onLocalEditClick={onLocalEditClick}
        isEdit={isEdit}
        isCreateMode={isCreateMode}
        onAddClick={onAddClick}
        onDeleteClick={onDeleteClick}
      />
    </div>
  )
}

export const AgencyPaymentForm = ({
  agent,
  addPaymentOtherObserver,
}: {
  agent?: TCalcAgent
  addPaymentOtherObserver?: () => void
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcPaymentOther: { getCalcPaymentOther, putCalcPaymentOther },
    },
  } = useSelector((state: RootState) => state)

  const paymentOtherAgent = useMemo(() => {
    return (
      getCalcPaymentOther?.data?.result.filter(
        (paymentOther) => paymentOther.agentId === agent?.id,
      ) || []
    )
  }, [getCalcPaymentOther?.data?.result, agent])

  useEffect(() => {
    paymentOtherAgent.forEach((paymentOther) => {
      paymentOther?.date &&
        form.setFieldValue(dateName + paymentOther.id, dayjs(paymentOther.date, "YYYY-MM-DD"))
      form.setFieldValue(amountName + paymentOther.id, paymentOther.amount)
    })
  }, [paymentOtherAgent])

  const onCreate = () => {
    if (!agent) return
    if (
      !getCalcLeadById?.data?.result.id ||
      !form.getFieldValue(amountName) ||
      !form.getFieldValue(dateName)
    )
      return
    dispatch(
      calcPaymentOtherActions.postCalcPaymentOtherAction.call({
        leadId: getCalcLeadById?.data?.result.id,
        agent: agent.id,
        amount: form.getFieldValue(amountName),
        date: form.getFieldValue(dateName),
        reason: "gag",
      }),
    )
    form.setFieldValue(amountName, undefined)
    form.setFieldValue(dateName, undefined)
    addPaymentOtherObserver && addPaymentOtherObserver()
  }

  const onChange = async (paymentOther: TCalcPaymentOther) => {
    if (!agent) return
    return form
      .validateFields([amountName + paymentOther.id, dateName + paymentOther.id])
      .then(() => {
        dispatch(
          calcPaymentOtherActions.putCalcPaymentOtherAction.call({
            ...paymentOther,
            paymentId: paymentOther.id,
            agent: agent.id,
            amount: form.getFieldValue(amountName + paymentOther.id),
            date: format(new Date(form.getFieldValue(dateName + paymentOther.id)), "yyyy-MM-dd"),
          }),
        )
      })
  }

  const isDisableForm = useMemo(() => {
    return !agent || getCalcPaymentOther.isLoading || putCalcPaymentOther.isLoading
  }, [getCalcPaymentOther, agent, putCalcPaymentOther])

  const deleteAgent = ({ paymentOther }: { paymentOther: TCalcPaymentOther }) => {
    dispatch(
      calcPaymentOtherActions.deleteCalcPaymentOtherAction.call({
        id: paymentOther.id,
      }),
    )
  }

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Агентский платеж
      </Title>
      <Form
        onFinish={onCreate}
        disabled={isDisableForm}
        name='agency_payment_form'
        form={form}
        layout={"vertical"}
      >
        {paymentOtherAgent.map((paymentOther, index) => (
          <AgencyPaymentItem
            key={index}
            paymentOther={paymentOther}
            onDeleteClick={() => deleteAgent({ paymentOther })}
            onUpdate={() => onChange(paymentOther)}
          />
        ))}
        <AgencyPaymentItem isCreateMode />
      </Form>
    </>
  )
}
