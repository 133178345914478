import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcLeadActions from "Base/store/lead/actions"
import {
  TDeleteCalcLeadData,
  TGetCalcLeadData,
  TPostCalcLeadData,
  TPatchCalcLeadData,
  TGetCalcLeadByIdData,
  TPostCalcLeadStatusData,
  TCopyCalcLeadData,
} from "Base/types/provider/api/lead"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcLeadFailureAction,
  TDeleteCalcLeadSuccessAction,
  TGetCalcLeadFailureAction,
  TGetCalcLeadSuccessAction,
  TGetCalcLeadByIdFailureAction,
  TPostCalcLeadFailureAction,
  TPostCalcLeadSuccessAction,
  TPatchCalcLeadFailureAction,
  TPatchCalcLeadSuccessAction,
  TPostCalcLeadStatusSuccessAction,
  TPostCalcLeadStatusFailureAction,
  TCopyCalcLeadFailureAction,
  TCopyCalcLeadSuccessAction,
} from "./types"

export type CalcLeadStateType = {
  getCalcLead: {
    data?: TGetCalcLeadData
  } & RequestInfoChildType
  getCalcLeadById: {
    data?: TGetCalcLeadByIdData
  } & RequestInfoChildType
  postCalcLead: {
    data?: TPostCalcLeadData
  } & RequestInfoChildType
  postCalcLeadStatus: {
    data?: TPostCalcLeadStatusData
  } & RequestInfoChildType
  patchCalcLead: {
    data?: TPatchCalcLeadData
  } & RequestInfoChildType
  deleteCalcLead: {
    data?: TDeleteCalcLeadData
  } & RequestInfoChildType
  copyCalcLead: {
    data?: TCopyCalcLeadData
  } & RequestInfoChildType
}

const initialState: CalcLeadStateType = {
  getCalcLead: {
    ...RequestInfoChildState,
  },
  getCalcLeadById: {
    ...RequestInfoChildState,
  },
  postCalcLead: {
    ...RequestInfoChildState,
  },
  postCalcLeadStatus: {
    ...RequestInfoChildState,
  },
  patchCalcLead: {
    ...RequestInfoChildState,
  },
  deleteCalcLead: {
    ...RequestInfoChildState,
  },
  copyCalcLead: {
    ...RequestInfoChildState,
  },
}

export const calcLeadReducer = createReducer(initialState, {
  [calcLeadActions.getCalcLeadAction.call.type]: (state) => ({
    ...state,
    getCalcLead: {
      ...state.getCalcLead,
      isLoading: true,
    },
  }),
  [calcLeadActions.getCalcLeadAction.success.type]: (state, action: TGetCalcLeadSuccessAction) => ({
    ...state,
    getCalcLead: {
      ...state.getCalcLead,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.getCalcLeadAction.failure.type]: (state, action: TGetCalcLeadFailureAction) => ({
    ...state,
    getCalcLead: {
      ...state.getCalcLead,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLeadActions.getCalcLeadByIdAction.call.type]: (state) => ({
    ...state,
    getCalcLeadById: {
      ...state.getCalcLeadById,
      isLoading: true,
    },
  }),
  [calcLeadActions.getCalcLeadByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcLeadById: {
      ...state.getCalcLeadById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.getCalcLeadByIdAction.failure.type]: (
    state,
    action: TGetCalcLeadByIdFailureAction,
  ) => ({
    ...state,
    getCalcLeadById: {
      ...state.getCalcLeadById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcLeadActions.getCalcLeadByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcLeadById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLeadActions.postCalcLeadAction.call.type]: (state) => ({
    ...state,
    postCalcLead: {
      ...state.postCalcLead,
      isLoading: true,
    },
  }),
  [calcLeadActions.postCalcLeadAction.success.type]: (
    state,
    action: TPostCalcLeadSuccessAction,
  ) => ({
    ...state,
    postCalcLead: {
      ...state.postCalcLead,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.postCalcLeadAction.failure.type]: (
    state,
    action: TPostCalcLeadFailureAction,
  ) => ({
    ...state,
    postCalcLead: {
      ...state.postCalcLead,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcLeadActions.postCalcLeadAction.initial.type]: (state) => ({
    ...state,
    postCalcLead: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLeadActions.postCalcLeadStatusAction.call.type]: (state) => ({
    ...state,
    postCalcLeadStatus: {
      ...state.postCalcLeadStatus,
      isLoading: true,
    },
  }),
  [calcLeadActions.postCalcLeadStatusAction.success.type]: (
    state,
    action: TPostCalcLeadStatusSuccessAction,
  ) => ({
    ...state,
    postCalcLeadStatus: {
      ...state.postCalcLeadStatus,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.postCalcLeadStatusAction.failure.type]: (
    state,
    action: TPostCalcLeadStatusFailureAction,
  ) => ({
    ...state,
    postCalcLeadStatus: {
      ...state.postCalcLeadStatus,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcLeadActions.postCalcLeadStatusAction.initial.type]: (state) => ({
    ...state,
    postCalcLeadStatus: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLeadActions.patchCalcLeadAction.call.type]: (state) => ({
    ...state,
    patchCalcLead: {
      ...state.patchCalcLead,
      isLoading: true,
    },
  }),
  [calcLeadActions.patchCalcLeadAction.success.type]: (
    state,
    action: TPatchCalcLeadSuccessAction,
  ) => ({
    ...state,
    patchCalcLead: {
      ...state.patchCalcLead,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.patchCalcLeadAction.failure.type]: (
    state,
    action: TPatchCalcLeadFailureAction,
  ) => ({
    ...state,
    patchCalcLead: {
      ...state.patchCalcLead,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLeadActions.deleteCalcLeadAction.call.type]: (state) => ({
    ...state,
    deleteCalcLead: {
      ...state.deleteCalcLead,
      isLoading: true,
    },
  }),
  [calcLeadActions.deleteCalcLeadAction.success.type]: (
    state,
    action: TDeleteCalcLeadSuccessAction,
  ) => ({
    ...state,
    deleteCalcLead: {
      ...state.deleteCalcLead,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.deleteCalcLeadAction.failure.type]: (
    state,
    action: TDeleteCalcLeadFailureAction,
  ) => ({
    ...state,
    deleteCalcLead: {
      ...state.deleteCalcLead,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  [calcLeadActions.copyCalcLeadAction.call.type]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [calcLeadActions.copyCalcLeadAction.success.type]: (
    state,
    action: TCopyCalcLeadSuccessAction,
  ) => ({
    ...state,
    copyCalcLead: {
      ...state.copyCalcLead,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLeadActions.copyCalcLeadAction.failure.type]: (
    state,
    action: TCopyCalcLeadFailureAction,
  ) => ({
    ...state,
    copyCalcLead: {
      ...state.copyCalcLead,
      error: action.payload.error,
      isLoading: false,
    },
  }),
})
