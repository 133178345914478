import { TErrorResponse } from "Base/types/provider/response"
import { notification } from "antd"
import axios from "axios"

const errorMessages = {
  type: {
    VALIDATION_FAILED: "Ошибка валидации",
  },
  message: {
    "numeric value out of bounds (<10 digits>.<2 digits> expected)":
      'числовое значение должно соответствовать формату: "10 цифр до и 2 цифры после запятой"',
  },
}

const notificationErrorTitle = (errorData: Record<string, unknown>) => {
  if ("type" in errorData && typeof errorData.type === "string") {
    const errorType = errorData.type

    if (errorType in errorMessages.type) {
      const errorTitle = errorMessages.type?.[errorType as keyof typeof errorMessages.type]
      return errorTitle
    }
  }
}

const requireCodes = [200, 201, 400, 401, 403, 404, 405, 409, 422, 429, 500, 502, 504]

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  validateStatus: (status) => requireCodes.includes(status),
  withCredentials: true,
})

export default class ApiProviderDefault {
  static request<T, R>(
    url: string,
    method: "POST" | "GET" | "PUT" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH",
    params: any,
    data?: T,
    headers?: Headers,
  ): Promise<R | TErrorResponse> {
    // @ts-ignore
    return axiosInstance({
      url,
      method,
      params,
      data,
      headers,
    })
      .then((response) => {
        switch (response.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 405:
          case 409:
          case 422:
          case 429:
          case 500:
          case 502:
            notification.error({
              message:
                notificationErrorTitle(response.data.error) || response?.data?.error?.message,
              description: response.data?.error?.data?.errors
                ? response.data.error.data.errors.map((error: any) => error.message).join(", ")
                : JSON.stringify(response.data.error?.data?.errors),
              placement: "topRight",
              type: "error",
            })
            return {
              status: response.status,
              detail: response.data.error,
              success: false,
            }
          case 504:
            return {
              status: response.status,
              detail: "TIME_OUT",
              success: false,
            }
          default:
            return {
              data: response.data,
              status: response.status,
              success: true,
            }
        }
      })
      .catch((error) => ({
        success: false,
        error: error.message,
      }))
  }
}
