import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import * as calcAssetActions from "Base/store/asset/actions"
import * as calcProviderActions from "Base/store/provider/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { TCalcProvider } from "Base/types/provider/api/provider"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { phoneNumber } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { Form, Input, InputNumber, Select } from "antd"
import "dayjs/locale/ru"
import { StringParam, useQueryParam } from "use-query-params"

const { Item } = Form

export const ProviderForm = ({
  asset,
  setIsRequiredFieldsCompleted,
  index,
}: {
  asset: TCalcAsset
  setIsRequiredFieldsCompleted: (...args: any[]) => void
  index: number
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  const { provider, vatRate } = values || {}

  useEffect(() => {
    let isUncomplited = false
    if (!provider) isUncomplited = true
    if (vatRate === "" || vatRate === null || vatRate === undefined) isUncomplited = true
    setIsRequiredFieldsCompleted(index, "provider", isUncomplited)
  }, [provider, vatRate])

  const [leadId, setLeadId] = useQueryParam("leadId", StringParam)
  if (!leadId) return null
  const {
    base: {
      calcAsset: { patchCalcAsset },
      calcProvider: { getCalcProvider, postCalcProvider, putCalcProvider },
    },
  } = useSelector((state: RootState) => state)
  const [searchNameValue, setSearchNameValue] = useState("")
  const [searchInnValue, setSearchInnValue] = useState("")

  useEffect(() => {
    asset?.provider?.id && form.setFieldValue("provider", asset?.provider?.id)
    asset?.provider?.vatRate > -1 && form.setFieldValue("vatRate", asset.provider.vatRate)
    asset?.provider?.contactPhone &&
      form.setFieldValue("contactPhone", asset?.provider?.contactPhone)
    asset?.provider?.contactName && form.setFieldValue("contactName", asset?.provider?.contactName)
    form.setFieldValue("inn", asset?.provider?.inn)
  }, [asset])

  const providerOptions = useMemo(() => {
    if (!getCalcProvider.data?.result) return []
    return getCalcProvider.data?.result.map((provider) => {
      return { value: provider.id, label: provider.name }
    })
  }, [getCalcProvider.data])

  const innOptions = useMemo(() => {
    if (!getCalcProvider.data?.result) return []
    return getCalcProvider.data?.result
      .filter((provider) => provider?.inn)
      .map((provider) => {
        return { value: provider.id, label: provider.inn }
      })
  }, [getCalcProvider.data])

  const onChangeNameOrInn = (value: number) => {
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        provider: value,
      }),
    )
  }

  const onSearchName = (value: string) => {
    setSearchNameValue(value)
  }

  const onBlurName = () => {
    if (!searchNameValue) return
    if (
      getCalcProvider.data?.result.filter((provider) => provider.name === searchNameValue)
        .length === 0
    ) {
      dispatch(
        calcProviderActions.postCalcProviderAction.call({
          assetId: asset.id,
          name: searchNameValue,
        }),
      )
    }
  }
  const onBlurInn = () => {
    let currentProvider: TCalcProvider | undefined
    let isFound = false
    getCalcProvider.data?.result.forEach((provider) => {
      if (asset.provider.id === provider.id) {
        currentProvider = provider
      }
      if (provider.inn === searchInnValue) {
        isFound = true
      }
    })
    if (searchInnValue && currentProvider && !isFound) {
      dispatch(
        calcProviderActions.putCalcProviderAction.call({
          ...currentProvider,
          assetId: asset.id,
          id: form.getFieldValue("provider"),
          inn: searchInnValue,
        }),
      )
      setSearchInnValue("")
    }
  }

  const onBlur = ({ name }: { name: keyof TCalcProvider }) => {
    if (form.getFieldValue(name) !== 0 && !form.getFieldValue(name)) return
    const providerFiltered =
      getCalcProvider.data?.result.filter(
        (provider) => provider.id == form.getFieldValue("provider"),
      ) || []
    if (providerFiltered.length === 0) return
    const provider = providerFiltered[0]
    if (provider?.[name] === form.getFieldValue(name)) return
    dispatch(
      calcProviderActions.putCalcProviderAction.call({
        ...provider,
        [name]: form.getFieldValue(name),
      }),
    )
  }

  const isDisableForm = useMemo(() => {
    return (
      getCalcProvider.isLoading ||
      patchCalcAsset.isLoading ||
      postCalcProvider.isLoading ||
      putCalcProvider.isLoading
    )
  }, [getCalcProvider, patchCalcAsset, postCalcProvider, putCalcProvider])

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Поставщик
      </Title>
      <Form disabled={isDisableForm} name='provider' form={form} layout={"vertical"}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "24px",
          }}
        >
          <div style={{ display: "grid", gridTemplateRows: "repeat(3, 1fr)" }}>
            <Item required name='provider' label='Название поставщика'>
              <Select
                className='select-custom'
                size='large'
                showSearch
                style={{ width: "100%" }}
                placeholder='Введите название'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={providerOptions}
                onSelect={onChangeNameOrInn}
                onSearch={onSearchName}
                onBlur={onBlurName}
              />
            </Item>
            <Item name='inn' label='ИНН'>
              <Select
                className='select-custom'
                size='large'
                showSearch
                style={{ width: "100%" }}
                placeholder='502101100500'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={innOptions}
                onChange={onChangeNameOrInn}
                onSearch={(value) => setSearchInnValue(value)}
                onBlur={onBlurInn}
              />
            </Item>
            <Item rules={[phoneNumber]} label='Телефон контактного лица'>
              <Input
                onBlur={() => onBlur({ name: "contactPhone" })}
                size='large'
                placeholder='+79999999999'
              />
            </Item>
          </div>
          <div style={{ display: "grid", gridTemplateRows: "repeat(3, 1fr)" }}>
            <Item name='contactName' label='Контактное лицо'>
              <Input
                onBlur={() => onBlur({ name: "contactName" })}
                size='large'
                placeholder={"Введите ФИО и должность"}
              />
            </Item>
            <Item required name='vatRate' label='Ставка НДС по сделке'>
              <InputNumber
                defaultValue={0}
                formatter={convertNumberToLocalString}
                parser={convertLocalStringToNumber}
                style={{ width: "100%" }}
                size='large'
                onBlur={() => onBlur({ name: "vatRate" })}
                min={0}
                max={99}
              />
            </Item>
          </div>
        </div>
      </Form>
    </>
  )
}
