import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"

import { Content } from "antd/es/layout/layout"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"
import Title from "antd/es/typography/Title"
import { TableProps } from "antd/lib"

import { clientModuleRoutes } from "Base/constants/routes"
import { TCalcShortLeasePayment } from "Base/types/provider/api/paymentSchedule"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { RootState } from "Starter/store/configureStore"
import { Button, Space, Table } from "antd"
import { StringParam, useQueryParam } from "use-query-params"

type TDataType = {
  key: number
} & TCalcShortLeasePayment

export const TableOfLeasePayments = () => {
  const {
    base: {
      calcPaymentSchedule: { getCalcPaymentSchedule },
    },
  } = useSelector((state: RootState) => state)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})
  const [leadId, setLeadId] = useQueryParam("leadId", StringParam)

  const columns: ColumnsType<TDataType> = [
    {
      title: "№",
      dataIndex: "key",
    },
    {
      title: "Дата платежа",
      dataIndex: "date",
      key: "paymentDate",
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortOrder: sortedInfo.columnKey === "date" ? sortedInfo.order : null,
    },
    {
      title: "Сумма лизинговых платежей с НДС",
      dataIndex: "leaseAmountWithDebt",
      sorter: (a, b) => a.leaseAmountWithDebt - b.leaseAmountWithDebt,
      sortOrder: sortedInfo.columnKey === "leaseAmountWithDebt" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Выкуп имущества по остаточной стоимости, в т.ч. НДС",
      dataIndex: "residualValueRepurchase",
      sorter: (a, b) => a.residualValueRepurchase - b.residualValueRepurchase,
      sortOrder: sortedInfo.columnKey === "residualValueRepurchase" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Итого, всех платежей, с удорожанием в год",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
      sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
  ]

  const data: Array<TDataType> = useMemo(() => {
    return (
      getCalcPaymentSchedule?.data?.result[0]?.shortLeasePayments.map(
        (shortLeasePayment, index) => ({
          key: ++index,
          ...shortLeasePayment,
        }),
      ) || []
    )
  }, [getCalcPaymentSchedule?.data?.result])

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }
  return (
    <Content
      style={{
        background: "white",
        padding: "20px",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "12px",
        height: "fit-content",
      }}
    >
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
          level={5}
        >
          График лизинговых платежей
        </Title>
        <NavLink to={clientModuleRoutes["lease-payment-schedule"].root + "?leadId=" + leadId}>
          <Button>Открыть график</Button>
        </NavLink>
      </Space>
      <Table columns={columns} dataSource={data} onChange={handleChange} />
    </Content>
  )
}
