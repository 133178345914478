import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Content } from "antd/es/layout/layout"
import Title from "antd/es/typography/Title"

import { MinusOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { clientModuleRoutes } from "Base/constants/routes"
import * as calcAgentActions from "Base/store/agent/actions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import { TCalcAgent } from "Base/types/provider/api/agent"
import { AgencyPaymentForm, AgencyPaymentsForm } from "ClientModule/containers/agency"
import { StageLayout } from "ClientModule/layouts/StageLayout"
import { RootState } from "Starter/store/configureStore"
import { Button, Collapse, Space } from "antd"

const { Panel } = Collapse

const AgentPanel = ({
  agent,
  newAgent,
  setNewAgent,
  addPaymentOtherObserver,
}: {
  agent?: TCalcAgent
  newAgent?: TCalcAgent
  setNewAgent?: (agent: TCalcAgent) => void
  addPaymentOtherObserver?: () => void
}) => (
  <>
    <Content>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Агентские платежи
      </Title>
      <AgencyPaymentsForm agent={agent} newAgent={newAgent} setNewAgent={setNewAgent} />
      <AgencyPaymentForm
        agent={agent || newAgent}
        addPaymentOtherObserver={addPaymentOtherObserver}
      />
    </Content>
  </>
)

export const Agency = () => {
  const dispatch = useDispatch()
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcAgent: { getCalcAgentFilteredByPayments },
    },
  } = useSelector((state: RootState) => state)
  const leadId = useMemo(() => getCalcLeadById?.data?.result.id, [getCalcLeadById?.data?.result.id])

  const [newAgentCreated, setNewAgentCreated] = useState(false)
  const [newAgent, setNewAgent] = useState<TCalcAgent | undefined>()

  useEffect(() => {
    if (!leadId) return
    dispatch(calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({ leadId }))
  }, [leadId])

  const deleteAgent = ({ agent }: { agent: TCalcAgent }) => {
    dispatch(
      calcPaymentOtherActions.deleteCalcPaymentOtherByPropertiesAction.call({
        agentId: agent.id,
      }),
    )
  }

  const PanelHeader = ({ index, onClick }: { index: number; onClick: () => void }) => (
    <Space
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Title style={{ margin: 0 }} level={4}>
        Агент {++index}
      </Title>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          onClick()
          // deleteAsset({ id: `${asset.id}` })
        }}
        style={{ marginLeft: "21px" }}
        icon={<MinusOutlined />}
      />
    </Space>
  )

  return (
    <StageLayout
      stageTitle='Шаг 4/4'
      backLink={clientModuleRoutes["leasing-stages"].deal.root + "?leadId=" + leadId}
      nextLink={clientModuleRoutes["lease-payment-schedule"].root + "?leadId=" + leadId}
      backButtonTitle='График'
    >
      <Collapse accordion>
        {getCalcAgentFilteredByPayments?.data?.map((agent, index) => (
          <Panel
            className='panel-icon'
            key={index}
            header={<PanelHeader index={index} onClick={() => deleteAgent({ agent })} />}
          >
            <AgentPanel agent={agent} />
          </Panel>
        ))}
        {newAgentCreated && (
          <Panel
            className='panel-icon'
            key={getCalcAgentFilteredByPayments?.data?.length || 0}
            header={
              <PanelHeader
                index={getCalcAgentFilteredByPayments?.data?.length || 0}
                onClick={() => {
                  setNewAgentCreated(false)
                  setNewAgent(undefined)
                }}
              />
            }
          >
            <AgentPanel
              newAgent={newAgent}
              setNewAgent={setNewAgent}
              addPaymentOtherObserver={() => {
                setNewAgentCreated(false)
                setNewAgent(undefined)
              }}
            />
          </Panel>
        )}
      </Collapse>
      <Button
        loading={getCalcAgentFilteredByPayments.isLoading}
        disabled={getCalcAgentFilteredByPayments.isLoading || newAgentCreated}
        type={"primary"}
        style={{ width: "100%", marginBottom: "30px", marginTop: "30px" }}
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setNewAgentCreated(true)
        }}
      >
        Добавить агента
      </Button>
    </StageLayout>
  )
}
