import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAmortizationGroupActions from "Base/store/amortizationGroup/actions"
import {
  TDeleteCalcAmortizationGroupData,
  TGetCalcAmortizationGroupByIdData,
  TGetCalcAmortizationGroupData, // TPatchCalcAmortizationGroupData,
  TPostCalcAmortizationGroupData,
} from "Base/types/provider/api/amortizationGroup"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcAmortizationGroupFailureAction,
  TDeleteCalcAmortizationGroupSuccessAction,
  TGetCalcAmortizationGroupByIdFailureAction,
  TGetCalcAmortizationGroupFailureAction,
  TGetCalcAmortizationGroupSuccessAction, // TPatchCalcAmortizationGroupFailureAction,
  // TPatchCalcAmortizationGroupSuccessAction,
  TPostCalcAmortizationGroupFailureAction,
  TPostCalcAmortizationGroupSuccessAction,
} from "./types"

export type CalcAmortizationGroupStateType = {
  getCalcAmortizationGroup: {
    data?: TGetCalcAmortizationGroupData
  } & RequestInfoChildType
  getCalcAmortizationGroupById: {
    data?: TGetCalcAmortizationGroupByIdData
  } & RequestInfoChildType
  postCalcAmortizationGroup: {
    data?: TPostCalcAmortizationGroupData
  } & RequestInfoChildType
  // patchCalcAmortizationGroup: {
  //   data?: TPatchCalcAmortizationGroupData
  // } & RequestInfoChildType
  deleteCalcAmortizationGroup: {
    data?: TDeleteCalcAmortizationGroupData
  } & RequestInfoChildType
}

const initialState: CalcAmortizationGroupStateType = {
  getCalcAmortizationGroup: {
    ...RequestInfoChildState,
  },
  getCalcAmortizationGroupById: {
    ...RequestInfoChildState,
  },
  postCalcAmortizationGroup: {
    ...RequestInfoChildState,
  },
  // patchCalcAmortizationGroup: {
  //   ...RequestInfoChildState,
  // },
  deleteCalcAmortizationGroup: {
    ...RequestInfoChildState,
  },
}

export const calcAmortizationGroupReducer = createReducer(initialState, {
  [calcAmortizationGroupActions.getCalcAmortizationGroupAction.call.type]: (state) => ({
    ...state,
    getCalcAmortizationGroup: {
      ...state.getCalcAmortizationGroup,
      isLoading: true,
    },
  }),
  [calcAmortizationGroupActions.getCalcAmortizationGroupAction.success.type]: (
    state,
    action: TGetCalcAmortizationGroupSuccessAction,
  ) => ({
    ...state,
    getCalcAmortizationGroup: {
      ...state.getCalcAmortizationGroup,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAmortizationGroupActions.getCalcAmortizationGroupAction.failure.type]: (
    state,
    action: TGetCalcAmortizationGroupFailureAction,
  ) => ({
    ...state,
    getCalcAmortizationGroup: {
      ...state.getCalcAmortizationGroup,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAmortizationGroupById: {
      ...state.getCalcAmortizationGroupById,
      isLoading: true,
    },
  }),
  [calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.success.type]: (
    state,
    action,
  ) => ({
    ...state,
    getCalcAmortizationGroupById: {
      ...state.getCalcAmortizationGroupById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.failure.type]: (
    state,
    action: TGetCalcAmortizationGroupByIdFailureAction,
  ) => ({
    ...state,
    getCalcAmortizationGroupById: {
      ...state.getCalcAmortizationGroupById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.initial.type]: (state) => {
    return {
      ...state,
      getCalcAmortizationGroupById: {
        data: undefined,
        isLoading: false,
        error: undefined,
      },
    }
  },
  //============================================================
  //============================================================
  [calcAmortizationGroupActions.postCalcAmortizationGroupAction.call.type]: (state) => ({
    ...state,
    postCalcAmortizationGroup: {
      ...state.postCalcAmortizationGroup,
      isLoading: true,
    },
  }),
  [calcAmortizationGroupActions.postCalcAmortizationGroupAction.success.type]: (
    state,
    action: TPostCalcAmortizationGroupSuccessAction,
  ) => ({
    ...state,
    postCalcAmortizationGroup: {
      ...state.postCalcAmortizationGroup,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAmortizationGroupActions.postCalcAmortizationGroupAction.failure.type]: (
    state,
    action: TPostCalcAmortizationGroupFailureAction,
  ) => ({
    ...state,
    postCalcAmortizationGroup: {
      ...state.postCalcAmortizationGroup,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  // [calcAmortizationGroupActions.patchCalcAmortizationGroupAction.call.type]: (state) => ({
  //   ...state,
  //   patchCalcAmortizationGroup: {
  //     ...state.patchCalcAmortizationGroup,
  //     isLoading: true,
  //   },
  // }),
  // [calcAmortizationGroupActions.patchCalcAmortizationGroupAction.success.type]: (
  //   state,
  //   action: TPatchCalcAmortizationGroupSuccessAction,
  // ) => ({
  //   ...state,
  //   patchCalcAmortizationGroup: {
  //     ...state.patchCalcAmortizationGroup,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [calcAmortizationGroupActions.patchCalcAmortizationGroupAction.failure.type]: (
  //   state,
  //   action: TPatchCalcAmortizationGroupFailureAction,
  // ) => ({
  //   ...state,
  //   patchCalcAmortizationGroup: {
  //     ...state.patchCalcAmortizationGroup,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  //============================================================
  //============================================================
  [calcAmortizationGroupActions.deleteCalcAmortizationGroupAction.call.type]: (state) => ({
    ...state,
    deleteCalcAmortizationGroup: {
      ...state.deleteCalcAmortizationGroup,
      isLoading: true,
    },
  }),
  [calcAmortizationGroupActions.deleteCalcAmortizationGroupAction.success.type]: (
    state,
    action: TDeleteCalcAmortizationGroupSuccessAction,
  ) => ({
    ...state,
    deleteCalcAmortizationGroup: {
      ...state.deleteCalcAmortizationGroup,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAmortizationGroupActions.deleteCalcAmortizationGroupAction.failure.type]: (
    state,
    action: TDeleteCalcAmortizationGroupFailureAction,
  ) => ({
    ...state,
    deleteCalcAmortizationGroup: {
      ...state.deleteCalcAmortizationGroup,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
