import React from "react"

import { ColumnsType } from "antd/es/table"

import {
  TGetCalcPaymentScheduleHistorySuccessPayload,
  THistoryChangesDataType,
} from "Base/store/paymentSchedule/types"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { Table, Typography } from "antd"

import { Widget } from "../Widget"

const { Title } = Typography

interface PaymentDetailsTableProps {
  data?: THistoryChangesDataType
  contractNumber: string
}

export const PaymentDetailsTable: React.FC<PaymentDetailsTableProps> = ({
  data,
  contractNumber,
}) => {
  const dataSourceWithKeys: TGetCalcPaymentScheduleHistorySuccessPayload["payments"] =
    data && data.payments
      ? data.payments.map((item, index) => ({
          ...item,
          key: data.payments && data.payments.length - index,
        }))
      : []

  const columns: ColumnsType<any> = [
    { title: "№", dataIndex: "key", key: "key" },
    { title: "Дата", dataIndex: "date", key: "date" },
    {
      title: "Основной долг, ₽",
      dataIndex: "principalDebt",
      key: "principalDebt",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Основной долг, %",
      dataIndex: "debtPercent",
      key: "debtPercent",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Проценты, ₽",
      dataIndex: "interest",
      key: "interest",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Налог, ₽",
      dataIndex: "taxes",
      key: "taxes",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Иные платы, ₽",
      dataIndex: "misc",
      key: "misc",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Доходность в месяц, ₽",
      dataIndex: "monthlyYield",
      key: "monthlyYield",
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Итого, ₽",
      dataIndex: "total",
      key: "total",
      render: (v) => convertNumberToLocalString(v),
    },
  ]

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Widget title='Сумма лизинга' price={data?.leasingAmount || 0} />
        <Widget title='Сумма выкупа' price={data?.buyoutAmount || 0} />
        <Widget title='Доходность' price={data?.profitability || 0} />
        <Widget title='Доходность, %' price={data?.profitabilityPercent || 0} />
        <Widget title='Удорожание в год, %' price={data?.annualIncreasePercent || 0} />
        <Widget title='Остаточная стоимость' price={data?.residualValue || 0} />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        {/* <Button
          onClick={onBack}
          style={{
            marginRight: "20px",
            padding: 0,
            background: "none",
            border: "none",
            color: "#1890ff",
            cursor: "pointer",
            textDecoration: "none",
            boxShadow: "none",
          }}
        >
          &larr; Вернуться к истории
        </Button> */}
        <Title level={3}>Версия графика {contractNumber}</Title>
      </div>

      <Table
        columns={columns}
        dataSource={dataSourceWithKeys}
        locale={{ emptyText: "Нет данных" }}
      />
    </div>
  )
}
