import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcAssetTypeParams,
  TDeleteCalcAssetTypeReq,
  TDeleteCalcAssetTypeResp,
  TGetCalcAssetTypeByIdParams,
  TGetCalcAssetTypeByIdReq,
  TGetCalcAssetTypeByIdResp,
  TGetCalcAssetTypeParams,
  TGetCalcAssetTypeReq,
  TGetCalcAssetTypeResp,
  TPostCalcAssetTypeParams,
  TPostCalcAssetTypeReq,
  TPostCalcAssetTypeResp,
  TPutCalcAssetTypeParams,
  TPutCalcAssetTypeReq,
  TPutCalcAssetTypeResp,
} from "Base/types/provider/api/assetType"

export default class AssetTypeProvider extends ApiProviderDefault {
  static async deleteCalcAssetTypeType(parameters: {
    params: TDeleteCalcAssetTypeParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/type/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcAssetTypeReq, TDeleteCalcAssetTypeResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetTypeById(parameters: {
    params: TGetCalcAssetTypeByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/type/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetTypeByIdReq, TGetCalcAssetTypeByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetType(parameters: { params: TGetCalcAssetTypeParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/asset/type`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetTypeReq, TGetCalcAssetTypeResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcAssetType(parameters: {
    params: TPostCalcAssetTypeParams
    headers: Headers
  }) {
    const { params: body, headers } = parameters
    const path = "/calc/asset/type"
    const query = {}
    return this.request<TPostCalcAssetTypeReq, TPostCalcAssetTypeResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putCalcAssetType(parameters: { params: TPutCalcAssetTypeParams; headers: Headers }) {
    const {
      params: { id, name, taxRate },
      headers,
    } = parameters
    const path = `/calc/asset/type/${id}`
    const body = {
      name,
      taxRate,
    }
    const query = undefined
    return this.request<TPutCalcAssetTypeReq, TPutCalcAssetTypeResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
