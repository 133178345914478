import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcAssetFailurePayload,
  TDeleteCalcAssetReqPayload,
  TDeleteCalcAssetSuccessPayload,
  TGetCalcAssetFailurePayload,
  TGetCalcAssetReqPayload,
  TGetCalcAssetSuccessPayload,
  TPostCalcAssetFailurePayload,
  TPostCalcAssetReqPayload,
  TPostCalcAssetSuccessPayload,
  TPatchCalcAssetFailurePayload,
  TPatchCalcAssetReqPayload,
  TPatchCalcAssetSuccessPayload,
  TGetCalcAssetByIdReqPayload,
  TGetCalcAssetByIdSuccessPayload,
  TGetCalcAssetByIdFailurePayload,
} from "./types"

const prefix = "calc-asset"

export const getCalcAssetAction = baseCSF<
  TGetCalcAssetReqPayload,
  TGetCalcAssetSuccessPayload,
  TGetCalcAssetFailurePayload,
  undefined
>(prefix, "get")
export const getCalcAssetByIdAction = baseCSF<
  TGetCalcAssetByIdReqPayload,
  TGetCalcAssetByIdSuccessPayload,
  TGetCalcAssetByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcAssetAction = baseCSF<
  TPostCalcAssetReqPayload,
  TPostCalcAssetSuccessPayload,
  TPostCalcAssetFailurePayload,
  undefined
>(prefix, "post")

export const patchCalcAssetAction = baseCSF<
  TPatchCalcAssetReqPayload,
  TPatchCalcAssetSuccessPayload,
  TPatchCalcAssetFailurePayload,
  undefined
>(prefix, "patch")

export const deleteCalcAssetAction = baseCSF<
  TDeleteCalcAssetReqPayload,
  TDeleteCalcAssetSuccessPayload,
  TDeleteCalcAssetFailurePayload,
  undefined
>(prefix, "delete")
