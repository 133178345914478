import { useState } from "react"

import { ListFormActions } from "Base/components/ListFormActions"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { nonZeroNumber, required } from "Base/utils/validation"
import { Form, DatePicker, InputNumber, Select } from "antd"

const { Item } = Form

const dateTranche = "dateTranche"
const amountTranche = "amountTranche"
const currencyTranche = "currencyTranche"
const rateTranche = "rateTranche"
const rateDateTranche = "rateDateTranche"

export const TrancheItem = ({
  index = "",
  assetCurrencyOptions,
  onDeleteClick,
  onAddClick,
  onUpdate,
  isCreateMode = false,
  validate,
}: {
  validate: (index: number | "") => void
  index?: number | ""
  assetCurrencyOptions: Array<{ label: string; value: number }>
  onDeleteClick?: (index: number) => void
  onAddClick?: () => void
  onUpdate?: () => Promise<void>
  isCreateMode?: boolean
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const isReadonly = !isEdit && !isCreateMode
  const [currency, setCurrency] = useState<number>()

  const isRuble = currency === 1

  const onLocalEditClick = () => {
    if (isEdit) {
      onUpdate &&
        onUpdate()
          .then(() => {
            setIsEdit(false)
          })
          .catch(() => setIsEdit(true))
    } else {
      setIsEdit(true)
    }
  }
  return (
    <div
      style={{
        display: "flex",
        gap: "24px",
        alignItems: "center",
        borderBottom: isCreateMode ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "24px",
        }}
      >
        <Item required={false} label='Дата платежа' name={dateTranche + index} rules={[required]}>
          <DatePicker
            disabled={isReadonly}
            placeholder='Выберите дату'
            size='large'
            style={{ width: "100%" }}
            format='DD-MM-YYYY'
          />
        </Item>
        <Item required={false} label='Сумма' style={{ margin: 0 }}>
          <Item name={amountTranche + index} rules={[required, nonZeroNumber]}>
            <InputNumber
              disabled={isReadonly}
              style={{ width: "100%" }}
              formatter={convertNumberToLocalString}
              parser={convertLocalStringToNumber}
              size='large'
            />
          </Item>
        </Item>
        <Item required={false} rules={[required]} name={currencyTranche + index} label='Валюта'>
          <Select
            onSelect={(v) => {
              setCurrency(v)
              validate(index)
            }}
            disabled={isReadonly}
            className='select-custom'
            size='large'
            showSearch
            style={{ width: "100%" }}
            placeholder='Выберите валюту'
            optionFilterProp='children'
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={assetCurrencyOptions}
          />
        </Item>
        <Item required={false} label='Курс ЦБ' style={{ marginBottom: 0 }}>
          <Item>
            <Item
              name={rateTranche + index}
              style={{ display: "inline-block", width: "calc(50% - 4px)", marginBottom: 0 }}
              required={!isRuble}
              rules={!isRuble ? [required, nonZeroNumber] : undefined}
            >
              <InputNumber
                disabled={isReadonly}
                formatter={convertNumberToLocalString}
                parser={convertLocalStringToNumber}
                size='large'
                style={{ width: "100%" }}
                placeholder='78,54'
                precision={4}
              />
            </Item>
            <Item
              required={!isRuble}
              rules={!isRuble ? [required] : undefined}
              name={rateDateTranche + index}
              style={{
                display: "inline-block",
                width: "calc(50% - 4px)",
                marginLeft: "8px",
                marginBottom: 0,
              }}
            >
              <DatePicker
                disabled={isReadonly}
                size='large'
                style={{ width: "100%" }}
                format='DD-MM-YYYY'
              />
            </Item>
          </Item>
          <div />
        </Item>
      </div>
      <div>
        <ListFormActions
          direction='vertical'
          onLocalEditClick={onLocalEditClick}
          isEdit={isEdit}
          isCreateMode={isCreateMode}
          onAddClick={onAddClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </div>
  )
}
