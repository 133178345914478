import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { ListFormActions } from "Base/components/ListFormActions"
import * as calcAssetActions from "Base/store/asset/actions"
import * as calcAssetTypeParamActions from "Base/store/assetTypeParam/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { required } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { Form, Input, Select } from "antd"
import "dayjs/locale/ru"
import hash from "object-hash"

const selectNameParam = "selectNameParam"
const inputNameParam = "inputNameParam"

const ItemParamOfForm = ({
  typeId,
  assetTypeParamOptions,
  index = "",
  onAddClick,
  onChangeParam,
  setSearchNameValue,
  searchNameValue,
  isCreateMode,
  onDeleteClick,
  onUpdate,
}: {
  onDeleteClick?: (index: number) => void
  onUpdate?: () => Promise<void>
  isCreateMode?: boolean
  onAddClick?: () => void
  onChangeParam?: ({
    index,
    name,
    value,
  }: {
    index: number
    name: string
    value: number | string
  }) => void
  assetTypeParamOptions: Array<{ value: string | number; label: string }>
  index?: number | ""
  typeId?: number
  setSearchNameValue: (value: string) => void
  searchNameValue: string
}) => {
  const dispatch = useDispatch()
  const [_searchNameValue, _setSearchNameValue] = useState(searchNameValue)
  const onBlurName = () => {
    if (!_searchNameValue) return
    if (
      typeId &&
      assetTypeParamOptions.filter(
        (assetTypeParamOption) => assetTypeParamOption.label === _searchNameValue,
      ).length === 0
    ) {
      dispatch(
        calcAssetTypeParamActions.postCalcAssetTypeParamAction.call({
          typeId,
          name: _searchNameValue,
          required: false,
          dataType: "STRING",
        }),
      )
      setSearchNameValue(_searchNameValue)
      _setSearchNameValue("")
    }
  }

  const [isEdit, setIsEdit] = useState(false)
  const isReadonly = !isEdit && !isCreateMode

  const onLocalEditClick = () => {
    if (isEdit) {
      onUpdate &&
        onUpdate()
          .then(() => {
            setIsEdit(false)
          })
          .catch(() => setIsEdit(true))
    } else {
      setIsEdit(true)
    }
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr auto auto",
        columnGap: "24px",
      }}
    >
      <Item required={false} label='Параметр' rules={[required]} name={selectNameParam + index}>
        <Select
          disabled={isReadonly}
          className='select-custom'
          searchValue={isCreateMode ? _searchNameValue : undefined}
          onBlur={() => {
            if (isCreateMode) {
              _setSearchNameValue(_searchNameValue)
              onBlurName()
            }
          }}
          onSearch={(v) => isCreateMode && _setSearchNameValue(v)}
          size='large'
          showSearch
          placeholder='Выберите параметр'
          optionFilterProp='children'
          filterOption={(input, option) => (option?.label ?? "").includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={assetTypeParamOptions}
        />
      </Item>

      <Item required={false} name={inputNameParam + index} rules={[required]} label='Значение'>
        <Input disabled={isReadonly} size='large' placeholder='Введите значение' />
      </Item>
      <div>
        <ListFormActions
          onAddClick={onAddClick}
          onLocalEditClick={onLocalEditClick}
          isEdit={isEdit}
          isCreateMode={isCreateMode}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </div>
  )
}

const { Item } = Form

export const ParamsForm = ({ asset }: { asset: TCalcAsset }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [typeSelected, setTypeSelected] = useState<number>()
  const [searchNameValue, setSearchNameValue] = useState("")
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcAsset: { patchCalcAsset },
      calcAssetTypeParam: { getCalcAssetTypeParam, postCalcAssetTypeParam },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (!typeSelected) return
    dispatch(calcAssetTypeParamActions.getCalcAssetTypeParamAction.call({ typeId: typeSelected }))
  }, [typeSelected])

  useEffect(() => {
    asset?.type?.id && setTypeSelected(asset.type.id)
    asset?.params.forEach((param, index) => {
      form.setFieldValue(inputNameParam + index, param.data.string)
      form.setFieldValue(selectNameParam + index, param.type.id)
    })
  }, [asset])

  const assetTypeParamOptions = useMemo(() => {
    if (!typeSelected || !getCalcAssetTypeParam.data?.[typeSelected]?.result) return []
    return getCalcAssetTypeParam.data?.[typeSelected]?.result.map((assetTypeParam) => {
      return { value: assetTypeParam.id, label: assetTypeParam.name }
    })
  }, [getCalcAssetTypeParam.data, typeSelected])

  useEffect(() => {
    if (!searchNameValue) return
    assetTypeParamOptions.forEach((assetTypeParamOption) => {
      if (assetTypeParamOption.label !== searchNameValue) return
      form.setFieldValue(selectNameParam, assetTypeParamOption.value)
      setSearchNameValue("")
    })
  }, [assetTypeParamOptions, searchNameValue])

  const onChangeParam = async (index: number | "") => {
    return form.validateFields([inputNameParam + index, selectNameParam + index]).then(() => {
      let isChanged = false
      const params = asset.params.map((param, _index) => {
        if (
          index !== _index ||
          (form.getFieldValue(selectNameParam + index) == param.type.id &&
            form.getFieldValue(inputNameParam + index) === param.data.string)
        ) {
          return {
            type: param.type.id,
            data: param.data,
          }
        }
        if (!isChanged) isChanged = true
        return {
          type: form.getFieldValue(selectNameParam + index),
          data: {
            ...param.data,
            string: form.getFieldValue(inputNameParam + index),
          },
        }
      })
      if (!isChanged) return
      dispatch(
        calcAssetActions.patchCalcAssetAction.call({
          id: asset.id,
          params,
        }),
      )
    })
  }

  const createParam = async () => {
    return form.validateFields().then(() => {
      dispatch(
        calcAssetActions.patchCalcAssetAction.call({
          id: asset.id,
          params: [
            ...asset.params.map((param) => ({
              type: param.type.id,
              data: param.data,
            })),
            {
              type: form.getFieldValue(selectNameParam),
              data: {
                string: form.getFieldValue(inputNameParam),
                bool: true,
                date: "2023-06-27",
                number: 5,
              },
            },
          ],
        }),
      )
      form.setFieldValue(inputNameParam, undefined)
      form.setFieldValue(selectNameParam, undefined)
    })
  }

  const deleteParam = (index: number) => {
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        params: asset.params
          .filter((param, _index) => index !== _index)
          .map((param) => ({
            type: param.type.id,
            data: param.data,
          })),
      }),
    )
  }

  const isDisableForm = useMemo(() => {
    return (
      !asset?.type?.id ||
      getCalcLeadById.isLoading ||
      patchCalcAsset.isLoading ||
      postCalcAssetTypeParam.isLoading
    )
  }, [asset, getCalcLeadById, patchCalcAsset, postCalcAssetTypeParam])

  return (
    <>
      <Form
        onFinish={createParam}
        disabled={isDisableForm}
        name='params_form'
        form={form}
        layout={"vertical"}
      >
        {asset.params.map((param, index) => (
          <ItemParamOfForm
            typeId={asset?.type?.id}
            onUpdate={() => onChangeParam(index)}
            index={index}
            key={hash(param)}
            assetTypeParamOptions={assetTypeParamOptions}
            onDeleteClick={() => deleteParam(index)}
            setSearchNameValue={setSearchNameValue}
            searchNameValue={searchNameValue}
          />
        ))}
        <ItemParamOfForm
          typeId={asset?.type?.id}
          assetTypeParamOptions={assetTypeParamOptions}
          setSearchNameValue={setSearchNameValue}
          searchNameValue={searchNameValue}
          isCreateMode
          onAddClick={createParam}
        />
      </Form>
    </>
  )
}
