import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TGetCalcAssetCurrencyByIdParams,
  TGetCalcAssetCurrencyByIdReq,
  TGetCalcAssetCurrencyByIdResp,
  TGetCalcAssetCurrencyParams,
  TGetCalcAssetCurrencyReq,
  TGetCalcAssetCurrencyResp,
} from "Base/types/provider/api/assetCurrency"

export default class AssetCurrencyProvider extends ApiProviderDefault {
  static async getCalcAssetCurrencyById(parameters: {
    params: TGetCalcAssetCurrencyByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/currency/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetCurrencyByIdReq, TGetCalcAssetCurrencyByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetCurrency(parameters: {
    params: TGetCalcAssetCurrencyParams
    headers: Headers
  }) {
    const { headers } = parameters
    const path = `/calc/asset/currency`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetCurrencyReq, TGetCalcAssetCurrencyResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }
}
