import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcAssetTypeFailurePayload,
  TDeleteCalcAssetTypeReqPayload,
  TDeleteCalcAssetTypeSuccessPayload,
  TGetCalcAssetTypeByIdFailurePayload,
  TGetCalcAssetTypeByIdReqPayload,
  TGetCalcAssetTypeByIdSuccessPayload,
  TGetCalcAssetTypeFailurePayload,
  TGetCalcAssetTypeReqPayload,
  TGetCalcAssetTypeSuccessPayload, // TPatchCalcAssetTypeFailurePayload,
  // TPatchCalcAssetTypeReqPayload,
  // TPatchCalcAssetTypeSuccessPayload,
  TPostCalcAssetTypeFailurePayload,
  TPostCalcAssetTypeReqPayload,
  TPostCalcAssetTypeSuccessPayload,
} from "./types"

const prefix = "calc-asset-type"

export const getCalcAssetTypeAction = baseCSF<
  TGetCalcAssetTypeReqPayload,
  TGetCalcAssetTypeSuccessPayload,
  TGetCalcAssetTypeFailurePayload,
  undefined
>(prefix, "get")
export const getCalcAssetTypeByIdAction = baseCSF<
  TGetCalcAssetTypeByIdReqPayload,
  TGetCalcAssetTypeByIdSuccessPayload,
  TGetCalcAssetTypeByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcAssetTypeAction = baseCSF<
  TPostCalcAssetTypeReqPayload,
  TPostCalcAssetTypeSuccessPayload,
  TPostCalcAssetTypeFailurePayload,
  undefined
>(prefix, "post")

// export const patchCalcAssetTypeAction = baseCSF<
//   TPatchCalcAssetTypeReqPayload,
//   TPatchCalcAssetTypeSuccessPayload,
//   TPatchCalcAssetTypeFailurePayload,
//   undefined
// >(prefix, "patch")

export const deleteCalcAssetTypeAction = baseCSF<
  TDeleteCalcAssetTypeReqPayload,
  TDeleteCalcAssetTypeSuccessPayload,
  TDeleteCalcAssetTypeFailurePayload,
  undefined
>(prefix, "delete")
