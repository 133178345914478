import React, { CSSProperties } from "react"
import { NavLink } from "react-router-dom"

import { Header } from "antd/es/layout/layout"
import Title from "antd/es/typography/Title"

import { icons } from "Base/assets/icons"
import { clientModuleRoutes } from "Base/constants/routes"

type TLeasingHeaderProps = {
  style?: CSSProperties
}

export const LeasingHeader = ({ style }: TLeasingHeaderProps) => {
  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        background: "white",
        height: "60px",
        boxShadow: "0px 4px 7px rgba(20, 31, 94, 0.1)",
        ...style,
      }}
    >
      <NavLink
        to={clientModuleRoutes["list-of-lease-payment-schedules"].root}
        style={{ margin: "10px 50px 0 0", cursor: "pointer" }}
      >
        <img src={icons.logoSvg} className='logo' alt='Логотип' style={{ cursor: "pointer" }} />
      </NavLink>
      <div style={{ display: "flex", gap: "40px", alignItems: "center" }}>
        <NavLink
          to={clientModuleRoutes["list-of-lease-payment-schedules"].root}
          style={{ color: "#000", cursor: "pointer" }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Сделки
          </Title>
        </NavLink>
        <Title level={5} style={{ margin: 0, color: "#7A7A7A" }}>
          Аналитика
        </Title>
        <Title level={5} style={{ margin: 0, color: "#7A7A7A" }}>
          Администрирование
        </Title>
      </div>
    </Header>
  )
}
