import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAgentActions from "Base/store/agent/actions"
import {
  TDeleteCalcAgentData,
  TGetCalcAgentData,
  TPostCalcAgentData,
  TPutCalcAgentData,
  TGetCalcAgentByIdData,
  TCalcAgent,
} from "Base/types/provider/api/agent"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcAgentFailureAction,
  TDeleteCalcAgentSuccessAction,
  TGetCalcAgentFailureAction,
  TGetCalcAgentSuccessAction,
  TGetCalcAgentByIdFailureAction,
  TPostCalcAgentFailureAction,
  TPostCalcAgentSuccessAction,
  TPutCalcAgentFailureAction,
  TPutCalcAgentSuccessAction,
  TGetCalcAgentFilteredByPaymentsSuccessAction,
} from "./types"

export type CalcAgentStateType = {
  getCalcAgent: {
    data?: TGetCalcAgentData
  } & RequestInfoChildType
  getCalcAgentFilteredByPayments: {
    data?: TCalcAgent[]
  } & RequestInfoChildType
  getCalcAgentById: {
    data?: TGetCalcAgentByIdData
  } & RequestInfoChildType
  postCalcAgent: {
    data?: TPostCalcAgentData
  } & RequestInfoChildType
  putCalcAgent: {
    data?: TPutCalcAgentData
  } & RequestInfoChildType
  deleteCalcAgent: {
    data?: TDeleteCalcAgentData
  } & RequestInfoChildType
}

const initialState: CalcAgentStateType = {
  getCalcAgent: {
    ...RequestInfoChildState,
  },
  getCalcAgentFilteredByPayments: {
    ...RequestInfoChildState,
  },
  getCalcAgentById: {
    ...RequestInfoChildState,
  },
  postCalcAgent: {
    ...RequestInfoChildState,
  },
  putCalcAgent: {
    ...RequestInfoChildState,
  },
  deleteCalcAgent: {
    ...RequestInfoChildState,
  },
}

export const calcAgentReducer = createReducer(initialState, {
  [calcAgentActions.getCalcAgentAction.call.type]: (state) => ({
    ...state,
    getCalcAgent: {
      ...state.getCalcAgent,
      isLoading: true,
    },
  }),
  [calcAgentActions.getCalcAgentAction.success.type]: (
    state,
    action: TGetCalcAgentSuccessAction,
  ) => ({
    ...state,
    getCalcAgent: {
      ...state.getCalcAgent,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAgentActions.getCalcAgentAction.failure.type]: (
    state,
    action: TGetCalcAgentFailureAction,
  ) => ({
    ...state,
    getCalcAgent: {
      ...state.getCalcAgent,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAgentActions.getCalcAgentFilteredByPaymentsAction.call.type]: (state) => ({
    ...state,
    getCalcAgentFilteredByPayments: {
      ...state.getCalcAgentFilteredByPayments,
      isLoading: true,
    },
  }),
  [calcAgentActions.getCalcAgentFilteredByPaymentsAction.success.type]: (
    state,
    action: TGetCalcAgentFilteredByPaymentsSuccessAction,
  ) => ({
    ...state,
    getCalcAgentFilteredByPayments: {
      ...state.getCalcAgentFilteredByPayments,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAgentActions.getCalcAgentFilteredByPaymentsAction.initial.type]: (state) => ({
    ...state,
    getCalcAgentFilteredByPayments: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAgentActions.getCalcAgentByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAgentById: {
      ...state.getCalcAgentById,
      isLoading: true,
    },
  }),
  [calcAgentActions.getCalcAgentByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcAgentById: {
      ...state.getCalcAgentById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAgentActions.getCalcAgentByIdAction.failure.type]: (
    state,
    action: TGetCalcAgentByIdFailureAction,
  ) => ({
    ...state,
    getCalcAgentById: {
      ...state.getCalcAgentById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAgentActions.getCalcAgentByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcAgentById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAgentActions.postCalcAgentAction.call.type]: (state) => ({
    ...state,
    postCalcAgent: {
      ...state.postCalcAgent,
      isLoading: true,
    },
  }),
  [calcAgentActions.postCalcAgentAction.success.type]: (
    state,
    action: TPostCalcAgentSuccessAction,
  ) => ({
    ...state,
    postCalcAgent: {
      ...state.postCalcAgent,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAgentActions.postCalcAgentAction.failure.type]: (
    state,
    action: TPostCalcAgentFailureAction,
  ) => ({
    ...state,
    postCalcAgent: {
      ...state.postCalcAgent,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAgentActions.putCalcAgentAction.call.type]: (state) => ({
    ...state,
    putCalcAgent: {
      ...state.putCalcAgent,
      isLoading: true,
    },
  }),
  [calcAgentActions.putCalcAgentAction.success.type]: (
    state,
    action: TPutCalcAgentSuccessAction,
  ) => ({
    ...state,
    putCalcAgent: {
      ...state.putCalcAgent,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAgentActions.putCalcAgentAction.failure.type]: (
    state,
    action: TPutCalcAgentFailureAction,
  ) => ({
    ...state,
    putCalcAgent: {
      ...state.putCalcAgent,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAgentActions.deleteCalcAgentAction.call.type]: (state) => ({
    ...state,
    deleteCalcAgent: {
      ...state.deleteCalcAgent,
      isLoading: true,
    },
  }),
  [calcAgentActions.deleteCalcAgentAction.success.type]: (
    state,
    action: TDeleteCalcAgentSuccessAction,
  ) => ({
    ...state,
    deleteCalcAgent: {
      ...state.deleteCalcAgent,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAgentActions.deleteCalcAgentAction.failure.type]: (
    state,
    action: TDeleteCalcAgentFailureAction,
  ) => ({
    ...state,
    deleteCalcAgent: {
      ...state.deleteCalcAgent,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
