import React from "react"

export const DisableBlock = () => {
  return (
    <div
      style={{
        position: "absolute",
        background: "grey",
        opacity: "0.7",
        // inlineSize: "100%",
        blockSize: "100%",
        // height: "100%",
        width: "100%",
        zIndex: 9,
      }}
      tabIndex={-1}
    />
  )
}
