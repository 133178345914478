import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcLeadFailurePayload,
  TDeleteCalcLeadReqPayload,
  TDeleteCalcLeadSuccessPayload,
  TGetCalcLeadFailurePayload,
  TGetCalcLeadReqPayload,
  TGetCalcLeadSuccessPayload,
  TPostCalcLeadFailurePayload,
  TPostCalcLeadReqPayload,
  TPostCalcLeadSuccessPayload,
  TPatchCalcLeadFailurePayload,
  TPatchCalcLeadReqPayload,
  TPatchCalcLeadSuccessPayload,
  TGetCalcLeadByIdReqPayload,
  TGetCalcLeadByIdSuccessPayload,
  TGetCalcLeadByIdFailurePayload,
  TPostCalcLeadStatusSuccessPayload,
  TPostCalcLeadStatusFailurePayload,
  TPostCalcLeadStatusReqPayload,
  TCopyCalcLeadReqPayload,
  TCopyCalcLeadSuccessPayload,
  TCopyCalcLeadFailurePayload,
} from "./types"

const prefix = "calc-lead"

export const getCalcLeadAction = baseCSF<
  TGetCalcLeadReqPayload,
  TGetCalcLeadSuccessPayload,
  TGetCalcLeadFailurePayload,
  undefined
>(prefix, "get")
export const getCalcLeadByIdAction = baseCSF<
  TGetCalcLeadByIdReqPayload,
  TGetCalcLeadByIdSuccessPayload,
  TGetCalcLeadByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcLeadAction = baseCSF<
  TPostCalcLeadReqPayload,
  TPostCalcLeadSuccessPayload,
  TPostCalcLeadFailurePayload,
  undefined
>(prefix, "post")

export const postCalcLeadStatusAction = baseCSF<
  TPostCalcLeadStatusReqPayload,
  TPostCalcLeadStatusSuccessPayload,
  TPostCalcLeadStatusFailurePayload,
  undefined
>(prefix, "post-status")

export const patchCalcLeadAction = baseCSF<
  TPatchCalcLeadReqPayload,
  TPatchCalcLeadSuccessPayload,
  TPatchCalcLeadFailurePayload,
  undefined
>(prefix, "patch")

export const deleteCalcLeadAction = baseCSF<
  TDeleteCalcLeadReqPayload,
  TDeleteCalcLeadSuccessPayload,
  TDeleteCalcLeadFailurePayload,
  undefined
>(prefix, "delete")

export const copyCalcLeadAction = baseCSF<
  TCopyCalcLeadReqPayload,
  TCopyCalcLeadSuccessPayload,
  TCopyCalcLeadFailurePayload,
  undefined
>(prefix, "copy")
