import { apiRequest } from "Base/provider/sagas"
import * as calcCreditorActions from "Base/store/creditor/actions"
import {
  TDeleteCalcCreditorResp,
  TGetCalcCreditorResp,
  TPostCalcCreditorResp,
  TGetCalcCreditorByIdResp,
  TCalcCreditor,
  TPutCalcCreditorResp,
} from "Base/types/provider/api/creditor"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select } from "redux-saga/effects"

import {
  TDeleteCalcCreditorReqAction,
  TPostCalcCreditorReqAction,
  TGetCalcCreditorByIdReqAction,
  TPutCalcCreditorReqAction,
} from "./types"

export function* getCalcCreditorSaga() {
  try {
    const resp: TGetCalcCreditorResp = yield call(apiRequest, "getCalcCreditor")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcCreditorActions.getCalcCreditorAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcCreditorActions.getCalcCreditorAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcCreditorByIdSaga({ payload }: TGetCalcCreditorByIdReqAction) {
  try {
    const resp: TGetCalcCreditorByIdResp = yield call(apiRequest, "getCalcCreditorById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcCreditorActions.getCalcCreditorByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcCreditorActions.getCalcCreditorByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcCreditorSaga({ payload }: TPostCalcCreditorReqAction) {
  try {
    const resp: TPostCalcCreditorResp = yield call(apiRequest, "postCalcCreditor", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcCreditorActions.postCalcCreditorAction.success(resp.data))
    const { getCalcCreditor } = yield select(({ base }: RootState) => base.calcProvider)

    yield put(
      calcCreditorActions.getCalcCreditorAction.success({
        ...getCalcCreditor?.data,
        result: [...getCalcCreditor.data.result, resp.data.result],
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcCreditorActions.postCalcCreditorAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putCalcCreditorSaga({ payload }: TPutCalcCreditorReqAction) {
  try {
    const resp: TPutCalcCreditorResp = yield call(apiRequest, "putCalcCreditor", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcCreditorActions.putCalcCreditorAction.success(resp.data))
    const { getCalcCreditor } = yield select(({ base }: RootState) => base.calcCreditor)
    yield put(
      calcCreditorActions.getCalcCreditorAction.success({
        ...getCalcCreditor.data,
        result: getCalcCreditor.data?.result.map((calcCreditor: TCalcCreditor) => {
          if (calcCreditor.id !== resp.data.result.id) return calcCreditor
          return resp.data.result
        }),
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcCreditorActions.putCalcCreditorAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcCreditorSaga({ payload }: TDeleteCalcCreditorReqAction) {
  try {
    const resp: TDeleteCalcCreditorResp = yield call(apiRequest, "deleteCalcCreditor", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcCreditorActions.deleteCalcCreditorAction.success(resp.data))
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)

    yield put(
      calcCreditorActions.getCalcCreditorAction.call({ leadId: getCalcLeadById.data.result.id }),
    )
  } catch (e: unknown) {
    yield put(
      calcCreditorActions.deleteCalcCreditorAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcCreditorWatcher() {
  yield takeLatest(calcCreditorActions.getCalcCreditorAction.call.type, getCalcCreditorSaga)
  yield takeLatest(calcCreditorActions.getCalcCreditorByIdAction.call.type, getCalcCreditorByIdSaga)
  yield takeLatest(calcCreditorActions.postCalcCreditorAction.call.type, postCalcCreditorSaga)
  yield takeLatest(calcCreditorActions.putCalcCreditorAction.call.type, putCalcCreditorSaga)
  yield takeLatest(calcCreditorActions.deleteCalcCreditorAction.call.type, deleteCalcCreditorSaga)
}
