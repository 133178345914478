import { useEffect } from "react"

export const useNavFormDisable = (disabled: boolean) => {
  useEffect(() => {
    const navElements = document.querySelector(".nav-links")
    if (disabled) {
      navElements?.classList.add("pointer-events-none")
    } else {
      navElements?.classList.remove("pointer-events-none")
    }
  }, [disabled])
}
