import { apiRequest } from "Base/provider/sagas"
import * as calcAgentActions from "Base/store/agent/actions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import {
  TDeleteCalcAgentResp,
  TGetCalcAgentResp,
  TPostCalcAgentResp,
  TGetCalcAgentByIdResp,
  TPutCalcAgentResp,
  TCalcAgent,
} from "Base/types/provider/api/agent"
import { TCalcPaymentOther } from "Base/types/provider/api/paymentOther"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select, take, delay } from "redux-saga/effects"

import { selectLeadId } from "../lead/selectors"
import { getCalcPaymentScheduleAction } from "../paymentSchedule/actions"
import {
  TDeleteCalcAgentReqAction,
  TPostCalcAgentReqAction,
  TGetCalcAgentByIdReqAction,
  TGetCalcAgentReqAction,
  TPutCalcAgentReqAction,
  TGetCalcAgentFilteredByPaymentsReqAction,
} from "./types"

export function* getCalcAgentSaga({ payload }: TGetCalcAgentReqAction) {
  try {
    const resp: TGetCalcAgentResp = yield call(apiRequest, "getCalcAgent", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAgentActions.getCalcAgentAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAgentActions.getCalcAgentAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcAgentFilteredByPaymentsSaga({
  payload,
}: TGetCalcAgentFilteredByPaymentsReqAction) {
  try {
    yield put(calcAgentActions.getCalcAgentAction.call({}))
    yield take(calcAgentActions.getCalcAgentAction.success)
    yield put(calcPaymentOtherActions.getCalcPaymentOtherAction.call(payload))
    yield take(calcPaymentOtherActions.getCalcPaymentOtherAction.success)

    const {
      calcAgent: { getCalcAgent },
      calcPaymentOther: { getCalcPaymentOther },
    } = yield select(({ base }: RootState) => base)
    const data = getCalcAgent?.data?.result.filter((agent: TCalcAgent) => {
      return (
        getCalcPaymentOther.data.result.filter(
          (paymentOther: TCalcPaymentOther) => agent.id === paymentOther.agentId,
        ).length > 0
      )
    })
    yield put(calcAgentActions.getCalcAgentFilteredByPaymentsAction.success(data))
  } catch (e: unknown) {
    console.log(e)
  }
}

export function* getCalcAgentByIdSaga({ payload }: TGetCalcAgentByIdReqAction) {
  try {
    const resp: TGetCalcAgentByIdResp = yield call(apiRequest, "getCalcAgentById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAgentActions.getCalcAgentByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAgentActions.getCalcAgentByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcAgentSaga({ payload }: TPostCalcAgentReqAction) {
  try {
    const resp: TPostCalcAgentResp = yield call(apiRequest, "postCalcAgent", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAgentActions.postCalcAgentAction.success(resp.data))
    const { getCalcAgent } = yield select(({ base }: RootState) => base.calcAsset)

    yield put(
      calcAgentActions.getCalcAgentAction.success({
        ...getCalcAgent.data,
        result: [...getCalcAgent.data.result, resp.data.result],
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcAgentActions.postCalcAgentAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putCalcAgentSaga({ payload }: TPutCalcAgentReqAction) {
  try {
    const resp: TPutCalcAgentResp = yield call(apiRequest, "putCalcAgent", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAgentActions.putCalcAgentAction.success(resp.data))
    const { getCalcAgent } = yield select(({ base }: RootState) => base.calcAsset)
    yield put(
      calcAgentActions.getCalcAgentAction.success({
        ...getCalcAgent.data,
        result: getCalcAgent.data?.result.map((asset: TCalcAgent) => {
          if (asset.id !== resp.data.result.id) return asset
          return resp.data.result
        }),
      }),
    )

    const leadId: number = yield select(selectLeadId)
    if (leadId) {
      yield delay(500)
      yield put(
        getCalcPaymentScheduleAction.call({
          leadId,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcAgentActions.putCalcAgentAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcAgentSaga({ payload }: TDeleteCalcAgentReqAction) {
  try {
    const resp: TDeleteCalcAgentResp = yield call(apiRequest, "deleteCalcAgent", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAgentActions.deleteCalcAgentAction.success(resp.data))
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)
    yield put(
      calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({
        leadId: getCalcLeadById?.data?.result?.id,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcAgentActions.deleteCalcAgentAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcAgentWatcher() {
  yield takeLatest(calcAgentActions.getCalcAgentAction.call.type, getCalcAgentSaga)
  yield takeLatest(
    calcAgentActions.getCalcAgentFilteredByPaymentsAction.call.type,
    getCalcAgentFilteredByPaymentsSaga,
  )
  yield takeLatest(calcAgentActions.getCalcAgentByIdAction.call.type, getCalcAgentByIdSaga)
  yield takeLatest(calcAgentActions.postCalcAgentAction.call.type, postCalcAgentSaga)
  yield takeLatest(calcAgentActions.putCalcAgentAction.call.type, putCalcAgentSaga)
  yield takeLatest(calcAgentActions.deleteCalcAgentAction.call.type, deleteCalcAgentSaga)
}
