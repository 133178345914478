import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as calcAssetActions from "Base/store/asset/actions"
import * as calcAssetTypeParamActions from "Base/store/assetTypeParam/actions"
import * as calcLeadActions from "Base/store/lead/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { RootState } from "Starter/store/configureStore"
import { DatePicker, Form, Input, InputNumber, Select } from "antd"
import { format } from "date-fns"
import dayjs from "dayjs"
import "dayjs/locale/ru"

const selectNameParam = "selectNameParam"
const inputNameParam = "inputNameParam"

const { Item } = Form

const ruble = 1

export const SubjectOfLeasingForm = ({
  asset,
  setIsRequiredFieldsCompleted,
  index,
}: {
  asset: TCalcAsset
  setIsRequiredFieldsCompleted: (...args: any[]) => void
  index: number
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  const { adr, amortizationGroup, name, type, value } = values || {}

  const isRateFieldDisabled = values?.currency === ruble

  useEffect(() => {
    const isUncomplited = Object.values({ adr, amortizationGroup, name, type, value }).some(
      (value) => !value || value === 0,
    )
    setIsRequiredFieldsCompleted(index, "subjectOfLeasing", isUncomplited)
  }, [adr, amortizationGroup, name, type, value])

  const [typeSelected, setTypeSelected] = useState<number>()
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcAsset: { patchCalcAsset, getCalcAsset },
      calcAssetType: { getCalcAssetType },
      calcAmortizationGroup: { getCalcAmortizationGroup },
      calcAssetCurrency: { getCalcAssetCurrency },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (!typeSelected) return
    dispatch(calcAssetTypeParamActions.getCalcAssetTypeParamAction.call({ typeId: typeSelected }))
  }, [typeSelected])

  useEffect(() => {
    asset?.type?.id && setTypeSelected(asset.type.id)
    form.setFieldValue("name", asset.name)
    form.setFieldValue("type", asset?.type?.id)
    form.setFieldValue("currency", asset?.currency?.id)
    form.setFieldValue("commission", asset?.commission)
    form.setFieldValue("rate", asset?.rate?.rate)
    asset?.rate?.date && form.setFieldValue("date", dayjs(asset?.rate?.date, "YYYY-MM-DD"))
    form.setFieldValue("amortizationGroup", asset?.amortizationGroup?.id)
    form.setFieldValue("adr", asset?.adr)
    form.setFieldValue("value", asset?.value)

    form.setFieldValue("okof", asset?.okof)
    asset?.params.forEach((param, index) => {
      form.setFieldValue(inputNameParam + index, param.data.string)
      form.setFieldValue(selectNameParam + index, param.type.id)
    })
  }, [asset])

  // const amountOfTranches = useMemo(() => {
  //   return asset?.tranche.reduce((amount, tranche) => amount + tranche.amount, 0) || 0
  // }, [asset?.tranche])

  const assetTypeOptions = useMemo(() => {
    if (!getCalcAssetType.data?.result) return []
    return getCalcAssetType.data?.result.map((lessee) => {
      return { value: lessee.id, label: lessee.name }
    })
  }, [getCalcAssetType.data])

  const assetCurrencyOptions = useMemo(() => {
    if (!getCalcAssetCurrency.data?.result) return []
    return getCalcAssetCurrency.data?.result.map((currency) => {
      return { value: currency.id, label: currency.name + " (" + currency.isoCode + ")" }
    })
  }, [getCalcAssetCurrency.data])

  const amortizationGroupOptions = useMemo(() => {
    if (!getCalcAmortizationGroup.data?.result) return []
    return getCalcAmortizationGroup.data?.result.map((lessee) => {
      return { value: lessee.id, label: lessee.name }
    })
  }, [getCalcAmortizationGroup.data])

  const calculateAmount = (currValue: number) => {
    let amount = 0
    const valueAssets =
      (getCalcAsset?.data?.result
        .filter((_, idx) => idx !== index)
        .reduce((value, asset) => value + asset.value, 0) || 0) + currValue
    const initialPayment = getCalcLeadById?.data?.result.leaseData?.initialPayment
    if (valueAssets && initialPayment) {
      amount = valueAssets - initialPayment
    }

    return amount
  }

  const updateLoanData = (currValue: number) => {
    const amount = calculateAmount(currValue)

    if (getCalcLeadById.data && getCalcLeadById?.data?.result?.loanData) {
      dispatch(
        calcLeadActions.patchCalcLeadAction.call({
          id: getCalcLeadById.data.result.id,
          name: getCalcLeadById.data.result.name,
          loanData: {
            creditor: getCalcLeadById?.data?.result?.loanData?.creditor?.id,
            term: getCalcLeadById?.data?.result?.loanData?.term,
            interestRate: getCalcLeadById?.data?.result?.loanData?.interestRate,
            amount,
          },
        }),
      )
    }
  }

  const onBlur = ({ name, value }: { name: keyof TCalcAsset; value?: number | string }) => {
    if (
      !form.getFieldValue(name) ||
      (!value && asset[name] === form.getFieldValue(name)) ||
      asset[name] === value
    ) {
      return
    }

    if (name === "value") {
      const currValue = Number(value)

      dispatch(
        calcAssetActions.patchCalcAssetAction.call({
          id: asset.id,
          [name]: currValue,
        }),
      )

      updateLoanData(currValue)
    } else {
      dispatch(
        calcAssetActions.patchCalcAssetAction.call({
          id: asset.id,
          [name]: value || form.getFieldValue(name),
        }),
      )
    }
  }

  const onBlurRate = ({ name, value }: { name: "rate" | "date"; value: string | number }) => {
    if (name === "date") value = format(new Date(form.getFieldValue(name)), "yyyy-MM-dd")
    if (asset?.rate && asset.rate[name] === value) return
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        rate: {
          ...asset.rate,
          [name]: value,
        },
      }),
    )
  }

  const onChangeOfSelect = ({ name, value }: { value: number; name: string }) => {
    const { rate, date } = values

    Promise.resolve()
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        [name]: value,
        rate: {
          rate: name === "currency" && value === ruble ? 1 : rate,
          date: date,
        },
      }),
    )
  }

  const isDisableForm = useMemo(() => {
    return getCalcLeadById.isLoading || patchCalcAsset.isLoading
  }, [getCalcLeadById, patchCalcAsset])

  const computedValue = useMemo(() => {
    const value = asset?.value
    const rate = asset?.rate?.rate

    if (!value || !rate) return 0

    const convertedValue = convertLocalStringToNumber(convertNumberToLocalString(value))
    const convertedRate = convertLocalStringToNumber(convertNumberToLocalString(rate))

    return convertedValue / convertedRate
  }, [asset?.value, asset?.rate?.rate])

  return (
    <>
      <Form disabled={isDisableForm} name='subject_of_leasing_form' form={form} layout={"vertical"}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "24px",
          }}
        >
          <div style={{ display: "grid", gridTemplateRows: "repeat(4, 1fr)" }}>
            <Item name='name' required label='Наименование предмета лизинга'>
              <Input
                size='large'
                onBlur={() => onBlur({ name: "name" })}
                placeholder='Введите наименование '
              />
            </Item>
            <Item required name='amortizationGroup' label='Группа амортизации'>
              <Select
                className='select-custom'
                onSelect={(value) => onChangeOfSelect({ name: "amortizationGroup", value })}
                size='large'
                showSearch
                style={{ width: "100%" }}
                placeholder='Выберите группу'
                optionFilterProp='children'
                filterOption={(input, option) => option?.label?.includes(input) || false}
                filterSort={(optionA, optionB) =>
                  (optionA?.label?.toString() ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label?.toString() ?? "").toLowerCase())
                }
                options={amortizationGroupOptions}
              />
            </Item>
            <Item required label='Стоимость предмета лизинга'>
              <Item name='value' style={{ display: "inline-block", width: "calc(50% - 4px)" }}>
                <InputNumber
                  formatter={convertNumberToLocalString}
                  parser={convertLocalStringToNumber}
                  style={{ width: "100%" }}
                  size='large'
                  onBlur={(event) => {
                    const value = convertLocalStringToNumber(event.target.value)
                    onBlur({ name: "value", value })
                  }}
                />
              </Item>
              <Item
                style={{ display: "inline-block", width: "calc(50% - 4px)", marginLeft: "8px" }}
              >
                <InputNumber
                  addonAfter='У.Е.'
                  formatter={convertNumberToLocalString}
                  parser={convertLocalStringToNumber}
                  style={{ width: "100%" }}
                  size='large'
                  disabled
                  value={computedValue}
                />
              </Item>
            </Item>
            <Item name='commission' label='Комиссия по курсу'>
              <Input onBlur={() => onBlur({ name: "commission" })} size='large' max={99} />
            </Item>
          </div>
          <div style={{ display: "grid", gridTemplateRows: "repeat(4, 1fr)" }}>
            <Item name='type' required label='Тип предмета'>
              <Select
                className='select-custom'
                loading={getCalcAssetType.isLoading}
                size='large'
                onSelect={(value) => onChangeOfSelect({ name: "type", value })}
                // onBlur={() => onBlur({ name: "directorBirthdate" })}
                showSearch
                style={{ width: "100%" }}
                placeholder='Выберите тип'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={assetTypeOptions}
              />
            </Item>
            <Item
              label='Коэффициент ускоренной амортизации'
              required
              style={{
                marginBottom: 0,
              }}
            >
              <Item name='adr' style={{ display: "inline-block", width: "calc(50% - 4px)" }}>
                <InputNumber
                  style={{ width: "100%" }}
                  size='large'
                  onBlur={() => onBlur({ name: "adr" })}
                  max={99}
                />
              </Item>
              <Item
                name='okof'
                style={{ display: "inline-block", width: "calc(50% - 4px)", marginLeft: "8px" }}
              >
                <Input
                  onBlur={() => onBlur({ name: "okof" })}
                  size='large'
                  style={{ width: "100%" }}
                  placeholder='ОКОФ'
                />
              </Item>
            </Item>
            <Item name='currency' label='Валюта'>
              <Select
                className='select-custom'
                size='large'
                onSelect={(value) => onChangeOfSelect({ name: "currency", value })}
                showSearch
                style={{ width: "100%" }}
                placeholder='Выберите валюту'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={assetCurrencyOptions}
              />
            </Item>
            <Item
              label='Курс ЦБ'
              style={{
                marginBottom: 0,
              }}
            >
              <Item name='rate' style={{ display: "inline-block", width: "calc(50% - 4px)" }}>
                <InputNumber
                  disabled={isRateFieldDisabled}
                  formatter={convertNumberToLocalString}
                  parser={convertLocalStringToNumber}
                  onBlur={(event) =>
                    onBlurRate({
                      name: "rate",
                      value: convertLocalStringToNumber(event.target.value),
                    })
                  }
                  size='large'
                  style={{ width: "100%" }}
                  placeholder='78,54'
                  precision={4}
                />
              </Item>
              <Item
                name='date'
                style={{ display: "inline-block", width: "calc(50% - 4px)", marginLeft: "8px" }}
              >
                <DatePicker
                  onBlur={(event: BaseSyntheticEvent) =>
                    onBlurRate({ name: "date", value: event.target.value })
                  }
                  size='large'
                  style={{ width: "100%" }}
                  format='DD-MM-YYYY'
                />
              </Item>
            </Item>
          </div>
        </div>
      </Form>
    </>
  )
}
