import { apiRequest } from "Base/provider/sagas"
import * as calcAssetTypeParamActions from "Base/store/assetTypeParam/actions"
import {
  TDeleteCalcAssetTypeParamResp,
  TGetCalcAssetTypeParamByIdResp,
  TGetCalcAssetTypeParamResp, // TPatchCalcAssetTypeParamResp,
  TPostCalcAssetTypeParamResp,
} from "Base/types/provider/api/assetTypeParam"
// import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select, delay } from "redux-saga/effects"

import { RootState } from "../../../Starter/store/configureStore"
// import * as calcLeadActions from "../assetType/actions"
import { selectLeadId } from "../lead/selectors"
import { getCalcPaymentScheduleAction } from "../paymentSchedule/actions"
import {
  TDeleteCalcAssetTypeParamReqAction,
  TGetCalcAssetTypeParamByIdReqAction,
  TGetCalcAssetTypeParamReqAction, // TPatchCalcAssetTypeParamReqAction,
  TPostCalcAssetTypeParamReqAction,
} from "./types"

export function* getCalcAssetTypeParamSaga({
  payload: { typeId },
}: TGetCalcAssetTypeParamReqAction) {
  try {
    const resp: TGetCalcAssetTypeParamResp = yield call(apiRequest, "getCalcAssetTypeParam", {
      params: {
        typeId,
      },
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    const { getCalcAssetTypeParam } = yield select(({ base }: RootState) => base.calcAssetTypeParam)

    yield put(
      calcAssetTypeParamActions.getCalcAssetTypeParamAction.success({
        ...getCalcAssetTypeParam.data,
        [typeId]: resp.data,
      }),
    )
    // yield put(
    //   calcAssetTypeParamActions.postCalcAssetTypeParamAction.call({
    //     typeId,
    //     name: "test16",
    //     required: true,
    //     dataType: "NUMBER",
    //   }),
    // )
  } catch (e: unknown) {
    yield put(
      calcAssetTypeParamActions.getCalcAssetTypeParamAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcAssetTypeParamByIdSaga({ payload }: TGetCalcAssetTypeParamByIdReqAction) {
  try {
    const resp: TGetCalcAssetTypeParamByIdResp = yield call(
      apiRequest,
      "getCalcAssetTypeParamById",
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcAssetTypeParamSaga({ payload }: TPostCalcAssetTypeParamReqAction) {
  try {
    const {
      calcAssetTypeParam: { getCalcAssetTypeParam },
    } = yield select(({ base }: RootState) => base)

    const resp: TPostCalcAssetTypeParamResp = yield call(apiRequest, "postCalcAssetTypeParam", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeParamActions.postCalcAssetTypeParamAction.success(resp.data))
    yield put(
      calcAssetTypeParamActions.getCalcAssetTypeParamAction.success({
        ...getCalcAssetTypeParam,
        [payload.typeId]: {
          ...getCalcAssetTypeParam?.data[payload.typeId],
          result: [
            ...(getCalcAssetTypeParam?.data[payload.typeId]?.result || []),
            resp.data.result,
          ],
        },
      }),
    )

    const leadId: number = yield select(selectLeadId)
    if (leadId) {
      yield delay(500)
      yield put(
        getCalcPaymentScheduleAction.call({
          leadId,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcAssetTypeParamActions.postCalcAssetTypeParamAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

// export function* patchCalcAssetTypeParamSaga({ payload }: TPatchCalcAssetTypeParamReqAction) {
//   try {
//     const resp: TPatchCalcAssetTypeParamResp = yield call(apiRequest, "patchCalcAssetTypeParam", {
//       params: payload,
//     })
//     if (!resp.success) throw new Error(resp.detail || "Unknown error")
//     yield put(calcAssetTypeParamActions.patchCalcAssetTypeParamAction.success(resp.data))
//     yield put(calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.success(resp.data))
//   } catch (e: unknown) {
//     yield put(
//       calcAssetTypeParamActions.patchCalcAssetTypeParamAction.failure({
//         error: e?.toString() || "",
//       }),
//     )
//     console.log(e)
//   }
// }

export function* deleteCalcAssetTypeParamSaga({ payload }: TDeleteCalcAssetTypeParamReqAction) {
  try {
    const resp: TDeleteCalcAssetTypeParamResp = yield call(apiRequest, "deleteCalcAssetTypeParam", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeParamActions.deleteCalcAssetTypeParamAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeParamActions.deleteCalcAssetTypeParamAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcAssetTypeParamWatcher() {
  yield takeLatest(
    calcAssetTypeParamActions.getCalcAssetTypeParamAction.call.type,
    getCalcAssetTypeParamSaga,
  )
  yield takeLatest(
    calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.call.type,
    getCalcAssetTypeParamByIdSaga,
  )
  yield takeLatest(
    calcAssetTypeParamActions.postCalcAssetTypeParamAction.call.type,
    postCalcAssetTypeParamSaga,
  )
  // yield takeLatest(calcAssetTypeParamActions.patchCalcAssetTypeParamAction.call.type, patchCalcAssetTypeParamSaga)
  yield takeLatest(
    calcAssetTypeParamActions.deleteCalcAssetTypeParamAction.call.type,
    deleteCalcAssetTypeParamSaga,
  )
}
