import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcAssetTypeParamFailurePayload,
  TDeleteCalcAssetTypeParamReqPayload,
  TDeleteCalcAssetTypeParamSuccessPayload,
  TGetCalcAssetTypeParamByIdFailurePayload,
  TGetCalcAssetTypeParamByIdReqPayload,
  TGetCalcAssetTypeParamByIdSuccessPayload,
  TGetCalcAssetTypeParamFailurePayload,
  TGetCalcAssetTypeParamReqPayload,
  TGetCalcAssetTypeParamSuccessPayload, // TPatchCalcAssetTypeParamFailurePayload,
  // TPatchCalcAssetTypeParamReqPayload,
  // TPatchCalcAssetTypeParamSuccessPayload,
  TPostCalcAssetTypeParamFailurePayload,
  TPostCalcAssetTypeParamReqPayload,
  TPostCalcAssetTypeParamSuccessPayload,
} from "./types"

const prefix = "calc-asset-type-param"

export const getCalcAssetTypeParamAction = baseCSF<
  TGetCalcAssetTypeParamReqPayload,
  TGetCalcAssetTypeParamSuccessPayload,
  TGetCalcAssetTypeParamFailurePayload,
  undefined
>(prefix, "get")
export const getCalcAssetTypeParamByIdAction = baseCSF<
  TGetCalcAssetTypeParamByIdReqPayload,
  TGetCalcAssetTypeParamByIdSuccessPayload,
  TGetCalcAssetTypeParamByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcAssetTypeParamAction = baseCSF<
  TPostCalcAssetTypeParamReqPayload,
  TPostCalcAssetTypeParamSuccessPayload,
  TPostCalcAssetTypeParamFailurePayload,
  undefined
>(prefix, "post")

// export const patchCalcAssetTypeParamAction = baseCSF<
//   TPatchCalcAssetTypeParamReqPayload,
//   TPatchCalcAssetTypeParamSuccessPayload,
//   TPatchCalcAssetTypeParamFailurePayload,
//   undefined
// >(prefix, "patch")

export const deleteCalcAssetTypeParamAction = baseCSF<
  TDeleteCalcAssetTypeParamReqPayload,
  TDeleteCalcAssetTypeParamSuccessPayload,
  TDeleteCalcAssetTypeParamFailurePayload,
  undefined
>(prefix, "delete")
