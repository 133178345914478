import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcAssetTypeTaxFailurePayload,
  TDeleteCalcAssetTypeTaxReqPayload,
  TDeleteCalcAssetTypeTaxSuccessPayload,
  TGetCalcAssetTypeTaxByIdFailurePayload,
  TGetCalcAssetTypeTaxByIdReqPayload,
  TGetCalcAssetTypeTaxByIdSuccessPayload,
  TGetCalcAssetTypeTaxFailurePayload,
  TGetCalcAssetTypeTaxReqPayload,
  TGetCalcAssetTypeTaxSuccessPayload, // TPatchCalcAssetTypeTaxFailurePayload,
  // TPatchCalcAssetTypeTaxReqPayload,
  // TPatchCalcAssetTypeTaxSuccessPayload,
  TPostCalcAssetTypeTaxFailurePayload,
  TPostCalcAssetTypeTaxReqPayload,
  TPostCalcAssetTypeTaxSuccessPayload,
} from "./types"

const prefix = "calc-asset-type-tax"

export const getCalcAssetTypeTaxAction = baseCSF<
  TGetCalcAssetTypeTaxReqPayload,
  TGetCalcAssetTypeTaxSuccessPayload,
  TGetCalcAssetTypeTaxFailurePayload,
  undefined
>(prefix, "get")
export const getCalcAssetTypeTaxByIdAction = baseCSF<
  TGetCalcAssetTypeTaxByIdReqPayload,
  TGetCalcAssetTypeTaxByIdSuccessPayload,
  TGetCalcAssetTypeTaxByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcAssetTypeTaxAction = baseCSF<
  TPostCalcAssetTypeTaxReqPayload,
  TPostCalcAssetTypeTaxSuccessPayload,
  TPostCalcAssetTypeTaxFailurePayload,
  undefined
>(prefix, "post")

// export const patchCalcAssetTypeTaxAction = baseCSF<
//   TPatchCalcAssetTypeTaxReqPayload,
//   TPatchCalcAssetTypeTaxSuccessPayload,
//   TPatchCalcAssetTypeTaxFailurePayload,
//   undefined
// >(prefix, "patch")

export const deleteCalcAssetTypeTaxAction = baseCSF<
  TDeleteCalcAssetTypeTaxReqPayload,
  TDeleteCalcAssetTypeTaxSuccessPayload,
  TDeleteCalcAssetTypeTaxFailurePayload,
  undefined
>(prefix, "delete")
