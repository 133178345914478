import React from "react"

import { Content } from "antd/es/layout/layout"

import { LeasingHeader } from "ClientModule/containers/LeasingHeader"
import { Layout } from "antd"

type LeasingLayoutProps = {
  children?: React.ReactNode
}
export const LeasingLayout = ({ children }: LeasingLayoutProps) => {
  return (
    <Layout style={{ height: "100%" }}>
      <LeasingHeader
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 100,
        }}
      />
      <Content
        style={{
          margin: 0,
          minHeight: 300,
          marginTop: "60px",
        }}
      >
        {children}
      </Content>
    </Layout>
  )
}
