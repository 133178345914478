import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import * as calcAgentActions from "Base/store/agent/actions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import { TCalcAgent } from "Base/types/provider/api/agent"
import { phoneNumber, validatePhoneNumber } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { Form, Input, Select } from "antd"

const { Item } = Form
export const AgencyPaymentsForm = ({
  agent,
  newAgent,
  setNewAgent,
}: {
  agent?: TCalcAgent
  newAgent?: TCalcAgent
  setNewAgent?: (agent: TCalcAgent) => void
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {
    base: {
      calcAgent: { getCalcAgent, putCalcAgent },
    },
  } = useSelector((state: RootState) => state)

  const _agent = useMemo(() => {
    return newAgent ? newAgent : agent
  }, [agent, newAgent])

  useEffect(() => {
    if (!_agent) return
    form.setFieldValue("name", _agent?.name)
    form.setFieldValue("contactName", _agent?.contactName)
    form.setFieldValue("contactPhone", _agent?.contactPhone)
    form.setFieldValue("contactJobTitle", _agent?.contactJobTitle)
    form.setFieldValue("inn", _agent?.inn)
  }, [_agent])

  const agentNameOptions = useMemo(() => {
    if (!getCalcAgent.data?.result) return []
    return getCalcAgent.data?.result.map((agentItem) => {
      return { value: agentItem.id, label: agentItem.name }
    })
  }, [getCalcAgent.data])

  const agentInnOptions = useMemo(() => {
    if (!getCalcAgent.data?.result) return []
    return getCalcAgent.data?.result.map((agentItem) => {
      return { value: agentItem.id, label: agentItem.inn }
    })
  }, [getCalcAgent.data])

  const isDisableForm = useMemo(() => {
    return getCalcAgent.isLoading || putCalcAgent.isLoading
  }, [getCalcAgent, putCalcAgent])

  const onBlur = () => {
    if (!_agent) return
    if (
      form.getFieldValue("name") &&
      form.getFieldValue("contactName") &&
      form.getFieldValue("contactPhone") &&
      form.getFieldValue("contactJobTitle") &&
      form.getFieldValue("inn")
    )
      dispatch(
        calcAgentActions.putCalcAgentAction.call({
          id: _agent.id,
          name: form.getFieldValue("name"),
          contactName: form.getFieldValue("contactName"),
          contactPhone: form.getFieldValue("contactPhone"),
          contactJobTitle: form.getFieldValue("contactJobTitle"),
          inn: form.getFieldValue("inn"),
        }),
      )
  }

  const onSelectNameOrInn = (value: number) => {
    if (value === _agent?.id) return
    if (!newAgent && _agent) {
      dispatch(
        calcPaymentOtherActions.putCalcPaymentOtherByAgentIdAction.call({
          agentIdNew: value,
          agentIdPrev: _agent.id,
        }),
      )
    } else {
      const _newAgent = getCalcAgent.data?.result.find((agentItem) => agentItem.id === value)
      _newAgent && setNewAgent && setNewAgent(_newAgent)
    }
  }

  return (
    <Form disabled={isDisableForm} name='agency' form={form} layout={"vertical"}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "24px",
        }}
      >
        <Item required name='name' label='Наименование агента'>
          <Select
            className='select-custom'
            size='large'
            onChange={onSelectNameOrInn}
            onSearch={() => {}}
            onBlur={() => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder='Введите наименование'
            optionFilterProp='children'
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={agentNameOptions}
          />
        </Item>
        <Item required name='contactName' label='Контактное лицо'>
          <Input onBlur={() => onBlur()} size='large' placeholder='Введите ФИО' />
        </Item>
        <Item required name='inn' label='ИНН'>
          <Select
            className='select-custom'
            size='large'
            onChange={onSelectNameOrInn}
            onSearch={() => {}}
            onBlur={() => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder='502101100500'
            optionFilterProp='children'
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={agentInnOptions}
          />
        </Item>
        <Item rules={[phoneNumber]} name='contactPhone' label='Телефон контактного лица'>
          <Input onBlur={() => onBlur()} size='large' placeholder='+79999999999' />
        </Item>
        <Item name='contactJobTitle' label='Контактное лицо должность'>
          <Input onBlur={() => onBlur()} size='large' placeholder='Введите должность' />
        </Item>
      </div>
    </Form>
  )
}
