import { modulesRoutes } from "./modulesRoutes"

const getClientRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Client",
    "list-of-lease-payment-schedules": {
      root: `${url}/list-of-lease-payment-schedules`,
      title: "Список графиков лизинговых платежей",
    },
    "lease-payment-schedule": {
      root: `${url}/lease-payment-schedule`,
      title: "График лизинговых платежей",
    },
    "leasing-stages": {
      root: `${url}/leasing-stages`,
      title: "Этапы лизинга",
      lessee: {
        title: "Лизингополучатель",
        root: `${url}/leasing-stages/lessee`,
      },
      "subject-of-leasing": {
        title: "Предмет лизинга",
        root: `${url}/leasing-stages/subject-of-leasing`,
      },
      deal: {
        title: "Сделка",
        root: `${url}/leasing-stages/deal`,
      },
      agency: {
        title: "Агентские",
        root: `${url}/leasing-stages/agency`,
      },
    },
    "lease-payment-history": {
      root: `${url}/lease-payment-history`,
      title: "История сделок",
    },
  }
}

export const clientModuleRoutes = getClientRoutes(modulesRoutes.client.root)
