import React, { CSSProperties, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { EditOutlined, DownloadOutlined } from "@ant-design/icons"
import { LoaderSpinner } from "Base/components"
import { exportChart } from "Base/provider/api/exportDocsToExcel"
import * as calcLeadActions from "Base/store/lead/actions"
import { TCalcLeadStatus } from "Base/types/provider/api/lead"
import { downloadBlob } from "Base/utils/downloadBlob"
import { RootState } from "Starter/store/configureStore"
import { Button, Form, Input, Select, Space, notification } from "antd"
import { StringParam, useQueryParam } from "use-query-params"

export const InputNameLeadForm = ({ style = {} }: { style?: CSSProperties | undefined }) => {
  const dispatch = useDispatch()
  const [leadId, setLeadId] = useQueryParam("leadId", StringParam)
  const {
    base: {
      calcLead: { postCalcLead, getCalcLeadById },
    },
  } = useSelector((state: RootState) => state)
  const [form] = Form.useForm()
  const [enableEditing, setEnableEditing] = useState(false)

  useEffect(() => {
    if (leadId && getCalcLeadById?.data?.result.name) {
      form.setFieldValue("name", getCalcLeadById?.data?.result.name)
      form.setFieldValue("status", getCalcLeadById?.data?.result.status)
    }
    setEnableEditing(!leadId)
  }, [getCalcLeadById, leadId])

  useEffect(() => {
    if (!getCalcLeadById?.data?.result.id || leadId) return
    setLeadId(getCalcLeadById.data.result.id + "")
    setEnableEditing(false)
  }, [getCalcLeadById?.data?.result.id])

  const onClickSave = () => {
    if (!form.getFieldValue("name")) return
    if (leadId) {
      dispatch(
        calcLeadActions.patchCalcLeadAction.call({
          id: leadId,
          name: form.getFieldValue("name"),
        }),
      )
    } else {
      dispatch(
        calcLeadActions.postCalcLeadAction.call({
          name: form.getFieldValue("name"),
        }),
      )
    }
    setEnableEditing(false)
  }

  const onChangeStatus = (value: TCalcLeadStatus) => {
    if (!leadId) return
    dispatch(
      calcLeadActions.postCalcLeadStatusAction.call({
        id: leadId,
        status: value,
      }),
    )
  }

  const downloadExcel = async () => {
    if (!leadId) return

    const formatDateAndTime = (date: number | Date | undefined) => {
      if (!date) return ""
      return new Intl.DateTimeFormat("ru", {
        timeStyle: "short",
        dateStyle: "short",
      }).format(new Date(date))
    }

    exportChart(Number(leadId))
      .then((blob) => {
        if (blob) {
          downloadBlob(blob, `График от ${formatDateAndTime(new Date())}.xlsx`)
        }
      })
      .catch(() => {
        notification.error({ message: "При загрузке файла произошла ошибка" })
      })
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 30px 20px 30px",
        ...style,
      }}
    >
      <Form name='InputNameLeadForm' form={form} onFinish={onClickSave}>
        {enableEditing ? (
          <Space>
            <Form.Item style={{ margin: 0 }} name='name' label='' required>
              <Input
                size='large'
                name='name'
                placeholder='Название'
                disabled={postCalcLead.isLoading}
                style={{ width: "200px" }}
                required
              />
            </Form.Item>
            {leadId && (
              <Button
                style={{ padding: 0, color: "grey" }}
                type='link'
                block
                onClick={() => setEnableEditing(false)}
                disabled={postCalcLead.isLoading}
              >
                Отменить
              </Button>
            )}
            <Button
              disabled={postCalcLead.isLoading}
              style={{ padding: 0 }}
              type='link'
              block
              htmlType='submit'
            >
              {postCalcLead.isLoading && <LoaderSpinner />}
              Сохранить
            </Button>
          </Space>
        ) : (
          <Space>
            <h3 style={{ color: "#78797a" }}>Название: </h3>
            <h3 style={{ position: "relative", minWidth: "40px" }}>
              {postCalcLead.isLoading || getCalcLeadById.isLoading ? (
                <LoaderSpinner />
              ) : (
                <>{form.getFieldValue("name") || getCalcLeadById?.data?.result.name}</>
              )}
            </h3>
            <EditOutlined
              disabled={getCalcLeadById.isLoading}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEnableEditing(true)
              }}
            />
            <Form.Item name='status' style={{ margin: 0, marginLeft: "20px" }}>
              <Select
                className='select-custom'
                style={{ width: "160px" }}
                placeholder='Выберите тип'
                options={[
                  { value: "ARCHIVED", label: "Архивированный" },
                  { value: "LEAD", label: "Черновик" },
                  { value: "DEAL", label: "Активный" },
                ]}
                disabled={getCalcLeadById.isLoading}
                onChange={onChangeStatus}
              />
            </Form.Item>
          </Space>
        )}
      </Form>
      {leadId && (
        <Button onClick={downloadExcel} icon={<DownloadOutlined />}>
          Скачать документы
        </Button>
      )}
    </div>
  )
}
