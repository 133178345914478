import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcAmortizationGroupParams,
  TDeleteCalcAmortizationGroupReq,
  TDeleteCalcAmortizationGroupResp,
  TGetCalcAmortizationGroupByIdParams,
  TGetCalcAmortizationGroupByIdReq,
  TGetCalcAmortizationGroupByIdResp,
  TGetCalcAmortizationGroupParams,
  TGetCalcAmortizationGroupReq,
  TGetCalcAmortizationGroupResp,
  TPostCalcAmortizationGroupParams,
  TPostCalcAmortizationGroupReq,
  TPostCalcAmortizationGroupResp,
  TPutCalcAmortizationGroupParams,
  TPutCalcAmortizationGroupReq,
  TPutCalcAmortizationGroupResp,
} from "Base/types/provider/api/amortizationGroup"

export default class AmortizationGroupProvider extends ApiProviderDefault {
  static async deleteCalcAmortizationGroup(parameters: {
    params: TDeleteCalcAmortizationGroupParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/amortization-group/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcAmortizationGroupReq, TDeleteCalcAmortizationGroupResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcAmortizationGroupById(parameters: {
    params: TGetCalcAmortizationGroupByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/amortization-group/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAmortizationGroupByIdReq, TGetCalcAmortizationGroupByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAmortizationGroup(parameters: {
    params: TGetCalcAmortizationGroupParams
    headers: Headers
  }) {
    const { headers } = parameters
    const path = `/calc/amortization-group`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAmortizationGroupReq, TGetCalcAmortizationGroupResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcAmortizationGroup(parameters: {
    params: TPostCalcAmortizationGroupParams
    headers: Headers
  }) {
    const { params: body, headers } = parameters
    const path = "/calc/amortization-group"
    const query = {}
    return this.request<TPostCalcAmortizationGroupReq, TPostCalcAmortizationGroupResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putCalcAmortizationGroup(parameters: {
    params: TPutCalcAmortizationGroupParams
    headers: Headers
  }) {
    const {
      params: { id, name, rate },
      headers,
    } = parameters
    const path = `/calc/amortization-group/${id}`
    const body = { name, rate }
    const query = undefined
    return this.request<TPutCalcAmortizationGroupReq, TPutCalcAmortizationGroupResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
