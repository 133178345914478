import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcAssetTypeTaxParams,
  TDeleteCalcAssetTypeTaxReq,
  TDeleteCalcAssetTypeTaxResp,
  TGetCalcAssetTypeTaxByIdParams,
  TGetCalcAssetTypeTaxByIdReq,
  TGetCalcAssetTypeTaxByIdResp,
  TGetCalcAssetTypeTaxParams,
  TGetCalcAssetTypeTaxReq,
  TGetCalcAssetTypeTaxResp,
  TPostCalcAssetTypeTaxParams,
  TPostCalcAssetTypeTaxReq,
  TPostCalcAssetTypeTaxResp,
  TPutCalcAssetTypeTaxParams,
  TPutCalcAssetTypeTaxReq,
  TPutCalcAssetTypeTaxResp,
} from "Base/types/provider/api/assetTypeTax"

export default class AssetTypeTaxProvider extends ApiProviderDefault {
  static async deleteCalcAssetTypeTaxType(parameters: {
    params: TDeleteCalcAssetTypeTaxParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/type/tax/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcAssetTypeTaxReq, TDeleteCalcAssetTypeTaxResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetTypeTaxById(parameters: {
    params: TGetCalcAssetTypeTaxByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/type/tax/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetTypeTaxByIdReq, TGetCalcAssetTypeTaxByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetTypeTax(parameters: {
    params: TGetCalcAssetTypeTaxParams
    headers: Headers
  }) {
    const {
      headers,
      params: { assetType },
    } = parameters
    const path = `/calc/asset/type/tax`
    const body = undefined
    const query = { assetType }
    return this.request<TGetCalcAssetTypeTaxReq, TGetCalcAssetTypeTaxResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcAssetTypeTax(parameters: {
    params: TPostCalcAssetTypeTaxParams
    headers: Headers
  }) {
    const { params, headers } = parameters
    const path = "/calc/asset/type/tax"
    const query = { assetType: params.assetType }
    const body = params as TPostCalcAssetTypeTaxReq
    return this.request<TPostCalcAssetTypeTaxReq, TPostCalcAssetTypeTaxResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putCalcAssetTypeTax(parameters: {
    params: TPutCalcAssetTypeTaxParams
    headers: Headers
  }) {
    const {
      params: { id, name, rate },
      headers,
    } = parameters
    const path = `/calc/asset/type/tax/${id}`
    const body = {
      name,
      rate,
    }
    const query = undefined
    return this.request<TPutCalcAssetTypeTaxReq, TPutCalcAssetTypeTaxResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
