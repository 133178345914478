import { useState } from "react"

import { ListFormActions } from "Base/components/ListFormActions"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { nonZeroNumber, required } from "Base/utils/validation"
import { Select, Input, InputNumber, DatePicker, Form } from "antd"

const { Item } = Form

const typeTax = "typeTax"
const commentTax = "commentTax"
const dateTax = "dateTax"
const amountTax = "amountTax"

export const TaxItem = ({
  assetTypeTaxOptions,
  onDeleteClick,
  onAddClick,
  onUpdate,
  index = "",
  isCreateMode = false,
}: {
  assetTypeTaxOptions: Array<{ value: string | number; label: string }>
  index?: number | ""
  onDeleteClick?: (index: number) => void
  onAddClick?: () => void
  onUpdate?: () => Promise<void>
  isCreateMode?: boolean
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const isReadonly = !isEdit && !isCreateMode

  const onLocalEditClick = () => {
    if (isEdit) {
      onUpdate &&
        onUpdate()
          .then(() => {
            setIsEdit(false)
          })
          .catch(() => setIsEdit(true))
    } else {
      setIsEdit(true)
    }
  }
  return (
    <div
      style={{
        display: "flex",
        gap: "24px",
        alignItems: "center",
        borderBottom: isCreateMode ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "24px",
        }}
      >
        <Item required={false} name={typeTax + index} label='Тип налога' rules={[required]}>
          <Select
            disabled={isReadonly}
            className='select-custom'
            size='large'
            showSearch
            style={{ width: "100%" }}
            placeholder='Выберите тип'
            optionFilterProp='children'
            filterOption={(input, option) => (option?.label ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={assetTypeTaxOptions}
          />
        </Item>
        <Item required={false} name={commentTax + index} rules={[required]} label='Комментарий'>
          <Input disabled={isReadonly} size='large' placeholder='Налог соответствует типу' />
        </Item>
        <Item rules={[required]} required={false} label='Дата платежа' name={dateTax + index}>
          <DatePicker
            disabled={isReadonly}
            size='large'
            style={{ width: "100%" }}
            format='DD-MM-YYYY'
          />
        </Item>
        <Item
          required={false}
          label='Сумма'
          name={amountTax + index}
          rules={[nonZeroNumber, required]}
        >
          <InputNumber
            disabled={isReadonly}
            formatter={convertNumberToLocalString}
            parser={convertLocalStringToNumber}
            style={{ width: "100%" }}
            required
            size='large'
          />
        </Item>
      </div>
      <div>
        <ListFormActions
          direction='vertical'
          onLocalEditClick={onLocalEditClick}
          isEdit={isEdit}
          isCreateMode={isCreateMode}
          onAddClick={onAddClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </div>
  )
}
