import { useState, useRef, useEffect } from "react"

import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { InputNumber, Space } from "antd"

export const Widget = ({
  title,
  price,
  editable = false,
  onSave,
}: {
  title: string
  price: string | number
  editable?: boolean
  onSave?: (value: string | number) => void
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [editMode, setEditMode] = useState(false)
  const [localPrice, setLocalPrice] = useState(price)

  useEffect(() => {
    setLocalPrice(price)
  }, [price])

  const handleSave = async () => {
    if (onSave && String(localPrice) !== String(price)) {
      onSave(localPrice)
    }
    setEditMode(false)
  }
  return (
    <Space
      direction={"vertical"}
      style={{
        position: "relative",
        padding: "12px",
        background: "white",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "12px",
        gap: editMode ? "3px" : "8px",
        height: "75px",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between", alignItems: "top", gap: "4px" }}
      >
        {!editMode && <h3>{convertNumberToLocalString(localPrice)}</h3>}
        {editMode && (
          <InputNumber
            formatter={convertNumberToLocalString}
            parser={convertLocalStringToNumber}
            ref={inputRef}
            size='small'
            value={localPrice}
            onChange={(value) => setLocalPrice(value ?? 0)}
          />
        )}
        {editMode && (
          <CloseOutlined
            onClick={() => {
              setEditMode(false)
              setLocalPrice(price)
            }}
            style={{ cursor: "pointer" }}
          />
        )}
        {editable && !editMode && (
          <EditOutlined
            onClick={() => {
              setEditMode(true)
              queueMicrotask(() => inputRef.current?.focus())
            }}
            style={{ cursor: "pointer" }}
          />
        )}
        {editable && editMode && (
          <CheckOutlined onClick={handleSave} style={{ cursor: "pointer" }} />
        )}
      </div>
      <p>{title}</p>
    </Space>
  )
}
