import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcIndClassActions from "Base/store/indClass/actions"
import {
  TDeleteCalcIndClassData,
  TGetCalcIndClassData,
  TPostCalcIndClassData,
  TGetCalcIndClassByIdData,
} from "Base/types/provider/api/indClass"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcIndClassFailureAction,
  TDeleteCalcIndClassSuccessAction,
  TGetCalcIndClassFailureAction,
  TGetCalcIndClassSuccessAction,
  TGetCalcIndClassByIdFailureAction,
  TPostCalcIndClassFailureAction,
  TPostCalcIndClassSuccessAction,
} from "./types"

export type CalcIndClassStateType = {
  getCalcIndClass: {
    data?: TGetCalcIndClassData
  } & RequestInfoChildType
  getCalcIndClassById: {
    data?: TGetCalcIndClassByIdData
  } & RequestInfoChildType
  postCalcIndClass: {
    data?: TPostCalcIndClassData
  } & RequestInfoChildType
  deleteCalcIndClass: {
    data?: TDeleteCalcIndClassData
  } & RequestInfoChildType
}

const initialState: CalcIndClassStateType = {
  getCalcIndClass: {
    ...RequestInfoChildState,
  },
  getCalcIndClassById: {
    ...RequestInfoChildState,
  },
  postCalcIndClass: {
    ...RequestInfoChildState,
  },
  deleteCalcIndClass: {
    ...RequestInfoChildState,
  },
}

export const calcIndClassReducer = createReducer(initialState, {
  [calcIndClassActions.getCalcIndClassAction.call.type]: (state) => ({
    ...state,
    getCalcIndClass: {
      ...state.getCalcIndClass,
      isLoading: true,
    },
  }),
  [calcIndClassActions.getCalcIndClassAction.success.type]: (
    state,
    action: TGetCalcIndClassSuccessAction,
  ) => ({
    ...state,
    getCalcIndClass: {
      ...state.getCalcIndClass,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcIndClassActions.getCalcIndClassAction.failure.type]: (
    state,
    action: TGetCalcIndClassFailureAction,
  ) => ({
    ...state,
    getCalcIndClass: {
      ...state.getCalcIndClass,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcIndClassActions.getCalcIndClassByIdAction.call.type]: (state) => ({
    ...state,
    getCalcIndClassById: {
      ...state.getCalcIndClassById,
      isLoading: true,
    },
  }),
  [calcIndClassActions.getCalcIndClassByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcIndClassById: {
      ...state.getCalcIndClassById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcIndClassActions.getCalcIndClassByIdAction.failure.type]: (
    state,
    action: TGetCalcIndClassByIdFailureAction,
  ) => ({
    ...state,
    getCalcIndClassById: {
      ...state.getCalcIndClassById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcIndClassActions.getCalcIndClassByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcIndClassById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcIndClassActions.postCalcIndClassAction.call.type]: (state) => ({
    ...state,
    postCalcIndClass: {
      ...state.postCalcIndClass,
      isLoading: true,
    },
  }),
  [calcIndClassActions.postCalcIndClassAction.success.type]: (
    state,
    action: TPostCalcIndClassSuccessAction,
  ) => ({
    ...state,
    postCalcIndClass: {
      ...state.postCalcIndClass,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcIndClassActions.postCalcIndClassAction.failure.type]: (
    state,
    action: TPostCalcIndClassFailureAction,
  ) => ({
    ...state,
    postCalcIndClass: {
      ...state.postCalcIndClass,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcIndClassActions.deleteCalcIndClassAction.call.type]: (state) => ({
    ...state,
    deleteCalcIndClass: {
      ...state.deleteCalcIndClass,
      isLoading: true,
    },
  }),
  [calcIndClassActions.deleteCalcIndClassAction.success.type]: (
    state,
    action: TDeleteCalcIndClassSuccessAction,
  ) => ({
    ...state,
    deleteCalcIndClass: {
      ...state.deleteCalcIndClass,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcIndClassActions.deleteCalcIndClassAction.failure.type]: (
    state,
    action: TDeleteCalcIndClassFailureAction,
  ) => ({
    ...state,
    deleteCalcIndClass: {
      ...state.deleteCalcIndClass,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
