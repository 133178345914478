import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAssetTypeParamActions from "Base/store/assetTypeParam/actions"
import {
  TDeleteCalcAssetTypeParamData,
  TGetCalcAssetTypeParamByIdData,
  TGetCalcAssetTypeParamData, // TPatchCalcAssetTypeParamData,
  TPostCalcAssetTypeParamData,
} from "Base/types/provider/api/assetTypeParam"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcAssetTypeParamFailureAction,
  TDeleteCalcAssetTypeParamSuccessAction,
  TGetCalcAssetTypeParamByIdFailureAction,
  TGetCalcAssetTypeParamFailureAction,
  TGetCalcAssetTypeParamSuccessAction, // TPatchCalcAssetTypeParamFailureAction,
  // TPatchCalcAssetTypeParamSuccessAction,
  TPostCalcAssetTypeParamFailureAction,
  TPostCalcAssetTypeParamSuccessAction,
} from "./types"

export type CalcAssetTypeParamStateType = {
  getCalcAssetTypeParam: {
    data?: { [key: string]: TGetCalcAssetTypeParamData }
  } & RequestInfoChildType
  getCalcAssetTypeParamById: {
    data?: TGetCalcAssetTypeParamByIdData
  } & RequestInfoChildType
  postCalcAssetTypeParam: {
    data?: TPostCalcAssetTypeParamData
  } & RequestInfoChildType
  // patchCalcAssetTypeParam: {
  //   data?: TPatchCalcAssetTypeParamData
  // } & RequestInfoChildType
  deleteCalcAssetTypeParam: {
    data?: TDeleteCalcAssetTypeParamData
  } & RequestInfoChildType
}

const initialState: CalcAssetTypeParamStateType = {
  getCalcAssetTypeParam: {
    ...RequestInfoChildState,
  },
  getCalcAssetTypeParamById: {
    ...RequestInfoChildState,
  },
  postCalcAssetTypeParam: {
    ...RequestInfoChildState,
  },
  // patchCalcAssetTypeParam: {
  //   ...RequestInfoChildState,
  // },
  deleteCalcAssetTypeParam: {
    ...RequestInfoChildState,
  },
}

export const calcAssetTypeParamReducer = createReducer(initialState, {
  [calcAssetTypeParamActions.getCalcAssetTypeParamAction.call.type]: (state) => ({
    ...state,
    getCalcAssetTypeParam: {
      ...state.getCalcAssetTypeParam,
      isLoading: true,
    },
  }),
  [calcAssetTypeParamActions.getCalcAssetTypeParamAction.success.type]: (
    state,
    action: TGetCalcAssetTypeParamSuccessAction,
  ) => ({
    ...state,
    getCalcAssetTypeParam: {
      ...state.getCalcAssetTypeParam,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeParamActions.getCalcAssetTypeParamAction.failure.type]: (
    state,
    action: TGetCalcAssetTypeParamFailureAction,
  ) => ({
    ...state,
    getCalcAssetTypeParam: {
      ...state.getCalcAssetTypeParam,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAssetTypeParamById: {
      ...state.getCalcAssetTypeParamById,
      isLoading: true,
    },
  }),
  [calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcAssetTypeParamById: {
      ...state.getCalcAssetTypeParamById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.failure.type]: (
    state,
    action: TGetCalcAssetTypeParamByIdFailureAction,
  ) => ({
    ...state,
    getCalcAssetTypeParamById: {
      ...state.getCalcAssetTypeParamById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAssetTypeParamActions.getCalcAssetTypeParamByIdAction.initial.type]: (state) => {
    return {
      ...state,
      getCalcAssetTypeParamById: {
        data: undefined,
        isLoading: false,
        error: undefined,
      },
    }
  },
  //============================================================
  //============================================================
  [calcAssetTypeParamActions.postCalcAssetTypeParamAction.call.type]: (state) => ({
    ...state,
    postCalcAssetTypeParam: {
      ...state.postCalcAssetTypeParam,
      isLoading: true,
    },
  }),
  [calcAssetTypeParamActions.postCalcAssetTypeParamAction.success.type]: (
    state,
    action: TPostCalcAssetTypeParamSuccessAction,
  ) => ({
    ...state,
    postCalcAssetTypeParam: {
      ...state.postCalcAssetTypeParam,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeParamActions.postCalcAssetTypeParamAction.failure.type]: (
    state,
    action: TPostCalcAssetTypeParamFailureAction,
  ) => ({
    ...state,
    postCalcAssetTypeParam: {
      ...state.postCalcAssetTypeParam,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  // [calcAssetTypeParamActions.patchCalcAssetTypeParamAction.call.type]: (state) => ({
  //   ...state,
  //   patchCalcAssetTypeParam: {
  //     ...state.patchCalcAssetTypeParam,
  //     isLoading: true,
  //   },
  // }),
  // [calcAssetTypeParamActions.patchCalcAssetTypeParamAction.success.type]: (
  //   state,
  //   action: TPatchCalcAssetTypeParamSuccessAction,
  // ) => ({
  //   ...state,
  //   patchCalcAssetTypeParam: {
  //     ...state.patchCalcAssetTypeParam,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [calcAssetTypeParamActions.patchCalcAssetTypeParamAction.failure.type]: (
  //   state,
  //   action: TPatchCalcAssetTypeParamFailureAction,
  // ) => ({
  //   ...state,
  //   patchCalcAssetTypeParam: {
  //     ...state.patchCalcAssetTypeParam,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  //============================================================
  //============================================================
  [calcAssetTypeParamActions.deleteCalcAssetTypeParamAction.call.type]: (state) => ({
    ...state,
    deleteCalcAssetTypeParam: {
      ...state.deleteCalcAssetTypeParam,
      isLoading: true,
    },
  }),
  [calcAssetTypeParamActions.deleteCalcAssetTypeParamAction.success.type]: (
    state,
    action: TDeleteCalcAssetTypeParamSuccessAction,
  ) => ({
    ...state,
    deleteCalcAssetTypeParam: {
      ...state.deleteCalcAssetTypeParam,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeParamActions.deleteCalcAssetTypeParamAction.failure.type]: (
    state,
    action: TDeleteCalcAssetTypeParamFailureAction,
  ) => ({
    ...state,
    deleteCalcAssetTypeParam: {
      ...state.deleteCalcAssetTypeParam,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
