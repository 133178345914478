import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"

import Search from "antd/es/input/Search"
import { Content } from "antd/es/layout/layout"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { EllipsisOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { clientModuleRoutes } from "Base/constants/routes"
import * as calcLeadActions from "Base/store/lead/actions"
import { RootState } from "Starter/store/configureStore"
import { Button, Dropdown, MenuProps, Space, Table, Typography } from "antd"

const { Title } = Typography

interface TDataType {
  id: number
  name: string
  status: string
  author?: string
  lessee?: string
  createdAt: string
  modifiedAt: string
}

export const ListOfLeasePaymentSchedules = () => {
  const dispatch = useDispatch()
  const {
    base: {
      calcLead: { getCalcLead },
    },
  } = useSelector((state: RootState) => state)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})
  const [searchValue, setSearchValue] = useState("")

  const copy = (leadId: number) => {
    dispatch(calcLeadActions.copyCalcLeadAction.call({ sourceId: leadId }))
  }

  useEffect(() => {
    dispatch(calcLeadActions.getCalcLeadAction.call({}))
  }, [])

  const getItems = ({ leadId }: { leadId: number }): MenuProps["items"] => {
    return [
      {
        key: "1",
        label: (
          <NavLink
            to={clientModuleRoutes["leasing-stages"].lessee.root + "?leadId=" + leadId}
            rel='noopener noreferrer'
          >
            Редактировать
          </NavLink>
        ),
      },
      {
        key: "2",
        label: <div onClick={() => copy(leadId)}>Копировать</div>,
      },
      {
        key: "3",
        label: (
          <div
            onClick={() => {
              dispatch(calcLeadActions.deleteCalcLeadAction.call({ id: leadId }))
            }}
          >
            Удалить
          </div>
        ),
      },
      {
        key: "4",
        label: (
          <a target='_blank' rel='noopener noreferrer' href='/'>
            История изменений
          </a>
        ),
      },
    ]
  }
  const columns: ColumnsType<TDataType> = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Статус",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
    },

    {
      title: "Автор",
      dataIndex: "author",
      // sorter: (a, b) => a.author.localeCompare(b.author),
      // sortOrder: sortedInfo.columnKey === "author" ? sortedInfo.order : null,
    },

    {
      title: "Лизингополучатель",
      dataIndex: "leaseName",
      // sorter: (a, b) => a.lessee.localeCompare(b.lessee),
      // sortOrder: sortedInfo.columnKey === "lessee" ? sortedInfo.order : null,
    },

    {
      title: "Дата создания",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: sortedInfo.columnKey === "date" ? sortedInfo.order : null,
    },
    {
      title: "",
      key: "action",
      width: 150,
      render: (_, record: TDataType) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Dropdown
              menu={{ items: getItems({ leadId: record.id }) }}
              placement='bottomRight'
              arrow
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }

  const onChangeSearchValue = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const calcLeadData = useMemo(() => {
    if (!getCalcLead.data?.result) return []
    return searchValue
      ? getCalcLead.data?.result.filter(
          (calcLead) => calcLead.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1,
        )
      : getCalcLead.data?.result
  }, [getCalcLead.data, searchValue])

  return (
    <Content
      style={{
        padding: 24,
      }}
    >
      <Space style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Space>
          <Title style={{ margin: 0, marginRight: "20px" }} level={3}>
            Выбор сделки
          </Title>
          <Search onChange={onChangeSearchValue} placeholder='Поиск' />
        </Space>
        <NavLink to={clientModuleRoutes["leasing-stages"].lessee.root}>
          <Button icon={<PlusCircleOutlined />} type='primary'>
            Создать версию
          </Button>
        </NavLink>
      </Space>
      <Table
        loading={getCalcLead.isLoading}
        columns={columns}
        dataSource={calcLeadData}
        onChange={handleChange}
      />
    </Content>
  )
}
