import React from "react"
import { NavLink } from "react-router-dom"

import { clientModuleRoutes } from "Base/constants/routes"
import { THistoryChangesDataType } from "Base/store/paymentSchedule/types"
import { Breadcrumb } from "antd"

import "./styles.css"

interface BreadcrumbsProps {
  selectedPayment: THistoryChangesDataType | null
  handleBack: () => void
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ selectedPayment, handleBack }) => {
  return (
    <Breadcrumb style={{ margin: "20px 0" }}>
      <Breadcrumb.Item>
        <NavLink to={clientModuleRoutes["lease-payment-schedule"].root}>
          График лизинговых платежей
        </NavLink>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span onClick={handleBack} className='breadcrumb-link'>
          История сделок
        </span>
      </Breadcrumb.Item>
      {selectedPayment && (
        <Breadcrumb.Item className='breadcrumb-link'>
          Сделка № {selectedPayment.key}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  )
}
