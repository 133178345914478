import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcIndClassFailurePayload,
  TDeleteCalcIndClassReqPayload,
  TDeleteCalcIndClassSuccessPayload,
  TGetCalcIndClassFailurePayload,
  TGetCalcIndClassReqPayload,
  TGetCalcIndClassSuccessPayload,
  TPostCalcIndClassFailurePayload,
  TPostCalcIndClassReqPayload,
  TPostCalcIndClassSuccessPayload,
  TGetCalcIndClassByIdReqPayload,
  TGetCalcIndClassByIdSuccessPayload,
  TGetCalcIndClassByIdFailurePayload,
} from "./types"

const prefix = "calc-ind-class"

export const getCalcIndClassAction = baseCSF<
  TGetCalcIndClassReqPayload,
  TGetCalcIndClassSuccessPayload,
  TGetCalcIndClassFailurePayload,
  undefined
>(prefix, "get")
export const getCalcIndClassByIdAction = baseCSF<
  TGetCalcIndClassByIdReqPayload,
  TGetCalcIndClassByIdSuccessPayload,
  TGetCalcIndClassByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcIndClassAction = baseCSF<
  TPostCalcIndClassReqPayload,
  TPostCalcIndClassSuccessPayload,
  TPostCalcIndClassFailurePayload,
  undefined
>(prefix, "post")

export const deleteCalcIndClassAction = baseCSF<
  TDeleteCalcIndClassReqPayload,
  TDeleteCalcIndClassSuccessPayload,
  TDeleteCalcIndClassFailurePayload,
  undefined
>(prefix, "delete")
