import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcLesseeParams,
  TDeleteCalcLesseeReq,
  TDeleteCalcLesseeResp,
  TGetCalcLesseeByIdParams,
  TGetCalcLesseeByIdReq,
  TGetCalcLesseeByIdResp,
  TGetCalcLesseeParams,
  TGetCalcLesseeReq,
  TGetCalcLesseeResp,
  TPatchCalcLesseeParams,
  TPatchCalcLesseeReq,
  TPatchCalcLesseeResp,
  TPostCalcLesseeParams,
  TPostCalcLesseeReq,
  TPostCalcLesseeResp,
  TPutCalcLesseeParams,
  TPutCalcLesseeReq,
  TPutCalcLesseeResp,
} from "Base/types/provider/api/lessee"

export default class LesseeProvider extends ApiProviderDefault {
  static async deleteCalcLessee(parameters: { params: TDeleteCalcLesseeParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/lessee/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcLesseeReq, TDeleteCalcLesseeResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcLesseeById(parameters: {
    params: TGetCalcLesseeByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/lessee/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcLesseeByIdReq, TGetCalcLesseeByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcLessee(parameters: { params: TGetCalcLesseeParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/lessee`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcLesseeReq, TGetCalcLesseeResp>(path, "GET", query, body, headers)
  }

  static async postCalcLessee(parameters: { params: TPostCalcLesseeParams; headers: Headers }) {
    const { params: body, headers } = parameters
    const path = "/calc/lessee"
    const query = { name: body.name }
    return this.request<TPostCalcLesseeReq, TPostCalcLesseeResp>(
      path,
      "POST",
      query,
      undefined,
      headers,
    )
  }

  static async patchCalcLessee(parameters: { params: TPatchCalcLesseeParams; headers: Headers }) {
    const {
      params: {
        id,
        name,
        contactName,
        contactPhone,
        contactJobTitle,
        directorName,
        directorBirthdate,
        inn,
        indClass,
      },
      headers,
    } = parameters
    const path = `/calc/lessee/${id}`
    const body = {
      name,
      contactName,
      contactPhone,
      contactJobTitle,
      directorName,
      directorBirthdate,
      inn,
      indClass,
    }
    const query = undefined
    return this.request<TPatchCalcLesseeReq, TPatchCalcLesseeResp>(
      path,
      "PATCH",
      query,
      body,
      headers,
    )
  }

  static async putCalcLessee(parameters: { params: TPutCalcLesseeParams; headers: Headers }) {
    const {
      params: {
        id,
        name,
        contactName,
        contactPhone,
        contactJobTitle,
        directorName,
        directorBirthdate,
        inn,
        indClass,
      },
      headers,
    } = parameters
    const path = `/calc/lessee/${id}`
    const body = {
      name,
      contactName,
      contactPhone,
      contactJobTitle,
      directorName,
      directorBirthdate,
      inn,
      indClass,
    }
    const query = undefined
    return this.request<TPutCalcLesseeReq, TPutCalcLesseeResp>(path, "PUT", query, body, headers)
  }
}
