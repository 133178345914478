import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"

import { Content } from "antd/es/layout/layout"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { RightOutlined } from "@ant-design/icons"
import { Widget } from "Base/components"
import { clientModuleRoutes } from "Base/constants/routes"
import * as calcAgentActions from "Base/store/agent/actions"
import * as calcAssetActions from "Base/store/asset/actions"
import * as calcPaymentScheduleActions from "Base/store/paymentSchedule/actions"
import { TCalcPaymentSchedulePayment } from "Base/types/provider/api/paymentSchedule"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { InputNameLeadForm } from "ClientModule/containers"
import { RootState } from "Starter/store/configureStore"
import { TableProps, Button, Modal, DatePicker, Form } from "antd"
import { InputNumber, Select, Space, Table, Typography, Checkbox } from "antd"

const { Title } = Typography

interface TDataType extends TCalcPaymentSchedulePayment {
  key: React.Key
}

const NavElementLink = ({ to, text, label }: { to: string; label: string; text: string }) => (
  <Space direction='vertical'>
    <NavLink to={to}>
      <Title style={{ margin: 0 }} level={5}>
        {label} <RightOutlined />
      </Title>
    </NavLink>
    <div style={{ color: "grey" }}>{text}</div>
  </Space>
)

export const LeasePaymentSchedule = () => {
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()
  const [localPayments, setLocalPayments] = useState<TDataType[]>([])
  const [initialPayments, setInitialPayments] = useState<TDataType[]>([]) // хранение начальных значений
  const [isChanged, setIsChanged] = useState(false)
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcPaymentSchedule: { getCalcPaymentSchedule, patchCalcPaymentSchedule },
      calcAsset: { getCalcAsset },
      calcAgent: { getCalcAgentFilteredByPayments },
      calcPaymentOther: { getCalcPaymentOther },
    },
  } = useSelector((state: RootState) => state)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})
  const leadId = useMemo(() => {
    return getCalcLeadById?.data?.result.id
  }, [getCalcLeadById?.data?.result.id])

  useEffect(() => {
    if (!leadId) return
    dispatch(calcAssetActions.getCalcAssetAction.call({ leadId }))
    dispatch(calcPaymentScheduleActions.getCalcPaymentScheduleAction.call({ leadId }))
    dispatch(calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({ leadId }))
  }, [leadId])

  useEffect(() => {
    if (getCalcPaymentSchedule?.data?.result.length) {
      const payments = getCalcPaymentSchedule?.data?.result[0]?.payments || []
      const paymentYield = getCalcPaymentSchedule?.data?.result[0]?.paymentYield
      const data =
        payments.map((payment, index) => ({
          key: ++index,
          paymentYield,
          ...payment,
        })) || []
      setLocalPayments(data)
      setInitialPayments(JSON.parse(JSON.stringify(data))) // сохраняем начальные значения
      setIsChanged(false)
    }
  }, [getCalcPaymentSchedule?.data])

  const isDisableForm = useMemo(
    () => patchCalcPaymentSchedule.isLoading,
    [patchCalcPaymentSchedule.isLoading],
  )

  const financialResult = getCalcPaymentSchedule?.data?.result[0]?.financialResult || 0
  const redemptionAmount = getCalcPaymentSchedule?.data?.result[0]?.redemptionAmount || 0
  const residualValue = getCalcPaymentSchedule?.data?.result[0]?.residualValue || 0
  const paymentYield = getCalcPaymentSchedule?.data?.result[0]?.paymentYield || 0
  const yieldPercent = getCalcPaymentSchedule?.data?.result[0]?.yieldPercent || 0
  const risePerYear = getCalcPaymentSchedule?.data?.result[0]?.risePerYear || 0

  const onChangePaymentSchedule = ({
    name,
    value,
    paymentSchedule,
  }: {
    name: keyof TDataType
    value: number | string | boolean
    paymentSchedule: TDataType
  }) => {
    const updatedPayments = localPayments.map((payment) => {
      if (payment.id === paymentSchedule.id) {
        return {
          ...payment,
          [name]: value,
        }
      }
      return payment
    })

    setLocalPayments(updatedPayments)

    const initialPayment = initialPayments.find((payment) => payment.id === paymentSchedule.id)
    if (initialPayment && initialPayment[name] !== value) {
      setIsChanged(true)
    }
  }

  const handleSave = () => {
    if (getCalcPaymentSchedule?.data?.result[0]) {
      dispatch(
        calcPaymentScheduleActions.patchCalcPaymentScheduleAction.call({
          ...getCalcPaymentSchedule?.data?.result[0],
          payments: localPayments.map((payment) => ({
            ...payment,
            principalDebt: Number(payment.principalDebt),
            debtPercent: Number(payment.debtPercent),
          })),
        }),
      )
      setIsChanged(false)
      setInitialPayments(JSON.parse(JSON.stringify(localPayments)))
    }
  }

  const columns: ColumnsType<TDataType> = [
    {
      title: "№",
      dataIndex: "key",
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Основной долг, ₽",
      dataIndex: "principalDebt",
      sortOrder: sortedInfo.columnKey === "debtPayment" ? sortedInfo.order : null,
      render: (text, paymentSchedule) => (
        <InputNumber
          formatter={convertNumberToLocalString}
          parser={convertLocalStringToNumber}
          disabled={isDisableForm}
          value={text}
          onBlur={(event) => {
            const newValue = convertLocalStringToNumber(event.target.value)
            if (newValue !== paymentSchedule.principalDebt) {
              onChangePaymentSchedule({
                name: "principalDebt",
                value: newValue,
                paymentSchedule,
              })
            }
          }}
        />
      ),
    },
    {
      title: "Основной долг, %",
      dataIndex: "debtPercent",
      sortOrder: sortedInfo.columnKey === "debtPercent" ? sortedInfo.order : null,
      render: (text, paymentSchedule) => (
        <InputNumber
          max={100}
          disabled={isDisableForm}
          formatter={convertNumberToLocalString}
          parser={convertLocalStringToNumber}
          value={text}
          onBlur={(event) => {
            const newValue = convertLocalStringToNumber(event.target.value)
            if (newValue !== paymentSchedule.debtPercent) {
              onChangePaymentSchedule({
                name: "debtPercent",
                value: newValue,
                paymentSchedule,
              })
            }
          }}
        />
      ),
    },
    {
      title: "Проценты, ₽",
      dataIndex: "interest",
      sortOrder: sortedInfo.columnKey === "interest" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Налог, ₽",
      dataIndex: "taxes",
      sortOrder: sortedInfo.columnKey === "taxes" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Иные платы, ₽",
      dataIndex: "misc",
      sortOrder: sortedInfo.columnKey === "misc" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Доходность в месяц, ₽",
      dataIndex: "monthlyYield",
      sortOrder: sortedInfo.columnKey === "monthlyYield" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Итого, ₽",
      dataIndex: "total",
      sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
      render: (v) => convertNumberToLocalString(v),
    },
    {
      title: "Изменяемый платеж",
      dataIndex: "fix",
      sortOrder: sortedInfo.columnKey === "fix" ? sortedInfo.order : null,
      render: (text, paymentSchedule) => (
        <Checkbox
          defaultChecked={paymentSchedule.modifiable}
          disabled={isDisableForm}
          value={true}
          onChange={(event) => {
            const newValue = event.target.checked
            if (newValue !== paymentSchedule.modifiable) {
              onChangePaymentSchedule({
                name: "modifiable",
                value: newValue,
                paymentSchedule,
              })
            }
          }}
        />
      ),
    },
  ]

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }

  const lesseeInfo = useMemo(() => {
    return getCalcLeadById?.data?.result?.lessee?.name || "-"
  }, [getCalcLeadById])

  const subjectsOfLeasingInfo = useMemo(() => {
    const assetNames = getCalcAsset?.data?.result.map((asset) => asset.name)
    return assetNames ? assetNames.join(", ") : "-"
  }, [getCalcAsset])

  const dealInfo = useMemo(() => {
    const leaseData = getCalcLeadById?.data?.result.leaseData
    const term = leaseData?.term || 0
    const interestRate = leaseData?.interestRate || 0

    return `${interestRate}% ${term} месяца`
  }, [getCalcLeadById])

  const agencyInfo = useMemo(() => {
    return (
      getCalcAgentFilteredByPayments?.data?.reduce((textAgent, agent) => {
        let agentInfo = agent.name
        const paymentInfo = getCalcPaymentOther?.data?.result?.reduce(
          (textPaymentOther, paymentOther) => {
            if (agent.id !== paymentOther.agentId) return textPaymentOther
            return textPaymentOther
              ? `${textPaymentOther}, ${paymentOther.amount}₽`
              : `${paymentOther.amount}₽`
          },
          "",
        )

        if (paymentInfo) {
          agentInfo += `: ${paymentInfo}`
        }

        return textAgent ? `${textAgent} | ${agentInfo}` : agentInfo
      }, "") || "-"
    )
  }, [getCalcAgentFilteredByPayments, getCalcPaymentOther])

  // const updateRedemptionAmount = (redemptionAmount: string | number) => {
  //   if (getCalcPaymentSchedule?.data?.result[0]) {
  //     dispatch(
  //       calcPaymentScheduleActions.patchCalcPaymentScheduleAction.call({
  //         ...getCalcPaymentSchedule?.data?.result[0],
  //         redemptionAmount: Number(redemptionAmount),
  //       }),
  //     )
  //     setIsChanged(true)
  //   }
  // }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "5fr 1fr",
        gridTemplateRows: "1fr",
        minHeight: "100%",
      }}
    >
      <Content
        style={{
          padding: "30px",
        }}
      >
        <InputNameLeadForm style={{ padding: "0 0 0 0" }} />
        <Space
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
            margin: "20px 0",
          }}
        >
          <Widget title={"Сумма лизинга"} price={financialResult} />
          <Widget
            title={"Сумма выкупа"}
            price={redemptionAmount}
            // editable
            // onSave={updateRedemptionAmount}
          />
          <Widget title={"Доходность"} price={paymentYield} />
          <Widget title={"Доходность %"} price={yieldPercent} />
          <Widget title={"Удорожание в год, %"} price={risePerYear} />
          <Widget title={"Остаточная стоимость"} price={residualValue} />
        </Space>
        <Space style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          <Title level={3}>График лизинговых платежей</Title>
          {/* <Form.Item name='status' style={{ margin: 0, marginLeft: "20px" }}>
            <Select
              className='select-custom'
              style={{ width: "160px" }}
              placeholder='Выбор динамики'
              options={[
                { value: "type1", label: "1 тип" },
                { value: "type2", label: "2 тип" },
                { value: "type3", label: "3 тип" },
              ]}
              onChange={() => {}}
            />
          </Form.Item> */}
          <div style={{ display: "flex" }}>
            <Button type='primary' onClick={handleSave} disabled={!isChanged}>
              Сохранить изменения
            </Button>
          </div>
        </Space>

        <Table
          key={Date.now()}
          columns={columns}
          dataSource={localPayments}
          onChange={handleChange}
          pagination={false}
        />

        <Modal
          title='Досрочное погашение'
          open={isModalVisible}
          onCancel={handleCancel}
          onOk={handleSave}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='earlyPaymentDate'
              label='Дата досрочного погашения'
              rules={[{ required: true, message: "Пожалуйста, выберите дату" }]}
            >
              <DatePicker format='DD.MM.YYYY' />
            </Form.Item>
          </Form>
        </Modal>
      </Content>
      <div
        style={{
          position: "fixed",
          top: "60px",
          right: 0,
          height: "calc(100vh - 60px)",
          background: "white",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto",
            gridTemplateRows: "min-content",
            gap: "16px",
          }}
        >
          <NavElementLink
            to={clientModuleRoutes["leasing-stages"].lessee.root + "?leadId=" + leadId}
            label={"Лизингополучатель"}
            text={lesseeInfo}
          />
          <NavElementLink
            to={
              clientModuleRoutes["leasing-stages"]["subject-of-leasing"].root + "?leadId=" + leadId
            }
            label={"Предмет лизинга"}
            text={subjectsOfLeasingInfo}
          />
          <NavElementLink
            to={clientModuleRoutes["leasing-stages"].deal.root + "?leadId=" + leadId}
            label={"Сделка"}
            text={dealInfo}
          />
          <NavElementLink
            to={clientModuleRoutes["leasing-stages"].agency.root + "?leadId=" + leadId}
            label={"Агентские"}
            text={agencyInfo}
          />
          <NavElementLink
            to={clientModuleRoutes["lease-payment-history"].root}
            label={"История сделок"}
            text=''
          />
        </div>
        <Button
          type='primary'
          onClick={showModal}
          style={{ marginTop: "20px", backgroundColor: "#142252" }}
        >
          Досрочное погашение
        </Button>
      </div>
    </div>
  )
}
