import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import { ListFormActions } from "Base/components/ListFormActions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import { TCalcPaymentOther } from "Base/types/provider/api/paymentOther"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { nonZeroNumber, required } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { Form, Input, InputNumber } from "antd"
import { format } from "date-fns"
import "dayjs/locale/ru"

const { Item } = Form

const reasonName = "reason"
const amountName = "amount"

const OtherExpensesItem = ({
  data,
  onDeleteClick,
  onUpdate,
  onAdd,
}: {
  data?: TCalcPaymentOther
  onDeleteClick?: (data: TCalcPaymentOther) => void
  onUpdate?: (data: TCalcPaymentOther) => Promise<void>
  onAdd?: () => void
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const isReadonly = !isEdit && Boolean(data)

  const onLocalEditClick = () => {
    if (isEdit) {
      data &&
        onUpdate &&
        onUpdate(data)
          .then(() => {
            setIsEdit(false)
          })
          .catch(() => setIsEdit(true))
    } else {
      setIsEdit(true)
    }
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr auto auto",
        columnGap: "24px",
      }}
    >
      <Item
        name={reasonName + (data?.id || "")}
        required={false}
        label='Статья расхода'
        style={{ margin: 0 }}
        rules={[required]}
      >
        <Input size='large' disabled={isReadonly} placeholder='Введите статью' />
      </Item>

      <Item
        rules={[required, nonZeroNumber]}
        name={amountName + (data?.id || "")}
        required={false}
        label='Сумма'
        style={{ margin: 0 }}
      >
        <InputNumber
          disabled={isReadonly}
          formatter={convertNumberToLocalString}
          parser={convertLocalStringToNumber}
          size='large'
          style={{ width: "100%" }}
        />
      </Item>

      <ListFormActions
        isCreateMode={!data}
        isEdit={isEdit}
        onLocalEditClick={onLocalEditClick}
        onAddClick={onAdd}
        onDeleteClick={() => data && onDeleteClick && onDeleteClick(data)}
      />
    </div>
  )
}

export const OtherExpensesForm = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const {
    base: {
      calcLead: { getCalcLeadById },
      calcPaymentOther: { getCalcPaymentOther, putCalcPaymentOther },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    getCalcPaymentOther?.data?.result.forEach((paymentOther) => {
      form.setFieldValue(reasonName + paymentOther.id, paymentOther.reason)
      form.setFieldValue(amountName + paymentOther.id, paymentOther.amount)
    })
  }, [getCalcPaymentOther?.data])

  const isDisableForm = useMemo(() => {
    return (
      getCalcLeadById.isLoading || getCalcPaymentOther.isLoading || putCalcPaymentOther.isLoading
    )
  }, [getCalcLeadById, putCalcPaymentOther, getCalcPaymentOther])

  const paymentOtherAsset = useMemo(() => {
    return (
      getCalcPaymentOther?.data?.result.filter(
        (paymentOther) =>
          paymentOther.leadId === getCalcLeadById?.data?.result.id &&
          !paymentOther?.agentId &&
          !paymentOther?.assetId,
      ) || []
    )
  }, [getCalcLeadById, getCalcPaymentOther])

  const onCreate = () => {
    if (!getCalcLeadById?.data?.result.id) return

    dispatch(
      calcPaymentOtherActions.postCalcPaymentOtherAction.call({
        leadId: getCalcLeadById?.data?.result.id,
        amount: form.getFieldValue(amountName),
        reason: form.getFieldValue(reasonName),
        date: format(new Date(), "yyyy-MM-dd"),
      }),
    )

    form.setFieldValue(amountName, undefined)
    form.setFieldValue(reasonName, undefined)
  }

  const onChange = async (paymentOther: TCalcPaymentOther) => {
    const fieldsNames = [amountName + paymentOther.id, reasonName + paymentOther.id]
    return form.validateFields(fieldsNames).then(() => {
      dispatch(
        calcPaymentOtherActions.putCalcPaymentOtherAction.call({
          ...paymentOther,
          paymentId: paymentOther.id,
          amount: form.getFieldValue(amountName + paymentOther.id),
          reason: form.getFieldValue(reasonName + paymentOther.id),
          date: format(new Date(), "yyyy-MM-dd"),
        }),
      )
    })
  }

  const onDelete = (paymentOther: TCalcPaymentOther) => {
    dispatch(
      calcPaymentOtherActions.deleteCalcPaymentOtherAction.call({
        id: paymentOther.id,
      }),
    )
  }

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Иные расходы
      </Title>
      <Form
        disabled={isDisableForm}
        onFinish={onCreate}
        onFinishFailed={(e) => console.info(e)}
        name='mixes'
        form={form}
        layout={"vertical"}
      >
        {paymentOtherAsset.map((paymentOther, index) => (
          <OtherExpensesItem
            key={index}
            data={paymentOther}
            onDeleteClick={onDelete}
            onUpdate={onChange}
          />
        ))}
        <OtherExpensesItem />
      </Form>
    </>
  )
}
