import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcLeadParams,
  TDeleteCalcLeadReq,
  TDeleteCalcLeadResp,
  TGetCalcLeadByIdParams,
  TGetCalcLeadByIdReq,
  TGetCalcLeadByIdResp,
  TGetCalcLeadParams,
  TGetCalcLeadReq,
  TGetCalcLeadResp,
  TPostCalcLeadParams,
  TPostCalcLeadReq,
  TPostCalcLeadResp,
  TPatchCalcLeadParams,
  TPatchCalcLeadReq,
  TPatchCalcLeadResp,
  TPostCalcLeadStatusResp,
  TPostCalcLeadStatusReq,
  TPostCalcLeadStatusParams,
  TCopyCalcLeadParams,
  TCopyCalcLeadReq,
  TCopyCalcLeadResp,
} from "Base/types/provider/api/lead"

export default class LeadProvider extends ApiProviderDefault {
  static async deleteCalcLead(parameters: { params: TDeleteCalcLeadParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/lead/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcLeadReq, TDeleteCalcLeadResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcLeadById(parameters: { params: TGetCalcLeadByIdParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/lead/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcLeadByIdReq, TGetCalcLeadByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcLead(parameters: { params: TGetCalcLeadParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/lead`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcLeadReq, TGetCalcLeadResp>(path, "GET", query, body, headers)
  }

  static async postCalcLead(parameters: { params: TPostCalcLeadParams; headers: Headers }) {
    const { params: body, headers } = parameters
    const path = "/calc/lead"
    const query = {}
    return this.request<TPostCalcLeadReq, TPostCalcLeadResp>(path, "POST", query, body, headers)
  }

  static async postCalcLeadStatus(parameters: {
    params: TPostCalcLeadStatusParams
    headers: Headers
  }) {
    const {
      params: { id, status },
      headers,
    } = parameters
    const path = "/calc/lead/" + id + "/status"
    const body = undefined
    const query = { status }
    return this.request<TPostCalcLeadStatusReq, TPostCalcLeadStatusResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async patchCalcLead(parameters: { params: TPatchCalcLeadParams; headers: Headers }) {
    const {
      params: { id, name, lessee, loanData, leaseData },
      headers,
    } = parameters
    const path = `/calc/lead/${id}`
    const body = { name, lessee, loanData, leaseData }
    const query = undefined
    return this.request<TPatchCalcLeadReq, TPatchCalcLeadResp>(path, "PATCH", query, body, headers)
  }

  static async copyCalcLead(parameters: { params: TCopyCalcLeadParams; headers: Headers }) {
    const {
      params: { sourceId },
      headers,
    } = parameters

    const path = `/calc/lead/copy/${sourceId}`
    return this.request<TCopyCalcLeadReq, TCopyCalcLeadResp>(
      path,
      "POST",
      undefined,
      undefined,
      headers,
    )
  }
}
