import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAssetTypeActions from "Base/store/assetType/actions"
import {
  TDeleteCalcAssetTypeData,
  TGetCalcAssetTypeByIdData,
  TGetCalcAssetTypeData, // TPatchCalcAssetTypeData,
  TPostCalcAssetTypeData,
} from "Base/types/provider/api/assetType"
import { RequestInfoChildType } from "Base/types/store/reducer"

import { calcAssetTypeWatcher } from "./sagas"
import {
  TDeleteCalcAssetTypeFailureAction,
  TDeleteCalcAssetTypeSuccessAction,
  TGetCalcAssetTypeByIdFailureAction,
  TGetCalcAssetTypeFailureAction,
  TGetCalcAssetTypeSuccessAction, // TPatchCalcAssetTypeFailureAction,
  // TPatchCalcAssetTypeSuccessAction,
  TPostCalcAssetTypeFailureAction,
  TPostCalcAssetTypeSuccessAction,
} from "./types"

export type CalcAssetTypeStateType = {
  getCalcAssetType: {
    data?: TGetCalcAssetTypeData
  } & RequestInfoChildType
  getCalcAssetTypeById: {
    data?: TGetCalcAssetTypeByIdData
  } & RequestInfoChildType
  postCalcAssetType: {
    data?: TPostCalcAssetTypeData
  } & RequestInfoChildType
  // patchCalcAssetType: {
  //   data?: TPatchCalcAssetTypeData
  // } & RequestInfoChildType
  deleteCalcAssetType: {
    data?: TDeleteCalcAssetTypeData
  } & RequestInfoChildType
}

const initialState: CalcAssetTypeStateType = {
  getCalcAssetType: {
    ...RequestInfoChildState,
  },
  getCalcAssetTypeById: {
    ...RequestInfoChildState,
  },
  postCalcAssetType: {
    ...RequestInfoChildState,
  },
  // patchCalcAssetType: {
  //   ...RequestInfoChildState,
  // },
  deleteCalcAssetType: {
    ...RequestInfoChildState,
  },
}

export const calcAssetTypeReducer = createReducer(initialState, {
  [calcAssetTypeActions.getCalcAssetTypeAction.call.type]: (state) => ({
    ...state,
    getCalcAssetType: {
      ...state.getCalcAssetType,
      isLoading: true,
    },
  }),
  [calcAssetTypeActions.getCalcAssetTypeAction.success.type]: (
    state,
    action: TGetCalcAssetTypeSuccessAction,
  ) => ({
    ...state,
    getCalcAssetType: {
      ...state.getCalcAssetType,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeActions.getCalcAssetTypeAction.failure.type]: (
    state,
    action: TGetCalcAssetTypeFailureAction,
  ) => ({
    ...state,
    getCalcAssetType: {
      ...state.getCalcAssetType,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetTypeActions.getCalcAssetTypeByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAssetTypeById: {
      ...state.getCalcAssetTypeById,
      isLoading: true,
    },
  }),
  [calcAssetTypeActions.getCalcAssetTypeByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcAssetTypeById: {
      ...state.getCalcAssetTypeById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeActions.getCalcAssetTypeByIdAction.failure.type]: (
    state,
    action: TGetCalcAssetTypeByIdFailureAction,
  ) => ({
    ...state,
    getCalcAssetTypeById: {
      ...state.getCalcAssetTypeById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAssetTypeActions.getCalcAssetTypeByIdAction.initial.type]: (state) => {
    return {
      ...state,
      getCalcAssetTypeById: {
        data: undefined,
        isLoading: false,
        error: undefined,
      },
    }
  },
  //============================================================
  //============================================================
  [calcAssetTypeActions.postCalcAssetTypeAction.call.type]: (state) => ({
    ...state,
    postCalcAssetType: {
      ...state.postCalcAssetType,
      isLoading: true,
    },
  }),
  [calcAssetTypeActions.postCalcAssetTypeAction.success.type]: (
    state,
    action: TPostCalcAssetTypeSuccessAction,
  ) => ({
    ...state,
    postCalcAssetType: {
      ...state.postCalcAssetType,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeActions.postCalcAssetTypeAction.failure.type]: (
    state,
    action: TPostCalcAssetTypeFailureAction,
  ) => ({
    ...state,
    postCalcAssetType: {
      ...state.postCalcAssetType,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  // [calcAssetTypeActions.patchCalcAssetTypeAction.call.type]: (state) => ({
  //   ...state,
  //   patchCalcAssetType: {
  //     ...state.patchCalcAssetType,
  //     isLoading: true,
  //   },
  // }),
  // [calcAssetTypeActions.patchCalcAssetTypeAction.success.type]: (
  //   state,
  //   action: TPatchCalcAssetTypeSuccessAction,
  // ) => ({
  //   ...state,
  //   patchCalcAssetType: {
  //     ...state.patchCalcAssetType,
  //     data: action.payload,
  //     isLoading: false,
  //   },
  // }),
  // [calcAssetTypeActions.patchCalcAssetTypeAction.failure.type]: (
  //   state,
  //   action: TPatchCalcAssetTypeFailureAction,
  // ) => ({
  //   ...state,
  //   patchCalcAssetType: {
  //     ...state.patchCalcAssetType,
  //     error: action.payload.error,
  //     isLoading: false,
  //   },
  // }),
  //============================================================
  //============================================================
  [calcAssetTypeActions.deleteCalcAssetTypeAction.call.type]: (state) => ({
    ...state,
    deleteCalcAssetType: {
      ...state.deleteCalcAssetType,
      isLoading: true,
    },
  }),
  [calcAssetTypeActions.deleteCalcAssetTypeAction.success.type]: (
    state,
    action: TDeleteCalcAssetTypeSuccessAction,
  ) => ({
    ...state,
    deleteCalcAssetType: {
      ...state.deleteCalcAssetType,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetTypeActions.deleteCalcAssetTypeAction.failure.type]: (
    state,
    action: TDeleteCalcAssetTypeFailureAction,
  ) => ({
    ...state,
    deleteCalcAssetType: {
      ...state.deleteCalcAssetType,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
