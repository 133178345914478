import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcAgentFailurePayload,
  TDeleteCalcAgentReqPayload,
  TDeleteCalcAgentSuccessPayload,
  TGetCalcAgentFailurePayload,
  TGetCalcAgentReqPayload,
  TGetCalcAgentSuccessPayload,
  TPostCalcAgentFailurePayload,
  TPostCalcAgentReqPayload,
  TPostCalcAgentSuccessPayload, // TPatchCalcAgentFailurePayload,
  // TPatchCalcAgentReqPayload,
  // TPatchCalcAgentSuccessPayload,
  TGetCalcAgentByIdReqPayload,
  TGetCalcAgentByIdSuccessPayload,
  TGetCalcAgentByIdFailurePayload,
  TPutCalcAgentSuccessPayload,
  TPutCalcAgentFailurePayload,
  TPutCalcAgentReqPayload,
  TGetCalcAgentFilteredByPaymentsSuccessPayload,
  TGetCalcAgentFilteredByPaymentsReqPayload,
} from "./types"

const prefix = "calc-agent"

export const getCalcAgentAction = baseCSF<
  TGetCalcAgentReqPayload,
  TGetCalcAgentSuccessPayload,
  TGetCalcAgentFailurePayload,
  undefined
>(prefix, "get")

export const getCalcAgentFilteredByPaymentsAction = baseCSF<
  TGetCalcAgentFilteredByPaymentsReqPayload,
  TGetCalcAgentFilteredByPaymentsSuccessPayload,
  undefined,
  undefined
>(prefix, "get-filtered-by-payments")

export const getCalcAgentByIdAction = baseCSF<
  TGetCalcAgentByIdReqPayload,
  TGetCalcAgentByIdSuccessPayload,
  TGetCalcAgentByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcAgentAction = baseCSF<
  TPostCalcAgentReqPayload,
  TPostCalcAgentSuccessPayload,
  TPostCalcAgentFailurePayload,
  undefined
>(prefix, "post")

export const putCalcAgentAction = baseCSF<
  TPutCalcAgentReqPayload,
  TPutCalcAgentSuccessPayload,
  TPutCalcAgentFailurePayload,
  undefined
>(prefix, "put")

export const deleteCalcAgentAction = baseCSF<
  TDeleteCalcAgentReqPayload,
  TDeleteCalcAgentSuccessPayload,
  TDeleteCalcAgentFailurePayload,
  undefined
>(prefix, "delete")
