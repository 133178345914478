import { apiRequest } from "Base/provider/sagas"
import * as calcAmortizationGroupActions from "Base/store/amortizationGroup/actions"
import {
  TDeleteCalcAmortizationGroupResp,
  TGetCalcAmortizationGroupByIdResp,
  TGetCalcAmortizationGroupResp, // TPatchCalcAmortizationGroupResp,
  TPostCalcAmortizationGroupResp,
} from "Base/types/provider/api/amortizationGroup"
import { takeLatest, call, put, select } from "redux-saga/effects"

import {
  TDeleteCalcAmortizationGroupReqAction,
  TGetCalcAmortizationGroupByIdReqAction, // TPatchCalcAmortizationGroupReqAction,
  TPostCalcAmortizationGroupReqAction,
} from "./types"

export function* getCalcAmortizationGroupSaga() {
  try {
    const resp: TGetCalcAmortizationGroupResp = yield call(apiRequest, "getCalcAmortizationGroup")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAmortizationGroupActions.getCalcAmortizationGroupAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAmortizationGroupActions.getCalcAmortizationGroupAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcAmortizationGroupByIdSaga({
  payload,
}: TGetCalcAmortizationGroupByIdReqAction) {
  try {
    const resp: TGetCalcAmortizationGroupByIdResp = yield call(
      apiRequest,
      "getCalcAmortizationGroupById",
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcAmortizationGroupSaga({ payload }: TPostCalcAmortizationGroupReqAction) {
  try {
    // const {
    //   calcLead: { getCalcLeadById },
    // } = yield select(({ base }: RootState) => base)

    const resp: TPostCalcAmortizationGroupResp = yield call(
      apiRequest,
      "postCalcAmortizationGroup",
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAmortizationGroupActions.postCalcAmortizationGroupAction.success(resp.data))

    yield put(calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAmortizationGroupActions.postCalcAmortizationGroupAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

// export function* patchCalcAmortizationGroupSaga({ payload }: TPatchCalcAmortizationGroupReqAction) {
//   try {
//     const resp: TPatchCalcAmortizationGroupResp = yield call(apiRequest, "patchCalcAmortizationGroup", {
//       params: payload,
//     })
//     if (!resp.success) throw new Error(resp.detail || "Unknown error")
//     yield put(calcAmortizationGroupActions.patchCalcAmortizationGroupAction.success(resp.data))
//     yield put(calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.success(resp.data))
//   } catch (e: unknown) {
//     yield put(
//       calcAmortizationGroupActions.patchCalcAmortizationGroupAction.failure({
//         error: e?.toString() || "",
//       }),
//     )
//     console.log(e)
//   }
// }

export function* deleteCalcAmortizationGroupSaga({
  payload,
}: TDeleteCalcAmortizationGroupReqAction) {
  try {
    const resp: TDeleteCalcAmortizationGroupResp = yield call(
      apiRequest,
      "deleteCalcAmortizationGroup",
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAmortizationGroupActions.deleteCalcAmortizationGroupAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAmortizationGroupActions.deleteCalcAmortizationGroupAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcAmortizationGroupWatcher() {
  yield takeLatest(
    calcAmortizationGroupActions.getCalcAmortizationGroupAction.call.type,
    getCalcAmortizationGroupSaga,
  )
  yield takeLatest(
    calcAmortizationGroupActions.getCalcAmortizationGroupByIdAction.call.type,
    getCalcAmortizationGroupByIdSaga,
  )
  yield takeLatest(
    calcAmortizationGroupActions.postCalcAmortizationGroupAction.call.type,
    postCalcAmortizationGroupSaga,
  )
  // yield takeLatest(calcAmortizationGroupActions.patchCalcAmortizationGroupAction.call.type, patchCalcAmortizationGroupSaga)
  yield takeLatest(
    calcAmortizationGroupActions.deleteCalcAmortizationGroupAction.call.type,
    deleteCalcAmortizationGroupSaga,
  )
}
