import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcCreditorFailurePayload,
  TDeleteCalcCreditorReqPayload,
  TDeleteCalcCreditorSuccessPayload,
  TGetCalcCreditorFailurePayload,
  TGetCalcCreditorReqPayload,
  TGetCalcCreditorSuccessPayload,
  TPostCalcCreditorFailurePayload,
  TPostCalcCreditorReqPayload,
  TPostCalcCreditorSuccessPayload,
  TGetCalcCreditorByIdReqPayload,
  TGetCalcCreditorByIdSuccessPayload,
  TGetCalcCreditorByIdFailurePayload,
  TPutCalcCreditorSuccessPayload,
  TPutCalcCreditorFailurePayload,
  TPutCalcCreditorReqPayload,
} from "./types"

const prefix = "calc-creditor"

export const getCalcCreditorAction = baseCSF<
  TGetCalcCreditorReqPayload,
  TGetCalcCreditorSuccessPayload,
  TGetCalcCreditorFailurePayload,
  undefined
>(prefix, "get")
export const getCalcCreditorByIdAction = baseCSF<
  TGetCalcCreditorByIdReqPayload,
  TGetCalcCreditorByIdSuccessPayload,
  TGetCalcCreditorByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcCreditorAction = baseCSF<
  TPostCalcCreditorReqPayload,
  TPostCalcCreditorSuccessPayload,
  TPostCalcCreditorFailurePayload,
  undefined
>(prefix, "post")

export const putCalcCreditorAction = baseCSF<
  TPutCalcCreditorReqPayload,
  TPutCalcCreditorSuccessPayload,
  TPutCalcCreditorFailurePayload,
  undefined
>(prefix, "put")

export const deleteCalcCreditorAction = baseCSF<
  TDeleteCalcCreditorReqPayload,
  TDeleteCalcCreditorSuccessPayload,
  TDeleteCalcCreditorFailurePayload,
  undefined
>(prefix, "delete")
