import { Rule } from "antd/es/form"

import { convertLocalStringToNumber } from "./convertLocalStringToNumber"

export const required = {
  required: true,
  message: "Поле обязательно для заполнения",
}

export const nonZeroNumber: Rule = {
  transform: convertLocalStringToNumber,
  min: 1,
  type: "number",
  message: "Сумма должна быть больше или равна 1",
}

export const validatePhoneNumber = (_: any, value: string) => {
  const regex = /^\+7\d{10}$/
  if (!value || regex.test(value)) {
    return Promise.resolve()
  }
  return Promise.reject(new Error("Номер телефона должен начинаться с +7 и содержать 10 цифр"))
}

export const phoneNumber = {
  validator: validatePhoneNumber,
}
