import { apiRequest } from "Base/provider/sagas"
import * as calcAssetActions from "Base/store/asset/actions"
import * as calcProviderActions from "Base/store/provider/actions"
import {
  TDeleteCalcProviderResp,
  TGetCalcProviderResp,
  TPostCalcProviderResp,
  TPatchCalcProviderResp,
  TGetCalcProviderByIdResp,
  TCalcProvider,
  TPutCalcProviderResp,
} from "Base/types/provider/api/provider"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select } from "redux-saga/effects"

import {
  TDeleteCalcProviderReqAction,
  TPostCalcProviderReqAction,
  TPatchCalcProviderReqAction,
  TGetCalcProviderByIdReqAction,
  TPutCalcProviderReqAction,
} from "./types"

export function* getCalcProviderSaga() {
  try {
    const resp: TGetCalcProviderResp = yield call(apiRequest, "getCalcProvider")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcProviderActions.getCalcProviderAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcProviderActions.getCalcProviderAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* getCalcProviderByIdSaga({ payload }: TGetCalcProviderByIdReqAction) {
  try {
    const resp: TGetCalcProviderByIdResp = yield call(apiRequest, "getCalcProviderById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcProviderActions.getCalcProviderByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcProviderActions.getCalcProviderByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* postCalcProviderSaga({ payload }: TPostCalcProviderReqAction) {
  try {
    const resp: TPostCalcProviderResp = yield call(apiRequest, "postCalcProvider", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcProviderActions.postCalcProviderAction.success(resp.data))
    const { getCalcProvider } = yield select(({ base }: RootState) => base.calcProvider)

    yield put(
      calcProviderActions.getCalcProviderAction.success({
        ...getCalcProvider?.data,
        result: [...getCalcProvider.data.result, resp.data.result],
      }),
    )
    yield put(
      calcAssetActions.patchCalcAssetAction.call({
        id: payload.assetId,
        provider: resp.data.result.id,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcProviderActions.postCalcProviderAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* patchCalcProviderSaga({ payload }: TPatchCalcProviderReqAction) {
  try {
    const resp: TPatchCalcProviderResp = yield call(apiRequest, "patchCalcProvider", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcProviderActions.patchCalcProviderAction.success(resp.data))
    const { getCalcProvider } = yield select(({ base }: RootState) => base.calcProvider)
    yield put(
      calcProviderActions.getCalcProviderAction.success({
        ...getCalcProvider.data,
        result: getCalcProvider.data?.result.map((asset: TCalcProvider) => {
          if (asset.id !== resp.data.result.id) return asset
          return resp.data.result
        }),
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcProviderActions.patchCalcProviderAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* putCalcProviderSaga({ payload }: TPutCalcProviderReqAction) {
  try {
    const resp: TPutCalcProviderResp = yield call(apiRequest, "putCalcProvider", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcProviderActions.putCalcProviderAction.success(resp.data))
    const { getCalcProvider } = yield select(({ base }: RootState) => base.calcProvider)
    yield put(
      calcProviderActions.getCalcProviderAction.success({
        ...getCalcProvider.data,
        result: getCalcProvider.data?.result.map((asset: TCalcProvider) => {
          if (asset.id !== resp.data.result.id) return asset
          return resp.data.result
        }),
      }),
    )
    if (payload?.assetId) {
      yield put(
        calcAssetActions.patchCalcAssetAction.call({
          id: payload.assetId,
          provider: resp.data.result.id,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcProviderActions.putCalcProviderAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* deleteCalcProviderSaga({ payload }: TDeleteCalcProviderReqAction) {
  try {
    const resp: TDeleteCalcProviderResp = yield call(apiRequest, "deleteCalcProvider", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcProviderActions.deleteCalcProviderAction.success(resp.data))
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)

    yield put(
      calcProviderActions.getCalcProviderAction.call({ leadId: getCalcLeadById.data.result.id }),
    )
  } catch (e: unknown) {
    yield put(
      calcProviderActions.deleteCalcProviderAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* calcProviderWatcher() {
  yield takeLatest(calcProviderActions.getCalcProviderAction.call.type, getCalcProviderSaga)
  yield takeLatest(calcProviderActions.getCalcProviderByIdAction.call.type, getCalcProviderByIdSaga)
  yield takeLatest(calcProviderActions.postCalcProviderAction.call.type, postCalcProviderSaga)
  yield takeLatest(calcProviderActions.patchCalcProviderAction.call.type, patchCalcProviderSaga)
  yield takeLatest(calcProviderActions.putCalcProviderAction.call.type, putCalcProviderSaga)
  yield takeLatest(calcProviderActions.deleteCalcProviderAction.call.type, deleteCalcProviderSaga)
}
