import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAssetActions from "Base/store/asset/actions"
import {
  TDeleteCalcAssetData,
  TGetCalcAssetData,
  TPostCalcAssetData,
  TPatchCalcAssetData,
  TGetCalcAssetByIdData,
} from "Base/types/provider/api/asset"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcAssetFailureAction,
  TDeleteCalcAssetSuccessAction,
  TGetCalcAssetFailureAction,
  TGetCalcAssetSuccessAction,
  TGetCalcAssetByIdFailureAction,
  TPostCalcAssetFailureAction,
  TPostCalcAssetSuccessAction,
  TPatchCalcAssetFailureAction,
  TPatchCalcAssetSuccessAction,
} from "./types"

export type CalcAssetStateType = {
  getCalcAsset: {
    data?: TGetCalcAssetData
  } & RequestInfoChildType
  getCalcAssetById: {
    data?: TGetCalcAssetByIdData
  } & RequestInfoChildType
  postCalcAsset: {
    data?: TPostCalcAssetData
  } & RequestInfoChildType
  patchCalcAsset: {
    data?: TPatchCalcAssetData
  } & RequestInfoChildType
  deleteCalcAsset: {
    data?: TDeleteCalcAssetData
  } & RequestInfoChildType
}

const initialState: CalcAssetStateType = {
  getCalcAsset: {
    ...RequestInfoChildState,
  },
  getCalcAssetById: {
    ...RequestInfoChildState,
  },
  postCalcAsset: {
    ...RequestInfoChildState,
  },
  patchCalcAsset: {
    ...RequestInfoChildState,
  },
  deleteCalcAsset: {
    ...RequestInfoChildState,
  },
}

export const calcAssetReducer = createReducer(initialState, {
  [calcAssetActions.getCalcAssetAction.call.type]: (state) => ({
    ...state,
    getCalcAsset: {
      ...state.getCalcAsset,
      isLoading: true,
    },
  }),
  [calcAssetActions.getCalcAssetAction.success.type]: (
    state,
    action: TGetCalcAssetSuccessAction,
  ) => ({
    ...state,
    getCalcAsset: {
      ...state.getCalcAsset,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetActions.getCalcAssetAction.failure.type]: (
    state,
    action: TGetCalcAssetFailureAction,
  ) => ({
    ...state,
    getCalcAsset: {
      ...state.getCalcAsset,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAssetActions.getCalcAssetAction.initial.type]: (state) => ({
    ...state,
    getCalcAsset: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetActions.getCalcAssetByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAssetById: {
      ...state.getCalcAssetById,
      isLoading: true,
    },
  }),
  [calcAssetActions.getCalcAssetByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcAssetById: {
      ...state.getCalcAssetById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetActions.getCalcAssetByIdAction.failure.type]: (
    state,
    action: TGetCalcAssetByIdFailureAction,
  ) => ({
    ...state,
    getCalcAssetById: {
      ...state.getCalcAssetById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAssetActions.getCalcAssetByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcAssetById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetActions.postCalcAssetAction.call.type]: (state) => ({
    ...state,
    postCalcAsset: {
      ...state.postCalcAsset,
      isLoading: true,
    },
  }),
  [calcAssetActions.postCalcAssetAction.success.type]: (
    state,
    action: TPostCalcAssetSuccessAction,
  ) => ({
    ...state,
    postCalcAsset: {
      ...state.postCalcAsset,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetActions.postCalcAssetAction.failure.type]: (
    state,
    action: TPostCalcAssetFailureAction,
  ) => ({
    ...state,
    postCalcAsset: {
      ...state.postCalcAsset,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetActions.patchCalcAssetAction.call.type]: (state) => ({
    ...state,
    patchCalcAsset: {
      ...state.patchCalcAsset,
      isLoading: true,
    },
  }),
  [calcAssetActions.patchCalcAssetAction.success.type]: (
    state,
    action: TPatchCalcAssetSuccessAction,
  ) => ({
    ...state,
    patchCalcAsset: {
      ...state.patchCalcAsset,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetActions.patchCalcAssetAction.failure.type]: (
    state,
    action: TPatchCalcAssetFailureAction,
  ) => ({
    ...state,
    patchCalcAsset: {
      ...state.patchCalcAsset,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetActions.deleteCalcAssetAction.call.type]: (state) => ({
    ...state,
    deleteCalcAsset: {
      ...state.deleteCalcAsset,
      isLoading: true,
    },
  }),
  [calcAssetActions.deleteCalcAssetAction.success.type]: (
    state,
    action: TDeleteCalcAssetSuccessAction,
  ) => ({
    ...state,
    deleteCalcAsset: {
      ...state.deleteCalcAsset,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetActions.deleteCalcAssetAction.failure.type]: (
    state,
    action: TDeleteCalcAssetFailureAction,
  ) => ({
    ...state,
    deleteCalcAsset: {
      ...state.deleteCalcAsset,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
