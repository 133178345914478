import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcPaymentScheduleActions from "Base/store/paymentSchedule/actions"
import {
  TDeleteCalcPaymentScheduleData,
  TGetCalcPaymentScheduleData,
  TPostCalcPaymentScheduleData,
  TGetCalcPaymentScheduleByIdData,
  TPostCalcPaymentScheduleRecalculateData,
  TPatchCalcPaymentScheduleData,
} from "Base/types/provider/api/paymentSchedule"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcPaymentScheduleFailureAction,
  TDeleteCalcPaymentScheduleSuccessAction,
  TGetCalcPaymentScheduleFailureAction,
  TGetCalcPaymentScheduleSuccessAction,
  TGetCalcPaymentScheduleByIdFailureAction,
  TPostCalcPaymentScheduleFailureAction,
  TPostCalcPaymentScheduleSuccessAction,
  TPostCalcPaymentScheduleRecalculateFailureAction,
  TPostCalcPaymentScheduleRecalculateSuccessAction,
  TPatchCalcPaymentScheduleSuccessAction,
  TPatchCalcPaymentScheduleFailureAction,
  TGetCalcPaymentScheduleHistorySuccessAction,
  TGetCalcPaymentScheduleHistoryFailureAction,
  TGetCalcPaymentScheduleHistorySuccessPayload,
} from "./types"

export type CalcPaymentScheduleStateType = {
  getCalcPaymentSchedule: {
    data?: TGetCalcPaymentScheduleData
  } & RequestInfoChildType
  getCalcPaymentScheduleById: {
    data?: TGetCalcPaymentScheduleByIdData
  } & RequestInfoChildType
  postCalcPaymentSchedule: {
    data?: TPostCalcPaymentScheduleData
  } & RequestInfoChildType
  patchCalcPaymentSchedule: {
    data?: TPatchCalcPaymentScheduleData
  } & RequestInfoChildType
  postCalcPaymentScheduleRecalculate: {
    data?: TPostCalcPaymentScheduleRecalculateData
  } & RequestInfoChildType
  deleteCalcPaymentSchedule: {
    data?: TDeleteCalcPaymentScheduleData
  } & RequestInfoChildType
  getPaymentsHistory: {
    data?: TGetCalcPaymentScheduleHistorySuccessPayload[]
  } & RequestInfoChildType
}

const initialState: CalcPaymentScheduleStateType = {
  getCalcPaymentSchedule: {
    ...RequestInfoChildState,
  },
  getCalcPaymentScheduleById: {
    ...RequestInfoChildState,
  },
  postCalcPaymentSchedule: {
    ...RequestInfoChildState,
  },
  patchCalcPaymentSchedule: {
    ...RequestInfoChildState,
  },
  postCalcPaymentScheduleRecalculate: {
    ...RequestInfoChildState,
  },
  deleteCalcPaymentSchedule: {
    ...RequestInfoChildState,
  },
  getPaymentsHistory: {
    ...RequestInfoChildState,
    data: [],
  },
}

export const calcPaymentScheduleReducer = createReducer(initialState, {
  [calcPaymentScheduleActions.getCalcPaymentScheduleAction.call.type]: (state) => ({
    ...state,
    getCalcPaymentSchedule: {
      ...state.getCalcPaymentSchedule,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleAction.success.type]: (
    state,
    action: TGetCalcPaymentScheduleSuccessAction,
  ) => ({
    ...state,
    getCalcPaymentSchedule: {
      ...state.getCalcPaymentSchedule,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleAction.failure.type]: (
    state,
    action: TGetCalcPaymentScheduleFailureAction,
  ) => ({
    ...state,
    getCalcPaymentSchedule: {
      ...state.getCalcPaymentSchedule,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.call.type]: (state) => ({
    ...state,
    getCalcPaymentScheduleById: {
      ...state.getCalcPaymentScheduleById,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcPaymentScheduleById: {
      ...state.getCalcPaymentScheduleById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.failure.type]: (
    state,
    action: TGetCalcPaymentScheduleByIdFailureAction,
  ) => ({
    ...state,
    getCalcPaymentScheduleById: {
      ...state.getCalcPaymentScheduleById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcPaymentScheduleById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentScheduleActions.postCalcPaymentScheduleAction.call.type]: (state) => ({
    ...state,
    postCalcPaymentSchedule: {
      ...state.postCalcPaymentSchedule,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.postCalcPaymentScheduleAction.success.type]: (
    state,
    action: TPostCalcPaymentScheduleSuccessAction,
  ) => ({
    ...state,
    postCalcPaymentSchedule: {
      ...state.postCalcPaymentSchedule,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.postCalcPaymentScheduleAction.failure.type]: (
    state,
    action: TPostCalcPaymentScheduleFailureAction,
  ) => ({
    ...state,
    postCalcPaymentSchedule: {
      ...state.postCalcPaymentSchedule,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  // ============================================================
  [calcPaymentScheduleActions.patchCalcPaymentScheduleAction.call.type]: (state) => ({
    ...state,
    patchCalcPaymentSchedule: {
      ...state.patchCalcPaymentSchedule,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.patchCalcPaymentScheduleAction.success.type]: (
    state,
    action: TPatchCalcPaymentScheduleSuccessAction,
  ) => ({
    ...state,
    patchCalcPaymentSchedule: {
      ...state.patchCalcPaymentSchedule,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.patchCalcPaymentScheduleAction.failure.type]: (
    state,
    action: TPatchCalcPaymentScheduleFailureAction,
  ) => ({
    ...state,
    patchCalcPaymentSchedule: {
      ...state.patchCalcPaymentSchedule,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentScheduleActions.postCalcPaymentScheduleRecalculateAction.call.type]: (state) => ({
    ...state,
    postCalcPaymentScheduleRecalculate: {
      ...state.postCalcPaymentScheduleRecalculate,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.postCalcPaymentScheduleRecalculateAction.success.type]: (
    state,
    action: TPostCalcPaymentScheduleRecalculateSuccessAction,
  ) => ({
    ...state,
    postCalcPaymentScheduleRecalculate: {
      ...state.postCalcPaymentScheduleRecalculate,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.postCalcPaymentScheduleRecalculateAction.failure.type]: (
    state,
    action: TPostCalcPaymentScheduleRecalculateFailureAction,
  ) => ({
    ...state,
    postCalcPaymentScheduleRecalculate: {
      ...state.postCalcPaymentScheduleRecalculate,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentScheduleActions.deleteCalcPaymentScheduleAction.call.type]: (state) => ({
    ...state,
    deleteCalcPaymentSchedule: {
      ...state.deleteCalcPaymentSchedule,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.deleteCalcPaymentScheduleAction.success.type]: (
    state,
    action: TDeleteCalcPaymentScheduleSuccessAction,
  ) => ({
    ...state,
    deleteCalcPaymentSchedule: {
      ...state.deleteCalcPaymentSchedule,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.deleteCalcPaymentScheduleAction.failure.type]: (
    state,
    action: TDeleteCalcPaymentScheduleFailureAction,
  ) => ({
    ...state,
    deleteCalcPaymentSchedule: {
      ...state.deleteCalcPaymentSchedule,
      error: action.payload.error,
      isLoading: false,
    },
  }),

  // ============================================================
  [calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.call.type]: (state) => ({
    ...state,
    getPaymentsHistory: {
      ...state.getPaymentsHistory,
      isLoading: true,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.success.type]: (
    state,
    action: TGetCalcPaymentScheduleHistorySuccessAction,
  ) => ({
    ...state,
    getPaymentsHistory: {
      ...state.getPaymentsHistory,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.failure.type]: (
    state,
    action: TGetCalcPaymentScheduleHistoryFailureAction,
  ) => ({
    ...state,
    getPaymentsHistory: {
      ...state.getPaymentsHistory,
      isLoading: false,
      error: action.payload.error,
    },
  }),
})
