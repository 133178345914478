import { apiRequest } from "Base/provider/sagas"
import * as calcAgentActions from "Base/store/agent/actions"
import * as calcAssetActions from "Base/store/asset/actions"
import * as calcPaymentScheduleActions from "Base/store/paymentSchedule/actions"
import * as updateEarlyPaymentDateAction from "Base/store/paymentSchedule/actions"
import * as postCalcPaymentScheduleRecalculateAction from "Base/store/paymentSchedule/actions"
import {
  TDeleteCalcPaymentScheduleResp,
  TGetCalcPaymentScheduleResp,
  TPostCalcPaymentScheduleResp,
  TGetCalcPaymentScheduleByIdResp,
  TPostCalcPaymentScheduleRecalculateResp,
  TPatchCalcPaymentScheduleResp,
} from "Base/types/provider/api/paymentSchedule"
import { TErrorResponse } from "Base/types/provider/response"
import { RootState } from "Starter/store/configureStore"
import { notification } from "antd"
import { takeLatest, call, put, select, take } from "redux-saga/effects"

import {
  TDeleteCalcPaymentScheduleReqAction,
  TPostCalcPaymentScheduleReqAction,
  TGetCalcPaymentScheduleByIdReqAction,
  TGetCalcPaymentScheduleReqAction,
  TPostCalcPaymentScheduleRecalculateReqAction,
  TPatchCalcPaymentScheduleReqAction,
  TGetCalcPaymentScheduleHistoryReqAction,
  TGetCalcPaymentScheduleHistorySuccessPayload, // TGetCalcPaymentScheduleHistoryReqAction,
  // TGetCalcPaymentScheduleHistorySuccessPayload,
} from "./types"

export function* getCalcPaymentScheduleSaga({ payload }: TGetCalcPaymentScheduleReqAction) {
  try {
    const resp: TGetCalcPaymentScheduleResp = yield call(apiRequest, "getCalcPaymentSchedule", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentScheduleActions.getCalcPaymentScheduleAction.success(resp.data))
    if (payload.break) return
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)
    if (
      !resp?.data?.result?.length &&
      getCalcLeadById.data?.result?.leaseData &&
      getCalcLeadById.data?.result?.loanData
    ) {
      yield put(calcPaymentScheduleActions.postCalcPaymentScheduleAction.call(payload))
      yield take(calcPaymentScheduleActions.postCalcPaymentScheduleAction.success)
      yield put(
        calcPaymentScheduleActions.getCalcPaymentScheduleAction.call({
          ...payload,
          break: true,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.getCalcPaymentScheduleAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcPaymentScheduleByIdSaga({ payload }: TGetCalcPaymentScheduleByIdReqAction) {
  try {
    const resp: TGetCalcPaymentScheduleByIdResp = yield call(
      apiRequest,
      "getCalcPaymentScheduleById",
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcPaymentScheduleSaga({ payload }: TPostCalcPaymentScheduleReqAction) {
  try {
    const resp: TPostCalcPaymentScheduleResp = yield call(apiRequest, "postCalcPaymentSchedule", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentScheduleActions.postCalcPaymentScheduleAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.postCalcPaymentScheduleAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* patchCalcPaymentScheduleSaga({ payload }: TPatchCalcPaymentScheduleReqAction) {
  try {
    const resp: TPatchCalcPaymentScheduleResp = yield call(apiRequest, "patchCalcPaymentSchedule", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentScheduleActions.patchCalcPaymentScheduleAction.success(resp.data))
    const { getCalcPaymentSchedule } = yield select(
      ({ base }: RootState) => base.calcPaymentSchedule,
    )
    yield put(
      calcPaymentScheduleActions.getCalcPaymentScheduleAction.success({
        ...getCalcPaymentSchedule,
        result: [resp.data.result],
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.patchCalcPaymentScheduleAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcPaymentScheduleRecalculateSaga({
  payload,
}: TPostCalcPaymentScheduleRecalculateReqAction) {
  try {
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)
    const resp: TPostCalcPaymentScheduleRecalculateResp = yield call(
      apiRequest,
      "postCalcPaymentScheduleRecalculate",
      {
        params: payload?.leadId ? payload : getCalcLeadById?.data?.result?.id,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(
      calcPaymentScheduleActions.postCalcPaymentScheduleRecalculateAction.success(resp.data),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.postCalcPaymentScheduleRecalculateAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcPaymentScheduleSaga({ payload }: TDeleteCalcPaymentScheduleReqAction) {
  try {
    const resp: TDeleteCalcPaymentScheduleResp = yield call(
      apiRequest,
      "deleteCalcPaymentSchedule",
      {
        params: payload,
      },
    )
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcPaymentScheduleActions.deleteCalcPaymentScheduleAction.success(resp.data))
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)

    yield put(
      calcPaymentScheduleActions.getCalcPaymentScheduleAction.call({
        leadId: getCalcLeadById.data.result.id,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.deleteCalcPaymentScheduleAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

function* updateEarlyPaymentDateSaga(
  action: ReturnType<typeof calcPaymentScheduleActions.updateEarlyPaymentDateAction.call>,
): Generator<any, void, TPatchCalcPaymentScheduleResp> {
  try {
    const { id, earlyPaymentDate } = action.payload
    const response: TPatchCalcPaymentScheduleResp = yield call(
      apiRequest,
      "patchEarlyPaymentDate",
      {
        params: { id, earlyPaymentDate },
      },
    )
    if (!response.success) throw new Error(response.detail || "Unknown error")
    yield put(calcPaymentScheduleActions.updateEarlyPaymentDateAction.success(response.data))
    yield put(
      postCalcPaymentScheduleRecalculateAction.postCalcPaymentScheduleRecalculateAction.call({
        leadId: id,
      }),
    )
    yield put(calcAssetActions.getCalcAssetAction.call({ leadId: id }))
    yield put(calcPaymentScheduleActions.getCalcPaymentScheduleAction.call({ leadId: id }))
    yield put(calcAgentActions.getCalcAgentFilteredByPaymentsAction.call({ leadId: id }))
    notification.success({ message: "Дата досрочного погашения изменена" })
  } catch (e: unknown) {
    yield put(
      calcPaymentScheduleActions.updateEarlyPaymentDateAction.failure({
        error: e?.toString() || "",
      }),
    )
  }
}
function* getCalcPaymentScheduleHistorySaga({
  payload,
}: TGetCalcPaymentScheduleHistoryReqAction): Generator<
  any,
  void,
  TGetCalcPaymentScheduleByIdResp | TErrorResponse
> {
  try {
    const response: any = yield call(apiRequest, "getPaymentsHistory", {
      params: payload,
    })

    if ("data" in response) {
      const responseData: TGetCalcPaymentScheduleHistorySuccessPayload = response.data

      yield put(
        calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.success(responseData),
      )
    } else {
      throw new Error(response.detail || "Data not found")
    }
  } catch (error: any) {
    console.error(error, "error")
    yield put(
      calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.failure({
        error: error?.message || "Error fetching payment schedule history",
      }),
    )
  }
}
export function* calcPaymentScheduleWatcher() {
  yield takeLatest(
    calcPaymentScheduleActions.getCalcPaymentScheduleAction.call.type,
    getCalcPaymentScheduleSaga,
  )
  yield takeLatest(
    calcPaymentScheduleActions.getCalcPaymentScheduleByIdAction.call.type,
    getCalcPaymentScheduleByIdSaga,
  )
  yield takeLatest(
    calcPaymentScheduleActions.postCalcPaymentScheduleAction.call.type,
    postCalcPaymentScheduleSaga,
  )
  yield takeLatest(
    calcPaymentScheduleActions.patchCalcPaymentScheduleAction.call.type,
    patchCalcPaymentScheduleSaga,
  )
  yield takeLatest(
    calcPaymentScheduleActions.deleteCalcPaymentScheduleAction.call.type,
    deleteCalcPaymentScheduleSaga,
  )
  yield takeLatest(
    calcPaymentScheduleActions.postCalcPaymentScheduleRecalculateAction.call.type,
    postCalcPaymentScheduleRecalculateSaga,
  )
  yield takeLatest(
    updateEarlyPaymentDateAction.updateEarlyPaymentDateAction.call.type,
    updateEarlyPaymentDateSaga,
  )
  yield takeLatest(
    calcPaymentScheduleActions.getCalcPaymentScheduleHistoryAction.call.type,
    getCalcPaymentScheduleHistorySaga,
  )
}
