import React from "react"
import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import locale from "antd/locale/ru_RU"

import { ConfigProvider } from "antd"
import { PersistGate } from "redux-persist/integration/react"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"

import { App } from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { persistor, store } from "./store/configureStore"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <ConfigProvider locale={locale}>
              <App />
            </ConfigProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
