import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcAssetCurrencyActions from "Base/store/assetCurrency/actions"
import {
  TGetCalcAssetCurrencyByIdData,
  TGetCalcAssetCurrencyData,
} from "Base/types/provider/api/assetCurrency"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TGetCalcAssetCurrencyByIdFailureAction,
  TGetCalcAssetCurrencyFailureAction,
  TGetCalcAssetCurrencySuccessAction,
} from "./types"

export type CalcAssetCurrencyStateType = {
  getCalcAssetCurrency: {
    data?: TGetCalcAssetCurrencyData
  } & RequestInfoChildType
  getCalcAssetCurrencyById: {
    data?: TGetCalcAssetCurrencyByIdData
  } & RequestInfoChildType
}

const initialState: CalcAssetCurrencyStateType = {
  getCalcAssetCurrency: {
    ...RequestInfoChildState,
  },
  getCalcAssetCurrencyById: {
    ...RequestInfoChildState,
  },
}

export const calcAssetCurrencyReducer = createReducer(initialState, {
  [calcAssetCurrencyActions.getCalcAssetCurrencyAction.call.type]: (state) => ({
    ...state,
    getCalcAssetCurrency: {
      ...state.getCalcAssetCurrency,
      isLoading: true,
    },
  }),
  [calcAssetCurrencyActions.getCalcAssetCurrencyAction.success.type]: (
    state,
    action: TGetCalcAssetCurrencySuccessAction,
  ) => ({
    ...state,
    getCalcAssetCurrency: {
      ...state.getCalcAssetCurrency,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetCurrencyActions.getCalcAssetCurrencyAction.failure.type]: (
    state,
    action: TGetCalcAssetCurrencyFailureAction,
  ) => ({
    ...state,
    getCalcAssetCurrency: {
      ...state.getCalcAssetCurrency,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.call.type]: (state) => ({
    ...state,
    getCalcAssetCurrencyById: {
      ...state.getCalcAssetCurrencyById,
      isLoading: true,
    },
  }),
  [calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcAssetCurrencyById: {
      ...state.getCalcAssetCurrencyById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.failure.type]: (
    state,
    action: TGetCalcAssetCurrencyByIdFailureAction,
  ) => ({
    ...state,
    getCalcAssetCurrencyById: {
      ...state.getCalcAssetCurrencyById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.initial.type]: (state) => {
    return {
      ...state,
      getCalcAssetCurrencyById: {
        data: undefined,
        isLoading: false,
        error: undefined,
      },
    }
  },
  //============================================================
  //============================================================
})
