import { apiRequest } from "Base/provider/sagas"
import * as calcAssetTypeActions from "Base/store/assetType/actions"
import {
  TDeleteCalcAssetTypeResp,
  TGetCalcAssetTypeByIdResp,
  TGetCalcAssetTypeResp, // TPatchCalcAssetTypeResp,
  TPostCalcAssetTypeResp,
} from "Base/types/provider/api/assetType"
// import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select } from "redux-saga/effects"

// import * as calcLeadActions from "../assetType/actions"
import {
  TDeleteCalcAssetTypeReqAction,
  TGetCalcAssetTypeByIdReqAction, // TPatchCalcAssetTypeReqAction,
  TPostCalcAssetTypeReqAction,
} from "./types"

export function* getCalcAssetTypeSaga() {
  try {
    const resp: TGetCalcAssetTypeResp = yield call(apiRequest, "getCalcAssetType")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeActions.getCalcAssetTypeAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeActions.getCalcAssetTypeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcAssetTypeByIdSaga({ payload }: TGetCalcAssetTypeByIdReqAction) {
  try {
    const resp: TGetCalcAssetTypeByIdResp = yield call(apiRequest, "getCalcAssetTypeById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeActions.getCalcAssetTypeByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeActions.getCalcAssetTypeByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcAssetTypeSaga({ payload }: TPostCalcAssetTypeReqAction) {
  try {
    // const {
    //   calcLead: { getCalcLeadById },
    // } = yield select(({ base }: RootState) => base)

    const resp: TPostCalcAssetTypeResp = yield call(apiRequest, "postCalcAssetType", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeActions.postCalcAssetTypeAction.success(resp.data))

    yield put(calcAssetTypeActions.getCalcAssetTypeByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeActions.postCalcAssetTypeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

// export function* patchCalcAssetTypeSaga({ payload }: TPatchCalcAssetTypeReqAction) {
//   try {
//     const resp: TPatchCalcAssetTypeResp = yield call(apiRequest, "patchCalcAssetType", {
//       params: payload,
//     })
//     if (!resp.success) throw new Error(resp.detail || "Unknown error")
//     yield put(calcAssetTypeActions.patchCalcAssetTypeAction.success(resp.data))
//     yield put(calcAssetTypeActions.getCalcAssetTypeByIdAction.success(resp.data))
//   } catch (e: unknown) {
//     yield put(
//       calcAssetTypeActions.patchCalcAssetTypeAction.failure({
//         error: e?.toString() || "",
//       }),
//     )
//     console.log(e)
//   }
// }

export function* deleteCalcAssetTypeSaga({ payload }: TDeleteCalcAssetTypeReqAction) {
  try {
    const resp: TDeleteCalcAssetTypeResp = yield call(apiRequest, "deleteCalcAssetType", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeActions.deleteCalcAssetTypeAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeActions.deleteCalcAssetTypeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcAssetTypeWatcher() {
  yield takeLatest(calcAssetTypeActions.getCalcAssetTypeAction.call.type, getCalcAssetTypeSaga)
  yield takeLatest(
    calcAssetTypeActions.getCalcAssetTypeByIdAction.call.type,
    getCalcAssetTypeByIdSaga,
  )
  yield takeLatest(calcAssetTypeActions.postCalcAssetTypeAction.call.type, postCalcAssetTypeSaga)
  // yield takeLatest(calcAssetTypeActions.patchCalcAssetTypeAction.call.type, patchCalcAssetTypeSaga)
  yield takeLatest(
    calcAssetTypeActions.deleteCalcAssetTypeAction.call.type,
    deleteCalcAssetTypeSaga,
  )
}
