export function convertLocalStringToNumber(num: string | undefined, locale = "ru") {
  if (!num) return 0
  const { format } = new Intl.NumberFormat(locale)
  const [, decimalSign] = /^0(.)1$/.exec(format(0.1)) || ([] as string[])

  const result = +(num || "")
    .replace(new RegExp(`[^${decimalSign} | ${"."}\\d]`, "g"), "")
    .replace(decimalSign, ".")

  return result
}
