import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import {
  TDeleteCalcPaymentOtherData,
  TGetCalcPaymentOtherData,
  TPostCalcPaymentOtherData,
  TPatchCalcPaymentOtherData,
  TGetCalcPaymentOtherByIdData,
  TPutCalcPaymentOtherData,
} from "Base/types/provider/api/paymentOther"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcPaymentOtherFailureAction,
  TDeleteCalcPaymentOtherSuccessAction,
  TGetCalcPaymentOtherFailureAction,
  TGetCalcPaymentOtherSuccessAction,
  TGetCalcPaymentOtherByIdFailureAction,
  TPostCalcPaymentOtherFailureAction,
  TPostCalcPaymentOtherSuccessAction,
  TPatchCalcPaymentOtherFailureAction,
  TPatchCalcPaymentOtherSuccessAction,
  TPutCalcPaymentOtherSuccessAction,
  TPutCalcPaymentOtherFailureAction,
  TPutCalcPaymentOtherByAgentIdFailureAction,
  TDeleteCalcPaymentOtherByPropertiesSuccessAction,
  TDeleteCalcPaymentOtherByPropertiesFailureAction,
} from "./types"

export type CalcPaymentOtherStateType = {
  getCalcPaymentOther: {
    data?: TGetCalcPaymentOtherData
  } & RequestInfoChildType
  getCalcPaymentOtherById: {
    data?: TGetCalcPaymentOtherByIdData
  } & RequestInfoChildType
  postCalcPaymentOther: {
    data?: TPostCalcPaymentOtherData
  } & RequestInfoChildType
  patchCalcPaymentOther: {
    data?: TPatchCalcPaymentOtherData
  } & RequestInfoChildType
  putCalcPaymentOther: {
    data?: TPutCalcPaymentOtherData
  } & RequestInfoChildType
  putCalcPaymentOtherByAgentId: RequestInfoChildType
  deleteCalcPaymentOther: {
    data?: TDeleteCalcPaymentOtherData
  } & RequestInfoChildType
  deleteCalcPaymentOtherByProperties: {
    data?: TDeleteCalcPaymentOtherData
  } & RequestInfoChildType
}

const initialState: CalcPaymentOtherStateType = {
  getCalcPaymentOther: {
    ...RequestInfoChildState,
  },
  getCalcPaymentOtherById: {
    ...RequestInfoChildState,
  },
  postCalcPaymentOther: {
    ...RequestInfoChildState,
  },
  patchCalcPaymentOther: {
    ...RequestInfoChildState,
  },
  putCalcPaymentOther: {
    ...RequestInfoChildState,
  },
  putCalcPaymentOtherByAgentId: {
    ...RequestInfoChildState,
  },
  deleteCalcPaymentOther: {
    ...RequestInfoChildState,
  },
  deleteCalcPaymentOtherByProperties: {
    ...RequestInfoChildState,
  },
}

export const calcPaymentOtherReducer = createReducer(initialState, {
  [calcPaymentOtherActions.getCalcPaymentOtherAction.call.type]: (state) => ({
    ...state,
    getCalcPaymentOther: {
      ...state.getCalcPaymentOther,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.getCalcPaymentOtherAction.success.type]: (
    state,
    action: TGetCalcPaymentOtherSuccessAction,
  ) => ({
    ...state,
    getCalcPaymentOther: {
      ...state.getCalcPaymentOther,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.getCalcPaymentOtherAction.failure.type]: (
    state,
    action: TGetCalcPaymentOtherFailureAction,
  ) => ({
    ...state,
    getCalcPaymentOther: {
      ...state.getCalcPaymentOther,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.getCalcPaymentOtherByIdAction.call.type]: (state) => ({
    ...state,
    getCalcPaymentOtherById: {
      ...state.getCalcPaymentOtherById,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.getCalcPaymentOtherByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcPaymentOtherById: {
      ...state.getCalcPaymentOtherById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.getCalcPaymentOtherByIdAction.failure.type]: (
    state,
    action: TGetCalcPaymentOtherByIdFailureAction,
  ) => ({
    ...state,
    getCalcPaymentOtherById: {
      ...state.getCalcPaymentOtherById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.getCalcPaymentOtherByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcPaymentOtherById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.postCalcPaymentOtherAction.call.type]: (state) => ({
    ...state,
    postCalcPaymentOther: {
      ...state.postCalcPaymentOther,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.postCalcPaymentOtherAction.success.type]: (
    state,
    action: TPostCalcPaymentOtherSuccessAction,
  ) => ({
    ...state,
    postCalcPaymentOther: {
      ...state.postCalcPaymentOther,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.postCalcPaymentOtherAction.failure.type]: (
    state,
    action: TPostCalcPaymentOtherFailureAction,
  ) => ({
    ...state,
    postCalcPaymentOther: {
      ...state.postCalcPaymentOther,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.patchCalcPaymentOtherAction.call.type]: (state) => ({
    ...state,
    patchCalcPaymentOther: {
      ...state.patchCalcPaymentOther,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.patchCalcPaymentOtherAction.success.type]: (
    state,
    action: TPatchCalcPaymentOtherSuccessAction,
  ) => ({
    ...state,
    patchCalcPaymentOther: {
      ...state.patchCalcPaymentOther,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.patchCalcPaymentOtherAction.failure.type]: (
    state,
    action: TPatchCalcPaymentOtherFailureAction,
  ) => ({
    ...state,
    patchCalcPaymentOther: {
      ...state.patchCalcPaymentOther,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.putCalcPaymentOtherAction.call.type]: (state) => ({
    ...state,
    putCalcPaymentOther: {
      ...state.putCalcPaymentOther,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.putCalcPaymentOtherAction.success.type]: (
    state,
    action: TPutCalcPaymentOtherSuccessAction,
  ) => ({
    ...state,
    putCalcPaymentOther: {
      ...state.putCalcPaymentOther,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.putCalcPaymentOtherAction.failure.type]: (
    state,
    action: TPutCalcPaymentOtherFailureAction,
  ) => ({
    ...state,
    putCalcPaymentOther: {
      ...state.putCalcPaymentOther,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.putCalcPaymentOtherByAgentIdAction.call.type]: (state) => ({
    ...state,
    putCalcPaymentOtherByAgentId: {
      ...state.putCalcPaymentOtherByAgentId,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.putCalcPaymentOtherByAgentIdAction.success.type]: (state) => ({
    ...state,
    putCalcPaymentOtherByAgentId: {
      ...state.putCalcPaymentOtherByAgentId,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.putCalcPaymentOtherByAgentIdAction.failure.type]: (
    state,
    action: TPutCalcPaymentOtherByAgentIdFailureAction,
  ) => ({
    ...state,
    putCalcPaymentOtherByAgentId: {
      ...state.putCalcPaymentOtherByAgentId,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.deleteCalcPaymentOtherAction.call.type]: (state) => ({
    ...state,
    deleteCalcPaymentOther: {
      ...state.deleteCalcPaymentOther,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.deleteCalcPaymentOtherAction.success.type]: (
    state,
    action: TDeleteCalcPaymentOtherSuccessAction,
  ) => ({
    ...state,
    deleteCalcPaymentOther: {
      ...state.deleteCalcPaymentOther,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.deleteCalcPaymentOtherAction.failure.type]: (
    state,
    action: TDeleteCalcPaymentOtherFailureAction,
  ) => ({
    ...state,
    deleteCalcPaymentOther: {
      ...state.deleteCalcPaymentOther,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcPaymentOtherActions.deleteCalcPaymentOtherByPropertiesAction.call.type]: (state) => ({
    ...state,
    deleteCalcPaymentOtherByProperties: {
      ...state.deleteCalcPaymentOtherByProperties,
      isLoading: true,
    },
  }),
  [calcPaymentOtherActions.deleteCalcPaymentOtherByPropertiesAction.success.type]: (
    state,
    action: TDeleteCalcPaymentOtherByPropertiesSuccessAction,
  ) => ({
    ...state,
    deleteCalcPaymentOtherByProperties: {
      ...state.deleteCalcPaymentOtherByProperties,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcPaymentOtherActions.deleteCalcPaymentOtherByPropertiesAction.failure.type]: (
    state,
    action: TDeleteCalcPaymentOtherByPropertiesFailureAction,
  ) => ({
    ...state,
    deleteCalcPaymentOtherByProperties: {
      ...state.deleteCalcPaymentOtherByProperties,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
