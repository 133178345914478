import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcLesseeFailurePayload,
  TDeleteCalcLesseeReqPayload,
  TDeleteCalcLesseeSuccessPayload,
  TGetCalcLesseeByIdFailurePayload,
  TGetCalcLesseeByIdReqPayload,
  TGetCalcLesseeByIdSuccessPayload,
  TGetCalcLesseeFailurePayload,
  TGetCalcLesseeReqPayload,
  TGetCalcLesseeSuccessPayload,
  TPatchCalcLesseeFailurePayload,
  TPatchCalcLesseeReqPayload,
  TPatchCalcLesseeSuccessPayload,
  TPostCalcLesseeFailurePayload,
  TPostCalcLesseeReqPayload,
  TPostCalcLesseeSuccessPayload,
} from "./types"

const prefix = "calc-lessee"

export const getCalcLesseeAction = baseCSF<
  TGetCalcLesseeReqPayload,
  TGetCalcLesseeSuccessPayload,
  TGetCalcLesseeFailurePayload,
  undefined
>(prefix, "get")
export const getCalcLesseeByIdAction = baseCSF<
  TGetCalcLesseeByIdReqPayload,
  TGetCalcLesseeByIdSuccessPayload,
  TGetCalcLesseeByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcLesseeAction = baseCSF<
  TPostCalcLesseeReqPayload,
  TPostCalcLesseeSuccessPayload,
  TPostCalcLesseeFailurePayload,
  undefined
>(prefix, "post")

export const patchCalcLesseeAction = baseCSF<
  TPatchCalcLesseeReqPayload,
  TPatchCalcLesseeSuccessPayload,
  TPatchCalcLesseeFailurePayload,
  undefined
>(prefix, "patch")

export const deleteCalcLesseeAction = baseCSF<
  TDeleteCalcLesseeReqPayload,
  TDeleteCalcLesseeSuccessPayload,
  TDeleteCalcLesseeFailurePayload,
  undefined
>(prefix, "delete")
