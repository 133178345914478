import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcAmortizationGroupFailurePayload,
  TDeleteCalcAmortizationGroupReqPayload,
  TDeleteCalcAmortizationGroupSuccessPayload,
  TGetCalcAmortizationGroupByIdFailurePayload,
  TGetCalcAmortizationGroupByIdReqPayload,
  TGetCalcAmortizationGroupByIdSuccessPayload,
  TGetCalcAmortizationGroupFailurePayload,
  TGetCalcAmortizationGroupReqPayload,
  TGetCalcAmortizationGroupSuccessPayload, // TPatchCalcAmortizationGroupFailurePayload,
  // TPatchCalcAmortizationGroupReqPayload,
  // TPatchCalcAmortizationGroupSuccessPayload,
  TPostCalcAmortizationGroupFailurePayload,
  TPostCalcAmortizationGroupReqPayload,
  TPostCalcAmortizationGroupSuccessPayload,
} from "./types"

const prefix = "calc-amortization-group"

export const getCalcAmortizationGroupAction = baseCSF<
  TGetCalcAmortizationGroupReqPayload,
  TGetCalcAmortizationGroupSuccessPayload,
  TGetCalcAmortizationGroupFailurePayload,
  undefined
>(prefix, "get")
export const getCalcAmortizationGroupByIdAction = baseCSF<
  TGetCalcAmortizationGroupByIdReqPayload,
  TGetCalcAmortizationGroupByIdSuccessPayload,
  TGetCalcAmortizationGroupByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcAmortizationGroupAction = baseCSF<
  TPostCalcAmortizationGroupReqPayload,
  TPostCalcAmortizationGroupSuccessPayload,
  TPostCalcAmortizationGroupFailurePayload,
  undefined
>(prefix, "post")

// export const patchCalcAmortizationGroupAction = baseCSF<
//   TPatchCalcAmortizationGroupReqPayload,
//   TPatchCalcAmortizationGroupSuccessPayload,
//   TPatchCalcAmortizationGroupFailurePayload,
//   undefined
// >(prefix, "patch")

export const deleteCalcAmortizationGroupAction = baseCSF<
  TDeleteCalcAmortizationGroupReqPayload,
  TDeleteCalcAmortizationGroupSuccessPayload,
  TDeleteCalcAmortizationGroupFailurePayload,
  undefined
>(prefix, "delete")
