import React from "react"
import { NavLink } from "react-router-dom"

import { Content } from "antd/es/layout/layout"

import { Button, Space } from "antd"

type TStageLayoutProps = {
  children: React.ReactNode
  stageTitle: string
  nextLink: string
  backLink?: string
  backButtonTitle?: string
  isButtonsDisabled?: boolean
}

export const StageLayout = ({
  children,
  stageTitle,
  nextLink,
  backLink,
  backButtonTitle = "Далее",
  isButtonsDisabled = false,
}: TStageLayoutProps) => {
  return (
    <Content>
      {children}
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "min-content",
        }}
      >
        {backLink ? (
          <NavLink to={backLink}>
            <Button disabled={isButtonsDisabled}>Назад</Button>
          </NavLink>
        ) : (
          <div />
        )}
        <h4>{stageTitle}</h4>
        <NavLink to={nextLink}>
          <Button disabled={isButtonsDisabled}>{backButtonTitle}</Button>
        </NavLink>
      </Space>
    </Content>
  )
}
