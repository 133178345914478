import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcPaymentScheduleParams,
  TDeleteCalcPaymentScheduleReq,
  TDeleteCalcPaymentScheduleResp,
  TGetCalcPaymentScheduleByIdParams,
  TGetCalcPaymentScheduleByIdReq,
  TGetCalcPaymentScheduleByIdResp,
  TGetCalcPaymentScheduleParams,
  TGetCalcPaymentScheduleReq,
  TGetCalcPaymentScheduleResp,
  TPatchCalcPaymentScheduleParams,
  TPatchCalcPaymentScheduleReq,
  TPatchCalcPaymentScheduleResp,
  TPostCalcPaymentScheduleParams,
  TPostCalcPaymentScheduleRecalculateParams,
  TPostCalcPaymentScheduleRecalculateReq,
  TPostCalcPaymentScheduleRecalculateResp,
  TPostCalcPaymentScheduleReq,
  TPostCalcPaymentScheduleResp,
} from "Base/types/provider/api/paymentSchedule"

export default class PaymentScheduleProvider extends ApiProviderDefault {
  static async deleteCalcPaymentSchedule(parameters: {
    params: TDeleteCalcPaymentScheduleParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/payment/schedule/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcPaymentScheduleReq, TDeleteCalcPaymentScheduleResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcPaymentScheduleById(parameters: {
    params: TGetCalcPaymentScheduleByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/payment/schedule/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcPaymentScheduleByIdReq, TGetCalcPaymentScheduleByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcPaymentSchedule(parameters: {
    params: TGetCalcPaymentScheduleParams
    headers: Headers
  }) {
    const { headers } = parameters
    const path = `/calc/payment/schedule`
    const body = undefined
    const query = parameters.params
    return this.request<TGetCalcPaymentScheduleReq, TGetCalcPaymentScheduleResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcPaymentSchedule(parameters: {
    params: TPostCalcPaymentScheduleParams
    headers: Headers
  }) {
    const {
      params: { leadId },
      headers,
    } = parameters
    const path = "/calc/payment/schedule"
    const query = { leadId }
    const body = undefined
    return this.request<TPostCalcPaymentScheduleReq, TPostCalcPaymentScheduleResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async patchCalcPaymentSchedule(parameters: {
    params: TPatchCalcPaymentScheduleParams
    headers: Headers
  }) {
    const { headers, params: body } = parameters
    const path = "/calc/payment/schedule/" + body.id
    const query = undefined
    return this.request<TPatchCalcPaymentScheduleReq, TPatchCalcPaymentScheduleResp>(
      path,
      "PATCH",
      query,
      body,
      headers,
    )
  }

  static async postCalcPaymentScheduleRecalculate(parameters: {
    params: TPostCalcPaymentScheduleRecalculateParams
    headers: Headers
  }) {
    const {
      params: { leadId },
      headers,
    } = parameters
    const path = "/calc/payment/schedule/recalculate"
    const query = { leadId }
    const body = undefined
    return this.request<
      TPostCalcPaymentScheduleRecalculateReq,
      TPostCalcPaymentScheduleRecalculateResp
    >(path, "POST", query, body, headers)
  }

  static async patchEarlyPaymentDate(parameters: {
    params: { id: string | number; earlyPaymentDate: string }
    headers: Headers
  }) {
    const {
      params: { id, earlyPaymentDate },
      headers,
    } = parameters
    const path = `/calc/lead/${id}/early-payment`
    const body = { earlyPaymentDate }
    const query = undefined
    return this.request<{ earlyPaymentDate: string }, any>(path, "PATCH", query, body, headers)
  }

  static async getPaymentsHistory(parameters: { params: { id: string }; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/payment/schedule/history/${id}`
    const body = undefined
    const query = undefined

    return this.request<TGetCalcPaymentScheduleByIdParams, TGetCalcPaymentScheduleByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }
}
