import { apiRequest } from "Base/provider/sagas"
import * as calcIndClassActions from "Base/store/indClass/actions"
import {
  TDeleteCalcIndClassResp,
  TGetCalcIndClassResp,
  TPostCalcIndClassResp,
  TGetCalcIndClassByIdResp,
} from "Base/types/provider/api/indClass"
import { takeLatest, call, put } from "redux-saga/effects"

import {
  TDeleteCalcIndClassReqAction,
  TPostCalcIndClassReqAction,
  TGetCalcIndClassByIdReqAction,
} from "./types"

export function* getCalcIndClassSaga() {
  try {
    const resp: TGetCalcIndClassResp = yield call(apiRequest, "getCalcIndClass")
    if (!resp?.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcIndClassActions.getCalcIndClassAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcIndClassActions.getCalcIndClassAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcIndClassByIdSaga({ payload }: TGetCalcIndClassByIdReqAction) {
  try {
    const resp: TGetCalcIndClassByIdResp = yield call(apiRequest, "getCalcIndClassById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcIndClassActions.getCalcIndClassByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcIndClassActions.getCalcIndClassByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcIndClassSaga({ payload }: TPostCalcIndClassReqAction) {
  try {
    const resp: TPostCalcIndClassResp = yield call(apiRequest, "postCalcIndClass", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcIndClassActions.postCalcIndClassAction.success(resp.data))
    yield put(calcIndClassActions.getCalcIndClassByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcIndClassActions.postCalcIndClassAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcIndClassSaga({ payload }: TDeleteCalcIndClassReqAction) {
  try {
    const resp: TDeleteCalcIndClassResp = yield call(apiRequest, "deleteCalcIndClass", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcIndClassActions.deleteCalcIndClassAction.success(resp.data))
    yield put(calcIndClassActions.getCalcIndClassAction.call({}))
  } catch (e: unknown) {
    yield put(
      calcIndClassActions.deleteCalcIndClassAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcIndClassWatcher() {
  yield takeLatest(calcIndClassActions.getCalcIndClassAction.call.type, getCalcIndClassSaga)
  yield takeLatest(calcIndClassActions.getCalcIndClassByIdAction.call.type, getCalcIndClassByIdSaga)
  yield takeLatest(calcIndClassActions.postCalcIndClassAction.call.type, postCalcIndClassSaga)
  yield takeLatest(calcIndClassActions.deleteCalcIndClassAction.call.type, deleteCalcIndClassSaga)
}
