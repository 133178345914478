import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import * as calcAssetActions from "Base/store/asset/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { required } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { DatePicker, DatePickerProps, Form } from "antd"
import { format } from "date-fns"
import dayjs from "dayjs"
import "dayjs/locale/ru"

const { Item } = Form

export const ExploitationForm = ({
  asset,
  setIsRequiredFieldsCompleted,
  index,
}: {
  asset: TCalcAsset
  setIsRequiredFieldsCompleted: (...args: any[]) => void
  index: number
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    const isUncomplited = !values?.commissionDate || values?.commissionDate === 0
    setIsRequiredFieldsCompleted(index, "exploitation", isUncomplited)
  }, [values?.commissionDate])

  const {
    base: {
      calcLead: { getCalcLeadById },
      calcAsset: { getCalcAsset, patchCalcAsset },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    asset?.commissionDate &&
      form.setFieldValue("commissionDate", dayjs(asset.commissionDate, "YYYY-MM-DD"))
  }, [asset])

  const isDisableForm = useMemo(() => {
    return getCalcLeadById.isLoading || getCalcAsset.isLoading || patchCalcAsset.isLoading
  }, [getCalcLeadById, getCalcAsset, patchCalcAsset])

  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    dispatch(
      calcAssetActions.patchCalcAssetAction.call({
        id: asset.id,
        commissionDate: format(new Date(form.getFieldValue("commissionDate")), "yyyy-MM-dd"),
      }),
    )
  }

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Эксплуатация
      </Title>
      <Form disabled={isDisableForm} name='mixes' form={form} layout={"vertical"}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "24px",
          }}
        >
          <Item
            name='commissionDate'
            required
            rules={[required]}
            label='Год и месяц ввода в эксплуатацию'
          >
            <DatePicker
              style={{ width: "100%" }}
              size='large'
              onChange={onChangeDate}
              picker='month'
              format='MM-YYYY'
            />
          </Item>
          <div />
        </div>
      </Form>
    </>
  )
}
