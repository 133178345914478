import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcCreditorActions from "Base/store/creditor/actions"
import {
  TDeleteCalcCreditorData,
  TGetCalcCreditorData,
  TPostCalcCreditorData,
  TGetCalcCreditorByIdData,
  TPutCalcCreditorData,
} from "Base/types/provider/api/creditor"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcCreditorFailureAction,
  TDeleteCalcCreditorSuccessAction,
  TGetCalcCreditorFailureAction,
  TGetCalcCreditorSuccessAction,
  TGetCalcCreditorByIdFailureAction,
  TPostCalcCreditorFailureAction,
  TPostCalcCreditorSuccessAction,
  TPutCalcCreditorFailureAction,
  TPutCalcCreditorSuccessAction,
} from "./types"

export type CalcCreditorStateType = {
  getCalcCreditor: {
    data?: TGetCalcCreditorData
  } & RequestInfoChildType
  getCalcCreditorById: {
    data?: TGetCalcCreditorByIdData
  } & RequestInfoChildType
  postCalcCreditor: {
    data?: TPostCalcCreditorData
  } & RequestInfoChildType
  putCalcCreditor: {
    data?: TPutCalcCreditorData
  } & RequestInfoChildType
  deleteCalcCreditor: {
    data?: TDeleteCalcCreditorData
  } & RequestInfoChildType
}

const initialState: CalcCreditorStateType = {
  getCalcCreditor: {
    ...RequestInfoChildState,
  },
  getCalcCreditorById: {
    ...RequestInfoChildState,
  },
  postCalcCreditor: {
    ...RequestInfoChildState,
  },
  putCalcCreditor: {
    ...RequestInfoChildState,
  },
  deleteCalcCreditor: {
    ...RequestInfoChildState,
  },
}

export const calcCreditorReducer = createReducer(initialState, {
  [calcCreditorActions.getCalcCreditorAction.call.type]: (state) => ({
    ...state,
    getCalcCreditor: {
      ...state.getCalcCreditor,
      isLoading: true,
    },
  }),
  [calcCreditorActions.getCalcCreditorAction.success.type]: (
    state,
    action: TGetCalcCreditorSuccessAction,
  ) => ({
    ...state,
    getCalcCreditor: {
      ...state.getCalcCreditor,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcCreditorActions.getCalcCreditorAction.failure.type]: (
    state,
    action: TGetCalcCreditorFailureAction,
  ) => ({
    ...state,
    getCalcCreditor: {
      ...state.getCalcCreditor,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcCreditorActions.getCalcCreditorByIdAction.call.type]: (state) => ({
    ...state,
    getCalcCreditorById: {
      ...state.getCalcCreditorById,
      isLoading: true,
    },
  }),
  [calcCreditorActions.getCalcCreditorByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcCreditorById: {
      ...state.getCalcCreditorById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcCreditorActions.getCalcCreditorByIdAction.failure.type]: (
    state,
    action: TGetCalcCreditorByIdFailureAction,
  ) => ({
    ...state,
    getCalcCreditorById: {
      ...state.getCalcCreditorById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcCreditorActions.getCalcCreditorByIdAction.initial.type]: (state) => ({
    ...state,
    getCalcCreditorById: {
      data: undefined,
      error: undefined,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcCreditorActions.postCalcCreditorAction.call.type]: (state) => ({
    ...state,
    postCalcCreditor: {
      ...state.postCalcCreditor,
      isLoading: true,
    },
  }),
  [calcCreditorActions.postCalcCreditorAction.success.type]: (
    state,
    action: TPostCalcCreditorSuccessAction,
  ) => ({
    ...state,
    postCalcCreditor: {
      ...state.postCalcCreditor,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcCreditorActions.postCalcCreditorAction.failure.type]: (
    state,
    action: TPostCalcCreditorFailureAction,
  ) => ({
    ...state,
    postCalcCreditor: {
      ...state.postCalcCreditor,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcCreditorActions.putCalcCreditorAction.call.type]: (state) => ({
    ...state,
    putCalcCreditor: {
      ...state.putCalcCreditor,
      isLoading: true,
    },
  }),
  [calcCreditorActions.putCalcCreditorAction.success.type]: (
    state,
    action: TPutCalcCreditorSuccessAction,
  ) => ({
    ...state,
    putCalcCreditor: {
      ...state.putCalcCreditor,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcCreditorActions.putCalcCreditorAction.failure.type]: (
    state,
    action: TPutCalcCreditorFailureAction,
  ) => ({
    ...state,
    putCalcCreditor: {
      ...state.putCalcCreditor,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcCreditorActions.deleteCalcCreditorAction.call.type]: (state) => ({
    ...state,
    deleteCalcCreditor: {
      ...state.deleteCalcCreditor,
      isLoading: true,
    },
  }),
  [calcCreditorActions.deleteCalcCreditorAction.success.type]: (
    state,
    action: TDeleteCalcCreditorSuccessAction,
  ) => ({
    ...state,
    deleteCalcCreditor: {
      ...state.deleteCalcCreditor,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcCreditorActions.deleteCalcCreditorAction.failure.type]: (
    state,
    action: TDeleteCalcCreditorFailureAction,
  ) => ({
    ...state,
    deleteCalcCreditor: {
      ...state.deleteCalcCreditor,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
