import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Title from "antd/es/typography/Title"

import { ListFormActions } from "Base/components/ListFormActions"
import * as calcPaymentOtherActions from "Base/store/paymentOther/actions"
import { TCalcAsset } from "Base/types/provider/api/asset"
import { TCalcPaymentOther } from "Base/types/provider/api/paymentOther"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { nonZeroNumber, required } from "Base/utils/validation"
import { RootState } from "Starter/store/configureStore"
import { Form, Input, InputNumber } from "antd"
import { format } from "date-fns"
import "dayjs/locale/ru"

const { Item } = Form

const reasonName = "reason"
const amountName = "amount"

const AdditionalExpensesItem = ({
  paymentOther,
  onDeleteClick,
  onAddClick,
  onUpdate,
  isCreateMode = false,
}: {
  paymentOther?: TCalcPaymentOther
  onDeleteClick?: (index: number) => void
  onAddClick?: () => void
  onUpdate?: () => Promise<void>
  isCreateMode?: boolean
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const isReadonly = !isEdit && !isCreateMode

  const onLocalEditClick = () => {
    if (isEdit) {
      onUpdate &&
        onUpdate()
          .then(() => {
            setIsEdit(false)
          })
          .catch(() => setIsEdit(true))
    } else {
      setIsEdit(true)
    }
  }
  return (
    <div
      style={{
        display: "flex",
        gap: "24px",
        alignItems: "center",
        borderBottom: isCreateMode ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "1fr 1fr auto",
          columnGap: "24px",
        }}
      >
        <Item
          required={false}
          name={reasonName + (paymentOther?.id || "")}
          label='Статья расхода'
          style={{ margin: 0 }}
          rules={[required]}
        >
          <Input disabled={isReadonly} size='large' placeholder='Введите статью' />
        </Item>

        <Item
          required={false}
          label='Сумма'
          style={{ margin: 0 }}
          name={amountName + (paymentOther?.id || "")}
          rules={[nonZeroNumber]}
        >
          <InputNumber
            disabled={isReadonly}
            formatter={convertNumberToLocalString}
            parser={convertLocalStringToNumber}
            size='large'
            style={{ width: "100%" }}
          />
        </Item>

        <ListFormActions
          onLocalEditClick={onLocalEditClick}
          isEdit={isEdit}
          isCreateMode={isCreateMode}
          onAddClick={onAddClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </div>
  )
}

export const AdditionalExpensesForm = ({ asset }: { asset: TCalcAsset }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcPaymentOther: { getCalcPaymentOther, putCalcPaymentOther },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    getCalcPaymentOther?.data?.result.forEach((paymentOther) => {
      form.setFieldValue(reasonName + paymentOther.id, paymentOther.reason)
      form.setFieldValue(amountName + paymentOther.id, paymentOther.amount)
    })
  }, [getCalcPaymentOther?.data])

  const isDisableForm = useMemo(() => {
    return (
      getCalcLeadById.isLoading || getCalcPaymentOther.isLoading || putCalcPaymentOther.isLoading
    )
  }, [getCalcLeadById, getCalcPaymentOther, putCalcPaymentOther])

  const paymentOtherAsset: TCalcPaymentOther[] = useMemo(() => {
    return (
      getCalcPaymentOther?.data?.result.filter(
        (paymentOther) => paymentOther.assetId === asset.id,
      ) || []
    )
  }, [getCalcLeadById, getCalcPaymentOther])

  const onCreate = () => {
    if (
      !getCalcLeadById?.data?.result.id ||
      !form.getFieldValue(amountName) ||
      !form.getFieldValue(reasonName)
    )
      return
    dispatch(
      calcPaymentOtherActions.postCalcPaymentOtherAction.call({
        leadId: getCalcLeadById?.data?.result.id,
        asset: asset.id,
        amount: form.getFieldValue(amountName),
        reason: form.getFieldValue(reasonName),
        date: format(new Date(), "yyyy-MM-dd"),
      }),
    )
    form.setFieldValue(amountName, undefined)
    form.setFieldValue(reasonName, undefined)
  }

  const onChange = async (paymentOther?: TCalcPaymentOther) => {
    if (!paymentOther) return
    const fieldNames = [amountName + paymentOther.id, reasonName + paymentOther.id]
    return form.validateFields(fieldNames).then(() => {
      dispatch(
        calcPaymentOtherActions.putCalcPaymentOtherAction.call({
          ...paymentOther,
          paymentId: paymentOther.id,
          amount: form.getFieldValue(amountName + paymentOther.id),
          reason: form.getFieldValue(reasonName + paymentOther.id),
          date: format(new Date(), "yyyy-MM-dd"),
        }),
      )
    })
  }

  const deletePayment = (paymentOther?: TCalcPaymentOther) => {
    if (!paymentOther) return
    dispatch(calcPaymentOtherActions.deleteCalcPaymentOtherAction.call({ id: paymentOther.id }))
  }

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Дополнительные затраты
      </Title>
      <Form
        onFinish={onCreate}
        disabled={isDisableForm}
        name='mixes'
        form={form}
        layout={"vertical"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {paymentOtherAsset.map((paymentOther, index) => (
            <AdditionalExpensesItem
              key={index}
              paymentOther={paymentOther}
              onDeleteClick={() => deletePayment(paymentOther)}
              onUpdate={() => onChange(paymentOther)}
            />
          ))}
        </div>

        <AdditionalExpensesItem isCreateMode />
      </Form>
    </>
  )
}
