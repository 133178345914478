import AgentProvider from "./api/agent"
import AmortizationGroupProvider from "./api/amortizationGroup"
import AssetProvider from "./api/asset"
import AssetCurrencyProvider from "./api/assetCurrency"
import AssetTypeProvider from "./api/assetType"
import AssetTypeParamProvider from "./api/assetTypeParam"
import AssetTypeTaxProvider from "./api/assetTypeTax"
import CreditorProvider from "./api/creditor"
import IndClassProvider from "./api/indClass"
import LeadProvider from "./api/lead"
import LesseeProvider from "./api/lessee"
import PaymentOtherProvider from "./api/paymentOther"
import PaymentScheduleProvider from "./api/paymentSchedule"
import ProviderProvider from "./api/provider"

// eslint-disable-next-line @typescript-eslint/ban-types
type Constructor<T = {}> = new (...args: any[]) => T

function aggregation<TBase extends Constructor>(baseClass: TBase, ...mixins: Constructor[]) {
  const base = class _Combined extends baseClass {
    constructor(...args: any[]) {
      super(...args)
      mixins.forEach((mixin) => {
        mixin.prototype.initializer.call(this)
      })
    }
  }
  const copyProps = (target: any, source: any) => {
    Object.getOwnPropertyNames(source)
      // @ts-ignore
      .concat(Object.getOwnPropertySymbols(source))
      .forEach((prop) => {
        if (
          prop.match(
            /^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/,
          )
        )
          return
        Object.defineProperty(
          target,
          prop,
          // @ts-ignore
          Object.getOwnPropertyDescriptor(source, prop),
        )
      })
  }
  mixins.forEach((mixin) => {
    copyProps(base.prototype, mixin.prototype)
    copyProps(base, mixin)
  })
  return base
}

class ApiProvider extends aggregation(
  AgentProvider,
  AmortizationGroupProvider,
  AssetProvider,
  AssetTypeProvider,
  AssetTypeParamProvider,
  CreditorProvider,
  LeadProvider,
  LesseeProvider,
  IndClassProvider,
  ProviderProvider,
  AssetTypeTaxProvider,
  PaymentOtherProvider,
  PaymentScheduleProvider,
  AssetCurrencyProvider,
) {}

export default ApiProvider
