import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcProviderParams,
  TDeleteCalcProviderReq,
  TDeleteCalcProviderResp,
  TGetCalcProviderByIdParams,
  TGetCalcProviderByIdReq,
  TGetCalcProviderByIdResp,
  TGetCalcProviderParams,
  TGetCalcProviderReq,
  TGetCalcProviderResp,
  TPatchCalcProviderParams,
  TPatchCalcProviderReq,
  TPatchCalcProviderResp,
  TPostCalcProviderParams,
  TPostCalcProviderReq,
  TPostCalcProviderResp,
  TPutCalcProviderParams,
  TPutCalcProviderReq,
  TPutCalcProviderResp,
} from "Base/types/provider/api/provider"

export default class ProviderProvider extends ApiProviderDefault {
  static async deleteCalcProvider(parameters: {
    params: TDeleteCalcProviderParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/provider/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcProviderReq, TDeleteCalcProviderResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcProviderById(parameters: {
    params: TGetCalcProviderByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/provider/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcProviderByIdReq, TGetCalcProviderByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcProvider(parameters: { params: TGetCalcProviderParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/provider`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcProviderReq, TGetCalcProviderResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcProvider(parameters: { params: TPostCalcProviderParams; headers: Headers }) {
    const { headers, params: body } = parameters
    const path = "/calc/provider"
    const query = undefined
    return this.request<TPostCalcProviderReq, TPostCalcProviderResp>(
      path,
      "POST",
      query,
      body as TPostCalcProviderReq,
      headers,
    )
  }

  static async patchCalcProvider(parameters: {
    params: TPatchCalcProviderParams
    headers: Headers
  }) {
    const { params, headers } = parameters
    const path = `/calc/provider/${params.id}`
    const body = params as TPatchCalcProviderReq
    const query = undefined
    return this.request<TPatchCalcProviderReq, TPatchCalcProviderResp>(
      path,
      "PATCH",
      query,
      body,
      headers,
    )
  }

  static async putCalcProvider(parameters: { params: TPutCalcProviderParams; headers: Headers }) {
    const { params, headers } = parameters
    const path = `/calc/provider/${params.id}`
    const body = params as TPutCalcProviderReq
    const query = undefined
    return this.request<TPutCalcProviderReq, TPutCalcProviderResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
