import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as calcLesseeActions from "Base/store/lessee/actions"
import {
  TDeleteCalcLesseeData,
  TGetCalcLesseeByIdData,
  TGetCalcLesseeData,
  TPatchCalcLesseeData,
  TPostCalcLesseeData,
} from "Base/types/provider/api/lessee"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteCalcLesseeFailureAction,
  TDeleteCalcLesseeSuccessAction,
  TGetCalcLesseeByIdFailureAction,
  TGetCalcLesseeFailureAction,
  TGetCalcLesseeSuccessAction,
  TPatchCalcLesseeFailureAction,
  TPatchCalcLesseeSuccessAction,
  TPostCalcLesseeFailureAction,
  TPostCalcLesseeSuccessAction,
} from "./types"

export type CalcLesseeStateType = {
  getCalcLessee: {
    data?: TGetCalcLesseeData
  } & RequestInfoChildType
  getCalcLesseeById: {
    data?: TGetCalcLesseeByIdData
  } & RequestInfoChildType
  postCalcLessee: {
    data?: TPostCalcLesseeData
  } & RequestInfoChildType
  patchCalcLessee: {
    data?: TPatchCalcLesseeData
  } & RequestInfoChildType
  deleteCalcLessee: {
    data?: TDeleteCalcLesseeData
  } & RequestInfoChildType
}

const initialState: CalcLesseeStateType = {
  getCalcLessee: {
    ...RequestInfoChildState,
  },
  getCalcLesseeById: {
    ...RequestInfoChildState,
  },
  postCalcLessee: {
    ...RequestInfoChildState,
  },
  patchCalcLessee: {
    ...RequestInfoChildState,
  },
  deleteCalcLessee: {
    ...RequestInfoChildState,
  },
}

export const calcLesseeReducer = createReducer(initialState, {
  [calcLesseeActions.getCalcLesseeAction.call.type]: (state) => ({
    ...state,
    getCalcLessee: {
      ...state.getCalcLessee,
      isLoading: true,
    },
  }),
  [calcLesseeActions.getCalcLesseeAction.success.type]: (
    state,
    action: TGetCalcLesseeSuccessAction,
  ) => ({
    ...state,
    getCalcLessee: {
      ...state.getCalcLessee,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLesseeActions.getCalcLesseeAction.failure.type]: (
    state,
    action: TGetCalcLesseeFailureAction,
  ) => ({
    ...state,
    getCalcLessee: {
      ...state.getCalcLessee,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLesseeActions.getCalcLesseeByIdAction.call.type]: (state) => ({
    ...state,
    getCalcLesseeById: {
      ...state.getCalcLesseeById,
      isLoading: true,
    },
  }),
  [calcLesseeActions.getCalcLesseeByIdAction.success.type]: (state, action) => ({
    ...state,
    getCalcLesseeById: {
      ...state.getCalcLesseeById,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLesseeActions.getCalcLesseeByIdAction.failure.type]: (
    state,
    action: TGetCalcLesseeByIdFailureAction,
  ) => ({
    ...state,
    getCalcLesseeById: {
      ...state.getCalcLesseeById,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  [calcLesseeActions.getCalcLesseeByIdAction.initial.type]: (state) => {
    return {
      ...state,
      getCalcLesseeById: {
        data: undefined,
        isLoading: false,
        error: undefined,
      },
    }
  },
  //============================================================
  //============================================================
  [calcLesseeActions.postCalcLesseeAction.call.type]: (state) => ({
    ...state,
    postCalcLessee: {
      ...state.postCalcLessee,
      isLoading: true,
    },
  }),
  [calcLesseeActions.postCalcLesseeAction.success.type]: (
    state,
    action: TPostCalcLesseeSuccessAction,
  ) => ({
    ...state,
    postCalcLessee: {
      ...state.postCalcLessee,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLesseeActions.postCalcLesseeAction.failure.type]: (
    state,
    action: TPostCalcLesseeFailureAction,
  ) => ({
    ...state,
    postCalcLessee: {
      ...state.postCalcLessee,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLesseeActions.patchCalcLesseeAction.call.type]: (state) => ({
    ...state,
    patchCalcLessee: {
      ...state.patchCalcLessee,
      isLoading: true,
    },
  }),
  [calcLesseeActions.patchCalcLesseeAction.success.type]: (
    state,
    action: TPatchCalcLesseeSuccessAction,
  ) => ({
    ...state,
    patchCalcLessee: {
      ...state.patchCalcLessee,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLesseeActions.patchCalcLesseeAction.failure.type]: (
    state,
    action: TPatchCalcLesseeFailureAction,
  ) => ({
    ...state,
    patchCalcLessee: {
      ...state.patchCalcLessee,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [calcLesseeActions.deleteCalcLesseeAction.call.type]: (state) => ({
    ...state,
    deleteCalcLessee: {
      ...state.deleteCalcLessee,
      isLoading: true,
    },
  }),
  [calcLesseeActions.deleteCalcLesseeAction.success.type]: (
    state,
    action: TDeleteCalcLesseeSuccessAction,
  ) => ({
    ...state,
    deleteCalcLessee: {
      ...state.deleteCalcLessee,
      data: action.payload,
      isLoading: false,
    },
  }),
  [calcLesseeActions.deleteCalcLesseeAction.failure.type]: (
    state,
    action: TDeleteCalcLesseeFailureAction,
  ) => ({
    ...state,
    deleteCalcLessee: {
      ...state.deleteCalcLessee,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
