import { baseCSF } from "Base/utils/store"

import {
  TDeleteCalcPaymentScheduleFailurePayload,
  TDeleteCalcPaymentScheduleReqPayload,
  TDeleteCalcPaymentScheduleSuccessPayload,
  TGetCalcPaymentScheduleFailurePayload,
  TGetCalcPaymentScheduleReqPayload,
  TGetCalcPaymentScheduleSuccessPayload,
  TPostCalcPaymentScheduleFailurePayload,
  TPostCalcPaymentScheduleReqPayload,
  TPostCalcPaymentScheduleSuccessPayload,
  TGetCalcPaymentScheduleByIdReqPayload,
  TGetCalcPaymentScheduleByIdSuccessPayload,
  TGetCalcPaymentScheduleByIdFailurePayload,
  TPostCalcPaymentScheduleRecalculateReqPayload,
  TPostCalcPaymentScheduleRecalculateSuccessPayload,
  TPostCalcPaymentScheduleRecalculateFailurePayload,
  TPatchCalcPaymentScheduleReqPayload,
  TPatchCalcPaymentScheduleSuccessPayload,
  TPatchCalcPaymentScheduleFailurePayload,
  TUpdateEarlyPaymentDateReq,
  TGetCalcPaymentScheduleHistoryFailurePayload,
  TGetCalcPaymentScheduleHistoryReqPayload,
  TGetCalcPaymentScheduleHistorySuccessPayload,
} from "./types"

const prefix = "calc-payment-schedule"

export const getCalcPaymentScheduleAction = baseCSF<
  TGetCalcPaymentScheduleReqPayload,
  TGetCalcPaymentScheduleSuccessPayload,
  TGetCalcPaymentScheduleFailurePayload,
  undefined
>(prefix, "get")
export const getCalcPaymentScheduleByIdAction = baseCSF<
  TGetCalcPaymentScheduleByIdReqPayload,
  TGetCalcPaymentScheduleByIdSuccessPayload,
  TGetCalcPaymentScheduleByIdFailurePayload,
  undefined
>(prefix, "get-by-id")

export const postCalcPaymentScheduleAction = baseCSF<
  TPostCalcPaymentScheduleReqPayload,
  TPostCalcPaymentScheduleSuccessPayload,
  TPostCalcPaymentScheduleFailurePayload,
  undefined
>(prefix, "post")

export const patchCalcPaymentScheduleAction = baseCSF<
  TPatchCalcPaymentScheduleReqPayload,
  TPatchCalcPaymentScheduleSuccessPayload,
  TPatchCalcPaymentScheduleFailurePayload,
  undefined
>(prefix, "patch")

export const updateEarlyPaymentDateAction = baseCSF<
  TUpdateEarlyPaymentDateReq,
  TPatchCalcPaymentScheduleSuccessPayload,
  TPatchCalcPaymentScheduleFailurePayload,
  undefined
>("calc-payment-schedule", "update-early-payment-date")

export const postCalcPaymentScheduleRecalculateAction = baseCSF<
  TPostCalcPaymentScheduleRecalculateReqPayload,
  TPostCalcPaymentScheduleRecalculateSuccessPayload,
  TPostCalcPaymentScheduleRecalculateFailurePayload,
  undefined
>(prefix, "post-recalculate")

export const deleteCalcPaymentScheduleAction = baseCSF<
  TDeleteCalcPaymentScheduleReqPayload,
  TDeleteCalcPaymentScheduleSuccessPayload,
  TDeleteCalcPaymentScheduleFailurePayload,
  undefined
>(prefix, "delete")

export const getCalcPaymentScheduleHistoryAction = baseCSF<
  TGetCalcPaymentScheduleHistoryReqPayload,
  TGetCalcPaymentScheduleHistorySuccessPayload,
  TGetCalcPaymentScheduleHistoryFailurePayload,
  undefined
>(prefix, "get-history")
