import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcPaymentOtherParams,
  TDeleteCalcPaymentOtherReq,
  TDeleteCalcPaymentOtherResp,
  TGetCalcPaymentOtherByIdParams,
  TGetCalcPaymentOtherByIdReq,
  TGetCalcPaymentOtherByIdResp,
  TGetCalcPaymentOtherParams,
  TGetCalcPaymentOtherReq,
  TGetCalcPaymentOtherResp,
  TPatchCalcPaymentOtherParams,
  TPatchCalcPaymentOtherReq,
  TPatchCalcPaymentOtherResp,
  TPostCalcPaymentOtherParams,
  TPostCalcPaymentOtherReq,
  TPostCalcPaymentOtherResp,
  TPutCalcPaymentOtherParams,
  TPutCalcPaymentOtherReq,
  TPutCalcPaymentOtherResp,
} from "Base/types/provider/api/paymentOther"

export default class PaymentOtherProvider extends ApiProviderDefault {
  static async deleteCalcPaymentOther(parameters: {
    params: TDeleteCalcPaymentOtherParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/payment/other/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcPaymentOtherReq, TDeleteCalcPaymentOtherResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcPaymentOtherById(parameters: {
    params: TGetCalcPaymentOtherByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/payment/other/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcPaymentOtherByIdReq, TGetCalcPaymentOtherByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcPaymentOther(parameters: {
    params: TGetCalcPaymentOtherParams
    headers: Headers
  }) {
    const { headers } = parameters
    const path = `/calc/payment/other`
    const body = undefined
    const query = parameters.params
    return this.request<TGetCalcPaymentOtherReq, TGetCalcPaymentOtherResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcPaymentOther(parameters: {
    params: TPostCalcPaymentOtherParams
    headers: Headers
  }) {
    const {
      params: { leadId, reason, amount, date, vat, creditor, asset, agent },
      headers,
    } = parameters
    const path = "/calc/payment/other"
    const query = { leadId }
    const body = { reason, amount, date, vat, creditor, asset, agent }
    return this.request<TPostCalcPaymentOtherReq, TPostCalcPaymentOtherResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async patchCalcPaymentOther(parameters: {
    params: TPatchCalcPaymentOtherParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/payment/other/${id}`
    const body = undefined
    const query = undefined
    return this.request<TPatchCalcPaymentOtherReq, TPatchCalcPaymentOtherResp>(
      path,
      "PATCH",
      query,
      body,
      headers,
    )
  }

  static async putCalcPaymentOther(parameters: {
    params: TPutCalcPaymentOtherParams
    headers: Headers
  }) {
    const {
      params: { paymentId, amount, date, asset, reason, agent },
      headers,
    } = parameters
    const path = `/calc/payment/other/${paymentId}`
    const body = {
      amount,
      date,
      asset,
      reason,
      agent,
    }
    const query = undefined
    return this.request<TPutCalcPaymentOtherReq, TPutCalcPaymentOtherResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
