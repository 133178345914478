import React from "react"

import { BaseRouter } from "Starter/routes"

import "./App.scss"

export function App() {
  return (
    <div className='app'>
      <BaseRouter />
    </div>
  )
}
