import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcAssetTypeParamParams,
  TDeleteCalcAssetTypeParamReq,
  TDeleteCalcAssetTypeParamResp,
  TGetCalcAssetTypeParamByIdParams,
  TGetCalcAssetTypeParamByIdReq,
  TGetCalcAssetTypeParamByIdResp,
  TGetCalcAssetTypeParamParams,
  TGetCalcAssetTypeParamReq,
  TGetCalcAssetTypeParamResp,
  TPostCalcAssetTypeParamParams,
  TPostCalcAssetTypeParamReq,
  TPostCalcAssetTypeParamResp,
  TPutCalcAssetTypeParamParams,
  TPutCalcAssetTypeParamReq,
  TPutCalcAssetTypeParamResp,
} from "Base/types/provider/api/assetTypeParam"

export default class AssetTypeParamProvider extends ApiProviderDefault {
  static async deleteCalcAssetTypeParamType(parameters: {
    params: TDeleteCalcAssetTypeParamParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/type/param/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcAssetTypeParamReq, TDeleteCalcAssetTypeParamResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetTypeParamById(parameters: {
    params: TGetCalcAssetTypeParamByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/asset/type/param/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAssetTypeParamByIdReq, TGetCalcAssetTypeParamByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAssetTypeParam(parameters: {
    params: TGetCalcAssetTypeParamParams
    headers: Headers
  }) {
    const {
      headers,
      params: { typeId },
    } = parameters
    const path = `/calc/asset/type/param`
    const body = undefined
    const query = { typeId }
    return this.request<TGetCalcAssetTypeParamReq, TGetCalcAssetTypeParamResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcAssetTypeParam(parameters: {
    params: TPostCalcAssetTypeParamParams
    headers: Headers
  }) {
    const { params: body, headers } = parameters
    const path = "/calc/asset/type/param"
    const query = { typeId: body.typeId }
    return this.request<TPostCalcAssetTypeParamReq, TPostCalcAssetTypeParamResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putCalcAssetTypeParam(parameters: {
    params: TPutCalcAssetTypeParamParams
    headers: Headers
  }) {
    const {
      params: { id, name, dataType, required },
      headers,
    } = parameters
    const path = `/calc/asset/type/param/${id}`
    const body = {
      name,
      dataType,
      required,
    }
    const query = undefined
    return this.request<TPutCalcAssetTypeParamReq, TPutCalcAssetTypeParamResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
