import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { calcAgentReducer } from "./agent/reducer"
import { calcAmortizationGroupReducer } from "./amortizationGroup/reducer"
import { calcAssetReducer } from "./asset/reducer"
import { calcAssetCurrencyReducer } from "./assetCurrency/reducer"
import { calcAssetTypeReducer } from "./assetType/reducer"
import { calcAssetTypeParamReducer } from "./assetTypeParam/reducer"
import { calcAssetTypeTaxReducer } from "./assetTypeTax/reducer"
import { authPersistReducer } from "./authPersist/reducer"
import { calcCreditorReducer } from "./creditor/reducer"
import { calcIndClassReducer } from "./indClass/reducer"
import { calcLeadReducer } from "./lead/reducer"
import { calcLesseeReducer } from "./lessee/reducer"
import { calcPaymentOtherReducer } from "./paymentOther/reducer"
import { calcPaymentScheduleReducer } from "./paymentSchedule/reducer"
import { calcProviderReducer } from "./provider/reducer"

const authPersistConfig = {
  key: "alfa-auth-password",
  storage,
  whitelist: ["auth"],
  transforms: [
    // setTransformToken,
    // setTransformIsAuth,
  ],
}

// const calculatorPersistConfig = {
//   key: "calculator-persist",
//   storage,
//   whitelist: ["assortments"],
//   transforms: [
//     // setTransformToken,
//     // setTransformIsAuth,
//   ],
// }
export const baseRootReducer = combineReducers({
  authPersist: persistReducer(authPersistConfig, authPersistReducer),
  calcLessee: calcLesseeReducer,
  calcLead: calcLeadReducer,
  calcIndClass: calcIndClassReducer,
  calcAsset: calcAssetReducer,
  calcAssetType: calcAssetTypeReducer,
  calcAmortizationGroup: calcAmortizationGroupReducer,
  calcAssetTypeParam: calcAssetTypeParamReducer,
  calcAssetTypeTax: calcAssetTypeTaxReducer,
  calcProvider: calcProviderReducer,
  calcPaymentOther: calcPaymentOtherReducer,
  calcAgent: calcAgentReducer,
  calcAssetCurrency: calcAssetCurrencyReducer,
  calcCreditor: calcCreditorReducer,
  calcPaymentSchedule: calcPaymentScheduleReducer,
})
