import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Content } from "antd/es/layout/layout"

import { DisableBlock, NavBarTabs, Widget } from "Base/components"
import { TNavBarTab } from "Base/components/NavBarTabs"
import { clientModuleRoutes } from "Base/constants/routes"
import * as calcAssetActions from "Base/store/asset/actions"
import { InputNameLeadForm, LeasingHeader, TableOfLeasePayments } from "ClientModule/containers"
import { LeadProvider } from "ClientModule/providers"
import { RootState } from "Starter/store/configureStore"
import { Layout } from "antd"
import { StringParam, useQueryParam } from "use-query-params"

type LeasingStagesLayoutProps = {
  children?: React.ReactNode
}
export const LeasingStagesLayout = ({ children }: LeasingStagesLayoutProps) => {
  const [leadId, setLeadId] = useQueryParam("leadId", StringParam)
  const {
    base: {
      calcPaymentSchedule: { getCalcPaymentSchedule },
      calcAsset: { getCalcAsset },
    },
  } = useSelector((state: RootState) => state)
  const disableBlock = useMemo(() => {
    return !leadId
  }, [leadId])

  const navLinks: TNavBarTab[] = useMemo(() => {
    return [
      {
        to: clientModuleRoutes["leasing-stages"].lessee.root + "?leadId=" + leadId,
        title: clientModuleRoutes["leasing-stages"].lessee.title,
        value: "lessee",
      },
      {
        to: clientModuleRoutes["leasing-stages"]["subject-of-leasing"].root + "?leadId=" + leadId,
        title: clientModuleRoutes["leasing-stages"]["subject-of-leasing"].title,
        value: "leasing",
      },
      {
        to: clientModuleRoutes["leasing-stages"].deal.root + "?leadId=" + leadId,
        title: clientModuleRoutes["leasing-stages"].deal.title,
        value: "deal",
      },
      {
        to: clientModuleRoutes["leasing-stages"].agency.root + "?leadId=" + leadId,
        title: clientModuleRoutes["leasing-stages"].agency.title,
        value: "agency",
      },
    ]
  }, [leadId])

  const financialResult = getCalcPaymentSchedule?.data?.result[0]?.financialResult || 0
  const redemptionAmount = getCalcPaymentSchedule?.data?.result[0]?.redemptionAmount || 0
  const residualValue = getCalcPaymentSchedule?.data?.result[0]?.residualValue || 0
  const paymentYield = getCalcPaymentSchedule?.data?.result[0]?.paymentYield || 0
  const yieldPercent = getCalcPaymentSchedule?.data?.result[0]?.yieldPercent || 0

  return (
    <LeadProvider>
      <Layout
        style={{
          height: "100%",
        }}
      >
        <LeasingHeader
          style={{
            position: "fixed",
            width: "100%",
            zIndex: 100,
          }}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            marginTop: "60px",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateRows: "100px auto",
              position: "relative",
            }}
          >
            <InputNameLeadForm />

            <div
              style={{
                display: "grid",
                gridTemplateRows: "auto 1fr",
                gap: "20px",
                padding: "0 30px 30px 30px",
                position: "relative",
              }}
            >
              {disableBlock && <DisableBlock />}

              <NavBarTabs navLinks={navLinks} />
              <Content>{children}</Content>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "auto 1fr",
              padding: "30px",
              background: "white",
              position: "relative",
              gap: "20px",
            }}
          >
            {disableBlock && <DisableBlock />}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "72px 72px",
                gap: "20px",
              }}
            >
              <Widget title={"Сумма лизинга"} price={financialResult} />
              <Widget title={"Сумма выкупа"} price={redemptionAmount} />
              <Widget title={"Доходность"} price={paymentYield} />
              <Widget title={"Доходность %"} price={yieldPercent} />
              <Widget title={"Остаточная стоимость"} price={residualValue} />
              {/* <Widget title={"Стоимость в у.е."} price={financialResult} /> */}
            </div>
            <TableOfLeasePayments />
          </div>
        </div>
      </Layout>
    </LeadProvider>
  )
}
