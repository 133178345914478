import { axiosInstance } from "../apiProviderDefault"

export async function exportChart(leadId: number) {
  return axiosInstance
    .get(`/export/download/${leadId}`, { responseType: "blob" })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
      throw new Error(response.statusText)
    })
}
