import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcAgentParams,
  TDeleteCalcAgentReq,
  TDeleteCalcAgentResp,
  TGetCalcAgentByIdParams,
  TGetCalcAgentByIdReq,
  TGetCalcAgentByIdResp,
  TGetCalcAgentParams,
  TGetCalcAgentReq,
  TGetCalcAgentResp,
  TPostCalcAgentParams,
  TPostCalcAgentReq,
  TPostCalcAgentResp,
  TPutCalcAgentParams,
  TPutCalcAgentReq,
  TPutCalcAgentResp,
} from "Base/types/provider/api/agent"

export default class AgentProvider extends ApiProviderDefault {
  static async deleteCalcAgent(parameters: { params: TDeleteCalcAgentParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/agent/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcAgentReq, TDeleteCalcAgentResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcAgentById(parameters: { params: TGetCalcAgentByIdParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/agent/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAgentByIdReq, TGetCalcAgentByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcAgent(parameters: { params: TGetCalcAgentParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/agent`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcAgentReq, TGetCalcAgentResp>(path, "GET", query, body, headers)
  }

  static async postCalcAgent(parameters: { params: TPostCalcAgentParams; headers: Headers }) {
    const { params: body, headers } = parameters
    const path = "/calc/agent"
    const query = {}
    return this.request<TPostCalcAgentReq, TPostCalcAgentResp>(path, "POST", query, body, headers)
  }

  static async putCalcAgent(parameters: { params: TPutCalcAgentParams; headers: Headers }) {
    const {
      params: { id, name, contactName, contactPhone, contactJobTitle, inn },
      headers,
    } = parameters
    const path = `/calc/agent/${id}`
    const body = { name, contactName, contactPhone, contactJobTitle, inn }
    const query = undefined
    return this.request<TPutCalcAgentReq, TPutCalcAgentResp>(path, "PUT", query, body, headers)
  }
}
