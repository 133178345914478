import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteCalcIndClassParams,
  TDeleteCalcIndClassReq,
  TDeleteCalcIndClassResp,
  TGetCalcIndClassByIdParams,
  TGetCalcIndClassByIdReq,
  TGetCalcIndClassByIdResp,
  TGetCalcIndClassParams,
  TGetCalcIndClassReq,
  TGetCalcIndClassResp,
  TPostCalcIndClassParams,
  TPostCalcIndClassReq,
  TPostCalcIndClassResp,
  TPutCalcIndClassParams,
  TPutCalcIndClassReq,
  TPutCalcIndClassResp,
} from "Base/types/provider/api/indClass"

export default class IndClassProvider extends ApiProviderDefault {
  static async deleteCalcIndClass(parameters: {
    params: TDeleteCalcIndClassParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/ind-class/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteCalcIndClassReq, TDeleteCalcIndClassResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getCalcIndClassById(parameters: {
    params: TGetCalcIndClassByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/calc/ind-class/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcIndClassByIdReq, TGetCalcIndClassByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getCalcIndClass(parameters: { params: TGetCalcIndClassParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/calc/ind-class`
    const body = undefined
    const query = undefined
    return this.request<TGetCalcIndClassReq, TGetCalcIndClassResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postCalcIndClass(parameters: { params: TPostCalcIndClassParams; headers: Headers }) {
    const { params: body, headers } = parameters
    const path = "/calc/ind-class"
    const query = {}
    return this.request<TPostCalcIndClassReq, TPostCalcIndClassResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putCalcIndClass(parameters: { params: TPutCalcIndClassParams; headers: Headers }) {
    const {
      params: { id, name },
      headers,
    } = parameters
    const path = `/calc/ind-class/${id}`
    const body = { name }
    const query = undefined
    return this.request<TPutCalcIndClassReq, TPutCalcIndClassResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
