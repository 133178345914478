import { apiRequest } from "Base/provider/sagas"
import * as calcAssetCurrencyActions from "Base/store/assetCurrency/actions"
import {
  TGetCalcAssetCurrencyByIdResp,
  TGetCalcAssetCurrencyResp,
} from "Base/types/provider/api/assetCurrency"
import { takeLatest, call, put } from "redux-saga/effects"

import { TGetCalcAssetCurrencyByIdReqAction } from "./types"

export function* getCalcAssetCurrencySaga() {
  try {
    const resp: TGetCalcAssetCurrencyResp = yield call(apiRequest, "getCalcAssetCurrency")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetCurrencyActions.getCalcAssetCurrencyAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetCurrencyActions.getCalcAssetCurrencyAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcAssetCurrencyByIdSaga({ payload }: TGetCalcAssetCurrencyByIdReqAction) {
  try {
    const resp: TGetCalcAssetCurrencyByIdResp = yield call(apiRequest, "getCalcAssetCurrencyById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcAssetCurrencyWatcher() {
  yield takeLatest(
    calcAssetCurrencyActions.getCalcAssetCurrencyAction.call.type,
    getCalcAssetCurrencySaga,
  )
  yield takeLatest(
    calcAssetCurrencyActions.getCalcAssetCurrencyByIdAction.call.type,
    getCalcAssetCurrencyByIdSaga,
  )
}
