import { apiRequest } from "Base/provider/sagas"
import * as calcAssetActions from "Base/store/asset/actions"
import {
  TDeleteCalcAssetResp,
  TGetCalcAssetResp,
  TPostCalcAssetResp,
  TPatchCalcAssetResp,
  TGetCalcAssetByIdResp,
  TCalcAsset,
} from "Base/types/provider/api/asset"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select, delay } from "redux-saga/effects"

import { selectLeadId } from "../lead/selectors"
import { getCalcPaymentScheduleAction } from "../paymentSchedule/actions"
import {
  TDeleteCalcAssetReqAction,
  TPostCalcAssetReqAction,
  TPatchCalcAssetReqAction,
  TGetCalcAssetByIdReqAction,
  TGetCalcAssetReqAction,
} from "./types"

export function* getCalcAssetSaga({ payload }: TGetCalcAssetReqAction) {
  try {
    const resp: TGetCalcAssetResp = yield call(apiRequest, "getCalcAsset", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetActions.getCalcAssetAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetActions.getCalcAssetAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* getCalcAssetByIdSaga({ payload }: TGetCalcAssetByIdReqAction) {
  try {
    const resp: TGetCalcAssetByIdResp = yield call(apiRequest, "getCalcAssetById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetActions.getCalcAssetByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetActions.getCalcAssetByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* postCalcAssetSaga({ payload }: TPostCalcAssetReqAction) {
  try {
    const resp: TPostCalcAssetResp = yield call(apiRequest, "postCalcAsset", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetActions.postCalcAssetAction.success(resp.data))
    const { getCalcAsset } = yield select(({ base }: RootState) => base.calcAsset)

    yield put(
      calcAssetActions.getCalcAssetAction.success({
        ...getCalcAsset.data,
        result: [...getCalcAsset.data.result, resp.data.result],
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcAssetActions.postCalcAssetAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* patchCalcAssetSaga({ payload }: TPatchCalcAssetReqAction) {
  try {
    const resp: TPatchCalcAssetResp = yield call(apiRequest, "patchCalcAsset", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetActions.patchCalcAssetAction.success(resp.data))
    const { getCalcAsset } = yield select(({ base }: RootState) => base.calcAsset)
    yield put(
      calcAssetActions.getCalcAssetAction.success({
        ...getCalcAsset.data,
        result: getCalcAsset.data?.result.map((asset: TCalcAsset) => {
          if (asset.id !== resp.data.result.id) return asset
          return resp.data.result
        }),
      }),
    )
    const leadId: number = yield select(selectLeadId)

    if (leadId) {
      yield delay(500)
      yield put(
        getCalcPaymentScheduleAction.call({
          leadId,
        }),
      )
    }
  } catch (e: unknown) {
    yield put(
      calcAssetActions.patchCalcAssetAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* deleteCalcAssetSaga({ payload }: TDeleteCalcAssetReqAction) {
  try {
    const resp: TDeleteCalcAssetResp = yield call(apiRequest, "deleteCalcAsset", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetActions.deleteCalcAssetAction.success(resp.data))
    const { getCalcLeadById } = yield select(({ base }: RootState) => base.calcLead)

    yield put(calcAssetActions.getCalcAssetAction.call({ leadId: getCalcLeadById.data.result.id }))
  } catch (e: unknown) {
    yield put(
      calcAssetActions.deleteCalcAssetAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.error(e)
  }
}

export function* calcAssetWatcher() {
  yield takeLatest(calcAssetActions.getCalcAssetAction.call.type, getCalcAssetSaga)
  yield takeLatest(calcAssetActions.getCalcAssetByIdAction.call.type, getCalcAssetByIdSaga)
  yield takeLatest(calcAssetActions.postCalcAssetAction.call.type, postCalcAssetSaga)
  yield takeLatest(calcAssetActions.patchCalcAssetAction.call.type, patchCalcAssetSaga)
  yield takeLatest(calcAssetActions.deleteCalcAssetAction.call.type, deleteCalcAssetSaga)
}
