import { apiRequest } from "Base/provider/sagas"
import * as calcLesseeActions from "Base/store/lessee/actions"
import {
  TDeleteCalcLesseeResp,
  TGetCalcLesseeByIdResp,
  TGetCalcLesseeResp,
  TPatchCalcLesseeResp,
  TPostCalcLesseeResp,
} from "Base/types/provider/api/lessee"
import { takeLatest, call, put, select } from "redux-saga/effects"

import { RootState } from "../../../Starter/store/configureStore"
import * as calcLeadActions from "../lead/actions"
import {
  TDeleteCalcLesseeReqAction,
  TGetCalcLesseeByIdReqAction,
  TPatchCalcLesseeReqAction,
  TPostCalcLesseeReqAction,
} from "./types"

export function* getCalcLesseeSaga() {
  try {
    const resp: TGetCalcLesseeResp = yield call(apiRequest, "getCalcLessee")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLesseeActions.getCalcLesseeAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLesseeActions.getCalcLesseeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcLesseeByIdSaga({ payload }: TGetCalcLesseeByIdReqAction) {
  try {
    const resp: TGetCalcLesseeByIdResp = yield call(apiRequest, "getCalcLesseeById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLesseeActions.getCalcLesseeByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLesseeActions.getCalcLesseeByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcLesseeSaga({ payload }: TPostCalcLesseeReqAction) {
  try {
    const {
      calcLead: { getCalcLeadById },
    } = yield select(({ base }: RootState) => base)

    const resp: TPostCalcLesseeResp = yield call(apiRequest, "postCalcLessee", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLesseeActions.postCalcLesseeAction.success(resp.data))

    yield put(calcLesseeActions.getCalcLesseeByIdAction.success(resp.data))
    yield put(
      calcLeadActions.patchCalcLeadAction.call({
        id: getCalcLeadById.data.result.id,
        lessee: resp.data.result.id,
        name: getCalcLeadById.data.result.name,
      }),
    )
  } catch (e: unknown) {
    yield put(
      calcLesseeActions.postCalcLesseeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* patchCalcLesseeSaga({ payload }: TPatchCalcLesseeReqAction) {
  try {
    const resp: TPatchCalcLesseeResp = yield call(apiRequest, "patchCalcLessee", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLesseeActions.patchCalcLesseeAction.success(resp.data))
    yield put(calcLesseeActions.getCalcLesseeByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLesseeActions.patchCalcLesseeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteCalcLesseeSaga({ payload }: TDeleteCalcLesseeReqAction) {
  try {
    const resp: TDeleteCalcLesseeResp = yield call(apiRequest, "deleteCalcLessee", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcLesseeActions.deleteCalcLesseeAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcLesseeActions.deleteCalcLesseeAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcLesseeWatcher() {
  yield takeLatest(calcLesseeActions.getCalcLesseeAction.call.type, getCalcLesseeSaga)
  yield takeLatest(calcLesseeActions.getCalcLesseeByIdAction.call.type, getCalcLesseeByIdSaga)
  yield takeLatest(calcLesseeActions.postCalcLesseeAction.call.type, postCalcLesseeSaga)
  yield takeLatest(calcLesseeActions.patchCalcLesseeAction.call.type, patchCalcLesseeSaga)
  yield takeLatest(calcLesseeActions.deleteCalcLesseeAction.call.type, deleteCalcLesseeSaga)
}
