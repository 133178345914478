import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import { toCalculateValueAssets } from "Base/utils/calculate"
import { convertLocalStringToNumber } from "Base/utils/convertLocalStringToNumber"
import { convertNumberToLocalString } from "Base/utils/convertNumberToLocalString"
import { RootState } from "Starter/store/configureStore"
import { Form, FormInstance, InputNumber, Select, DatePicker, Typography } from "antd"
import dayjs from "dayjs"

const { Item } = Form
const { Title } = Typography

export const DealDataForm: React.FC<{
  setAmount?: (value: number) => void
  form: FormInstance<any>
  onChange: () => void
}> = ({ setAmount, form, onChange }) => {
  const {
    base: {
      calcLead: { getCalcLeadById },
      calcAsset: { getCalcAsset },
    },
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (!getCalcLeadById.data?.result) return
    const lead = getCalcLeadById.data?.result
    form.setFieldsValue({
      term: lead.leaseData?.term,
      paymentType: lead.leaseData?.paymentType,
      balanceHolder: lead.leaseData?.balanceHolder,
      interestRate: lead.leaseData?.interestRate,
      monthlyPaymentDay: lead.leaseData?.monthlyPaymentDay,
      firstPaymentDate: lead.leaseData?.firstPaymentDate
        ? dayjs(lead.leaseData.firstPaymentDate, "YYYY-MM-DD")
        : null,
      issueDate: lead.leaseData?.issueDate ? dayjs(lead.leaseData.issueDate, "YYYY-MM-DD") : null,
    })
  }, [getCalcLeadById.data?.result, form])

  const valueAssets = useMemo(() => {
    return getCalcAsset?.data?.result.reduce((value, asset) => value + asset.value, 0) || 0
  }, [getCalcAsset.data])

  useEffect(() => {
    if (!getCalcLeadById.data?.result || valueAssets === 0) return
    const initialPayment = getCalcLeadById.data?.result?.leaseData?.initialPayment || 0
    if (initialPayment === 0) return
    const result = toCalculateValueAssets({
      valueAssets,
      initialPayment,
    })
    form.setFieldsValue({
      initialPayment: result.initialPayment,
      initialPaymentPercent: result.initialPaymentPercent,
    })
  }, [valueAssets, getCalcLeadById.data?.result, form])

  const isDisableForm = useMemo(() => {
    return getCalcLeadById.isLoading
  }, [getCalcLeadById])

  const onBlurInitialPayment = ({ name }: { name: "initialPayment" | "initialPaymentPercent" }) => {
    const result = toCalculateValueAssets({
      valueAssets,
      initialPayment: name === "initialPayment" && form.getFieldValue("initialPayment"),
      initialPaymentPercent:
        name === "initialPaymentPercent" && form.getFieldValue("initialPaymentPercent"),
    })
    form.setFieldsValue({
      initialPayment: result.initialPayment,
      initialPaymentPercent: result.initialPaymentPercent,
    })

    if (
      name === "initialPayment" &&
      getCalcLeadById.data &&
      getCalcLeadById?.data?.result?.loanData
    ) {
      let amount = 0
      const valueAssets =
        getCalcAsset?.data?.result.reduce((value, asset) => value + asset.value, 0) || 0
      const initialPayment = form.getFieldValue("initialPayment")
      if (valueAssets && initialPayment && setAmount) {
        amount = valueAssets - initialPayment
        form.setFieldValue("amount", amount)
        setAmount(amount)
      }
    }

    // if (setAmount) setAmount(result.initialPayment)
    onChange()
  }

  const balanceHolderOptions = [
    { value: "LESSOR", label: "Арендодатель" },
    { value: "LESSEE", label: "Арендатор" },
  ]

  const paymentTypeOptions = [
    { value: "ANNUITY", label: "Рента" },
    { value: "DIFFERENTIATED", label: "Дифференцированный" },
  ]

  return (
    <>
      <Title style={{ marginBottom: "20px" }} level={3}>
        Данные по сделке
      </Title>
      <Form
        disabled={isDisableForm}
        name='dealLeaseData'
        form={form}
        layout={"vertical"}
        onValuesChange={onChange}
      >
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "24px" }}>
          <Item label='Первоначальный взнос' style={{ marginBottom: 0 }} required>
            <Item
              name='initialPayment'
              style={{ display: "inline-block", width: "calc(50% - 4px)" }}
            >
              <InputNumber
                formatter={convertNumberToLocalString}
                parser={convertLocalStringToNumber}
                onBlur={() => onBlurInitialPayment({ name: "initialPayment" })}
                required
                size='large'
                style={{ width: "100%" }}
                placeholder='0,00 рублей'
                precision={2}
              />
            </Item>
            <Item
              name='initialPaymentPercent'
              style={{ display: "inline-block", width: "calc(50% - 4px)", marginLeft: "8px" }}
            >
              <InputNumber
                formatter={convertNumberToLocalString}
                parser={convertLocalStringToNumber}
                onBlur={() => onBlurInitialPayment({ name: "initialPaymentPercent" })}
                size='large'
                style={{ width: "100%" }}
                placeholder='%'
                min={0}
                max={100}
                precision={2}
              />
            </Item>
          </Item>
          <Item name='interestRate' label='Процентная ставка клиента' required>
            <InputNumber
              formatter={convertNumberToLocalString}
              parser={convertLocalStringToNumber}
              required
              size='large'
              placeholder='0,00%'
              style={{ width: "100%" }}
            />
          </Item>
          <Item name='term' label='Срок лизинга (мес.)' required>
            <InputNumber
              min={1}
              max={99}
              style={{ width: "100%" }}
              required
              size='large'
              placeholder='Введите срок'
            />
          </Item>
          <Item name='issueDate' label='Дата выдачи заемных средств' required>
            <DatePicker
              placeholder='Выберите дату'
              size='large'
              style={{ width: "100%" }}
              format='DD-MM-YYYY'
            />
          </Item>
          <Item name='firstPaymentDate' label='Дата первого платежа' required>
            <DatePicker size='large' style={{ width: "100%" }} format='DD-MM-YYYY' />
          </Item>
          <Item name='monthlyPaymentDay' label='Число ежемесячного платежа' required>
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              max={31}
              type='number'
              precision={0}
              required
              size='large'
            />
          </Item>
          <Item name='balanceHolder' label='Балансодержатель' required>
            <Select
              className='select-custom'
              size='large'
              showSearch
              style={{ width: "100%" }}
              placeholder='Выберите балансодержателя'
              optionFilterProp='children'
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={balanceHolderOptions}
            />
          </Item>
          <Item name='paymentType' label='Тип платежей' required>
            <Select
              className='select-custom'
              size='large'
              showSearch
              style={{ width: "100%" }}
              placeholder='Выберите тип'
              optionFilterProp='children'
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={paymentTypeOptions}
            />
          </Item>
        </div>
      </Form>
    </>
  )
}
