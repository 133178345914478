import { apiRequest } from "Base/provider/sagas"
import * as calcAssetTypeTaxActions from "Base/store/assetTypeTax/actions"
import {
  TDeleteCalcAssetTypeTaxResp,
  TGetCalcAssetTypeTaxByIdResp,
  TGetCalcAssetTypeTaxResp, // TPatchCalcAssetTypeTaxResp,
  TPostCalcAssetTypeTaxResp,
} from "Base/types/provider/api/assetTypeTax"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, call, put, select } from "redux-saga/effects"

import {
  TDeleteCalcAssetTypeTaxReqAction,
  TGetCalcAssetTypeTaxByIdReqAction,
  TGetCalcAssetTypeTaxReqAction, // TPatchCalcAssetTypeTaxReqAction,
  TPostCalcAssetTypeTaxReqAction,
} from "./types"

export function* getCalcAssetTypeTaxSaga({
  payload: { assetType },
}: TGetCalcAssetTypeTaxReqAction) {
  try {
    const resp: TGetCalcAssetTypeTaxResp = yield call(apiRequest, "getCalcAssetTypeTax", {
      params: { assetType },
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")

    const { getCalcAssetTypeTax } = yield select(({ base }: RootState) => base.calcAssetTypeTax)
    yield put(
      calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.success({
        ...getCalcAssetTypeTax.data,
        [assetType]: resp.data,
      }),
    )
    // yield put(
    //   calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.call({
    //     assetType,
    //     rate: 5,
    //     name: "test7",
    //   }),
    // )
  } catch (e: unknown) {
    yield put(
      calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* getCalcAssetTypeTaxByIdSaga({ payload }: TGetCalcAssetTypeTaxByIdReqAction) {
  try {
    const resp: TGetCalcAssetTypeTaxByIdResp = yield call(apiRequest, "getCalcAssetTypeTaxById", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postCalcAssetTypeTaxSaga({ payload }: TPostCalcAssetTypeTaxReqAction) {
  try {
    // const {
    //   calcLead: { getCalcLeadById },
    // } = yield select(({ base }: RootState) => base)

    const resp: TPostCalcAssetTypeTaxResp = yield call(apiRequest, "postCalcAssetTypeTax", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.success(resp.data))

    yield put(calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

// export function* patchCalcAssetTypeTaxSaga({ payload }: TPatchCalcAssetTypeTaxReqAction) {
//   try {
//     const resp: TPatchCalcAssetTypeTaxResp = yield call(apiRequest, "patchCalcAssetTypeTax", {
//       params: payload,
//     })
//     if (!resp.success) throw new Error(resp.detail || "Unknown error")
//     yield put(calcAssetTypeTaxActions.patchCalcAssetTypeTaxAction.success(resp.data))
//     yield put(calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.success(resp.data))
//   } catch (e: unknown) {
//     yield put(
//       calcAssetTypeTaxActions.patchCalcAssetTypeTaxAction.failure({
//         error: e?.toString() || "",
//       }),
//     )
//     console.log(e)
//   }
// }

export function* deleteCalcAssetTypeTaxSaga({ payload }: TDeleteCalcAssetTypeTaxReqAction) {
  try {
    const resp: TDeleteCalcAssetTypeTaxResp = yield call(apiRequest, "deleteCalcAssetTypeTax", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(calcAssetTypeTaxActions.deleteCalcAssetTypeTaxAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      calcAssetTypeTaxActions.deleteCalcAssetTypeTaxAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* calcAssetTypeTaxWatcher() {
  yield takeLatest(
    calcAssetTypeTaxActions.getCalcAssetTypeTaxAction.call.type,
    getCalcAssetTypeTaxSaga,
  )
  yield takeLatest(
    calcAssetTypeTaxActions.getCalcAssetTypeTaxByIdAction.call.type,
    getCalcAssetTypeTaxByIdSaga,
  )
  yield takeLatest(
    calcAssetTypeTaxActions.postCalcAssetTypeTaxAction.call.type,
    postCalcAssetTypeTaxSaga,
  )
  // yield takeLatest(calcAssetTypeTaxActions.patchCalcAssetTypeTaxAction.call.type, patchCalcAssetTypeTaxSaga)
  yield takeLatest(
    calcAssetTypeTaxActions.deleteCalcAssetTypeTaxAction.call.type,
    deleteCalcAssetTypeTaxSaga,
  )
}
