import { CheckOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons"
import { Form, Button } from "antd"

const { Item } = Form

export const ListFormActions = <T,>({
  direction = "horizontal",
  isEdit,
  onLocalEditClick,
  onDeleteClick,
  isCreateMode = false,
  onAddClick,
}: {
  direction?: "horizontal" | "vertical"
  isEdit: boolean
  onLocalEditClick: () => void
  onAddClick?: () => void
  onDeleteClick?: (data: T) => void
  isCreateMode?: boolean
}) => {
  const onLocalAddClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!onAddClick) return
    e.preventDefault()
    onAddClick()
  }
  return (
    <>
      {!isCreateMode ? (
        <Item label=' '>
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              gap: "8px",
              flexDirection: direction === "vertical" ? "column" : "row",
              justifyContent: direction === "vertical" ? "space-between" : "normal",
            }}
          >
            <Button
              size='large'
              onClick={onLocalEditClick}
              style={{
                marginTop: "auto",
                justifySelf: "flex-end",
                alignSelf: "flex-end",
              }}
              icon={isEdit ? <CheckOutlined /> : <EditOutlined />}
            />
            <Button
              danger
              onClick={onDeleteClick as unknown as () => void}
              size='large'
              style={{ marginTop: "auto", justifySelf: "flex-end", alignSelf: "flex-end" }}
              icon={<MinusOutlined />}
            />
          </div>
        </Item>
      ) : (
        <Item label=' '>
          <Button
            onClick={onLocalAddClick}
            htmlType='submit'
            size='large'
            style={{ marginTop: "auto", justifySelf: "flex-end", alignSelf: "flex-end" }}
          >
            Добавить
          </Button>
        </Item>
      )}
    </>
  )
}
